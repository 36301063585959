import Popup from "../../../Common/Popup";

const Sign = ({ signUrl, isModalOpen, handleCancel }) => {
  return (
    <Popup
      title="Sign The Document"
      open={isModalOpen}
      cancel={handleCancel}
      maskClosable={false}
      keyboard={false}
      footer={null}
      width={680}
    >
      <div>
        Please sign the below lease agreement to proceed your booking. Document
        will be active only for 2 minutes. Please sign before that.
      </div>
      <div className="mt05">
        <b>Steps:</b>
        <ul>
          <li>Accept the agreement by clicking on checkbox</li>
          <li>Add your name and signature</li>
          <li>
            Once done click on <b>"Finish"</b> button
          </li>
          <li>Please click on the Close icon once you signed.</li>
        </ul>
      </div>

      <div className="text-center">
        <iframe
          title="sign"
          src={signUrl}
          name="SignForm"
          scrolling="yes"
          width="100%"
          height="500"
          frameBorder="0"
        ></iframe>
      </div>
    </Popup>
  );
};

export default Sign;
