import { Input, Col, Row, Tabs } from "antd";
import { useState } from "react";
import General from "./General";
import Devices from "./Devices";
import BANNER from "../../../Assets/images/faqBg.png";
import HeroSection from "../../Common/Layouts/HeroSection";
import { FAQ } from "../../Common/AppContent";
import { useLocation } from "react-router-dom";

const { Search } = Input;

const FaqPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(tab || "general");

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabItems = [
    {
      key: "general",
      label: "General",
      children: <General searchTerm={searchTerm} />,
    },
    {
      key: "devices",
      label: "Devices",
      children: <Devices searchTerm={searchTerm} />,
    },
  ];

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div data-testid="faq">
      <HeroSection
        titleColSpan={14}
        imgSrc={BANNER}
        title={FAQ.TITLE}
        subTitle={FAQ.SUB_TITLE}
        description={FAQ.DESCRIPTION}
        descColSpan={18}
      />

      <div className="pd3">
        <Row className="mt1">
          <Col span={24}>
            <Search
              allowClear
              value={searchTerm}
              placeholder="Search..."
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="search"
            />
          </Col>
        </Row>
        <Row className="mt3">
          <Col span={24}>
            <Tabs
              type="card"
              defaultActiveKey={activeTab}
              onChange={handleTabChange}
              items={tabItems}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FaqPage;
