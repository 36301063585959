import { Col, Row, Typography } from "antd";
import LOGO1 from "../../Assets/images/Clients/cigna.svg";
import LOGO2 from "../../Assets/images/Clients/multiPlan.svg";
import LOGO3 from "../../Assets/images/Clients/unitedHc.svg";
import LOGO4 from "../../Assets/images/Clients/tricare.svg";
import LOGO5 from "../../Assets/images/Clients/aetna.svg";
import LOGO6 from "../../Assets/images/Clients/medicare.svg";
import LOGO7 from "../../Assets/images/Clients/anthem.svg";
import LOGO8 from "../../Assets/images/Clients/blueCalifornia.svg";
import { HOME } from "../Common/AppContent";

const { Title } = Typography;

const Clients = () => {
  return (
    <div className="pd2 grey-fill">
      <Title level={2} className="text-center fw700">
        <div className="blue">{HOME.HEALTH_JOURNEY.BLUE_HEADER}</div>
        <div className="orange">{HOME.HEALTH_JOURNEY.ORANGE_HEADER}</div>
      </Title>
      <Row className="pd2 flex-center">
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Row gutter={[16, 16]} justify={"space-around"}>
            {[LOGO1, LOGO2, LOGO3, LOGO4, LOGO5, LOGO6, LOGO7, LOGO8].map(
              (item, index) => {
                return (
                  <Col
                    key={index}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="flex-center mt2"
                  >
                    <img src={item} alt={index} className="client-logos" />
                  </Col>
                );
              }
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Clients;
