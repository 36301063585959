import { SafetyOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table } from "antd";
import { displayDateTime } from "../../Common/Utils";

const BestPEF = ({ vitalDetails, bestPefDetails }) => {
  const columns = [
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "PEF",
      dataIndex: "pef",
      render: (text, record) => (
        <span style={{ color: record.color, fontWeight: "bold" }}>{text}</span>
      ),
    },
    {
      title: "FEV %",
      dataIndex: "fev",
      render: (text, record) => (
        <span style={{ color: record.color, fontWeight: "bold" }}>{text}</span>
      ),
    },
    {
      title: "Relative PEF %",
      dataIndex: "rfev",
      render: (text, record) => (
        <span style={{ color: record.color, fontWeight: "bold" }}>{text}</span>
      ),
    },
  ];

  const dataSource = vitalDetails?.map((item, index) => ({
    key: index,
    time: displayDateTime(item[0] * 1000),
    pef: item[1],
    fev: item[2],
    rfev: item[3],
    color: item[4],
  }));

  return (
    <Row gutter={[16, 16]} className="flex-center">
      <Col xs={24} sm={24} md={24} lg={18} xl={18}>
        <Table
          bordered
          rowKey={(record) => record.key}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: "100%", y: 250 }}
          pagination={false}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <Card className="yellow-card text-center">
          <div className="bold fs16">
            <SafetyOutlined style={{ fontSize: "1.25rem" }} className="mr05" />
            Best PEF Value
          </div>

          <div className="bold fs52 mt1">{bestPefDetails?.value}</div>
        </Card>
      </Col>
    </Row>
  );
};

export default BestPEF;
