import { Col, Row, Spin } from "antd";

const WithForm = ({ showLoader, children }) => {
  const renderDetails = () => {
    if (showLoader) {
      return (
        <Row gutter={[16, 16]} justify="center" className="mt1">
          <Col span={24}>
            <Spin className="flex-center pd3" size="large" />
          </Col>
        </Row>
      );
    } else {
      return children;
    }
  };

  return renderDetails();
};

export default WithForm;
