import { Flex } from "antd";
import { Typography } from "antd";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../../../../firebase";
import { setOtpSent, setAllStepDetails } from "../../../../Reducers/authSlice";
import { postApis } from "../../Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Constants";
import CustomForm from "../../CustomForm";
import { patientVerifyPhoneUrl } from "../../Endpoints";
import { labelledPhoneInput } from "../../FormFields";
import useNotify from "../../Notify";
import { processedPhoneNumber } from "../../Utils";
const { Title, Text } = Typography;

const PatientStepOne = ({ setCurrentStep }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const checkRegisteredUser = async (values) => {
    const phone = await processedPhoneNumber(values);

    const updatedValues = {
      ...values,
      phoneNumber: phone,
    };

    await postApis(patientVerifyPhoneUrl, {
      type: "register",
      phoneNumber: phone,
    })
      .then(() => {
        setLoading(true);
        signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            dispatch(setOtpSent(true));
            dispatch(setAllStepDetails(updatedValues));
            setLoading(false);
            setCurrentStep(1);
          })
          .catch((err) => {
            setLoading(false);
            notify(
              NOTIFICATIONS.ERROR.KEY,
              err?.message?.includes("invalid-phone-number")
                ? "Invalid Phone Number"
                : err?.message
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: () => {
        console.log("Verified Recaptcha.");
      },
    });
    checkRegisteredUser(values);
  };

  return (
    <div data-testid="stepOne">
      <Flex vertical={true} className="media-mt-3">
        <Title>Registration</Title>
        <Text>Please enter your phone number below</Text>
        <Text>We will send a verification code to your phone</Text>
      </Flex>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={() => setLoading(false)}
        formFields={[labelledPhoneInput]}
        initialValues={{ code: "+1" }}
        style={{
          width: 400,
        }}
        loading={loading}
        text={BUTTONS.REGISTER}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      />
    </div>
  );
};

export default PatientStepOne;
