import { clinicAdminSleepManualBookingsUrl } from "../../../Common/Endpoints";
import { ROUTES } from "../../../Common/Constants";
import Bookings from "../../../Common/Bookings";

const SleepManualBookings = () => {
  return (
    <Bookings
      getUrl={clinicAdminSleepManualBookingsUrl}
      redirectUrl={ROUTES.CLINIC_ADMIN.SLEEP_MANUAL_BOOK.PATH}
      putUrl={clinicAdminSleepManualBookingsUrl}
      locationType="sleep"
    />
  );
};

export default SleepManualBookings;
