import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobile: window.innerWidth < 768,
  isTablet: window.innerWidth < 1110 && window.innerWidth > 768,
  userType: localStorage.getItem("userType"),
  staticDropdownData: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsTablet: (state, action) => {
      state.isTablet = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setStaticDropdownData: (state, action) => {
      state.staticDropdownData = action.payload;
    },
  },
});

export const { setIsMobile, setIsTablet, setUserType, setStaticDropdownData } =
  appSlice.actions;
export default appSlice.reducer;
