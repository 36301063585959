import { Button, Card, Col, Result, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { activateRing, displayDateTime } from "../../Common/Utils";
const { Meta } = Card;

const List = ({
  deviceDetails,
  deviceImages,
  deviceConnectionDetails,
  showListModal,
  showRingModal,
  remoteConfigDevices,
}) => {
  const navigate = useNavigate();

  const getDeviceData = (device) => {
    const deviceList = remoteConfigDevices?.filter((i) => i?.value === device);
    return deviceList?.[0];
  };

  const connectActions = (showConnect, showOrder) => {
    return (
      <Row
        key="actions"
        gutter={[16, 16]}
        justify="center"
        className={
          window?.webkit?.messageHandlers?.uidMessageHandler ? "mb2" : ""
        }
      >
        {(window?.webkit?.messageHandlers?.uidMessageHandler ||
          showConnect) && (
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Button key="connect" block onClick={() => showListModal()}>
              Connect
            </Button>
          </Col>
        )}

        {(window?.webkit?.messageHandlers?.uidMessageHandler || showOrder) && (
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Button
              key="order"
              type="primary"
              block
              onClick={() => navigate(ROUTES.PATIENT.ORDERS.PATH)}
            >
              Order
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  return deviceDetails?.length === 0 ? (
    <Card>
      <Result
        title="No Devices Available"
        extra={connectActions(false, true)}
      />
    </Card>
  ) : (
    <div>
      {connectActions(false, false)}
      {deviceDetails?.map((item, index) => {
        return (
          <Card
            title={item?.name}
            className={`${index > 0 && "mt1"}`}
            key={index}
          >
            {item?.connected?.length > 0 ? (
              <Row gutter={[16, 16]}>
                {item?.connected?.map((subItem, subIndex) => {
                  return (
                    <Col key={subIndex} xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Card
                        styles={{
                          body: { padding: "1rem", background: "#f8f8f8" },
                        }}
                        cover={
                          <img
                            alt={subItem?.device}
                            src={deviceImages?.[subItem?.device]}
                          />
                        }
                        className="box-shadow"
                        actions={
                          window?.webkit?.messageHandlers?.uidMessageHandler &&
                          subItem?.device === "Saiwell_Ring" && [
                            <Link
                              key="edit"
                              onClick={() => showRingModal("edit")}
                            >
                              Edit
                            </Link>,
                            deviceConnectionDetails?.[
                              subItem?.device === "Saiwell_Ring"
                                ? "SaiwellRing"
                                : subItem?.device
                            ] ? (
                              <span className="default-cursor green bold">
                                Connected
                              </span>
                            ) : (
                              <Link
                                key="activate"
                                onClick={() => activateRing()}
                              >
                                Connect
                              </Link>
                            ),
                          ]
                        }
                      >
                        <Meta
                          title={getDeviceData(subItem?.device)?.label}
                          description={
                            <div>
                              {subItem?.lastUpdated && (
                                <div>
                                  Last Measured On:{" "}
                                  <span className="black">
                                    {displayDateTime(subItem?.lastUpdated)}
                                  </span>
                                </div>
                              )}
                              {subItem?.macAddress && (
                                <div>
                                  MAC:{" "}
                                  <span className="black">
                                    {subItem?.macAddress}
                                  </span>
                                </div>
                              )}
                            </div>
                          }
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Result title="No devices found" />
            )}
          </Card>
        );
      })}
    </div>
  );
};

export default List;
