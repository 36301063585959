import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeSlots: [],
  details: null,
  mailSent: false,
  patientDetails: null,
};

const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    resetBookingDetails: () => initialState,
    setTimeSlots: (state, action) => {
      state.timeSlots = action.payload;
    },
    setBookingDetails: (state, action) => {
      state.details = action.payload;
    },
    setMailSent: (state, action) => {
      state.mailSent = action.payload;
    },
    setPatientDetails: (state, action) => {
      state.patientDetails = action.payload;
    },
  },
});

export const {
  setTimeSlots,
  setBookingDetails,
  setMailSent,
  resetBookingDetails,
  setPatientDetails,
} = bookingsSlice.actions;
export default bookingsSlice.reducer;
