import Popup from "../Popup";
import { rowDataWithIcon } from "../Utils";
import { userInfo } from "./Info";

const ViewPatient = ({ modalOpen, cancelModal, details }) => {
  return (
    <Popup
      title={"Patient Details"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={700}
      style={{ top: "8%" }}
    >
      <div>
        {userInfo(details)?.map((item, index) => {
          return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
        })}
      </div>
    </Popup>
  );
};

export default ViewPatient;
