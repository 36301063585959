import { Avatar, Drawer, Dropdown, Flex, Layout, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import { ROUTES, USER_TYPES } from "../../Constants";
import { MenuOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import LOGO from "../../../../Assets/images/logo.png";
import { desktopMenuItems, mobileMenuItems } from "../../MenuData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getApis } from "../../Apis";
import { providerNotificationUrl } from "../../Endpoints";
import { setNotification } from "../../../../Reducers/authSlice";
import { getOpenKeys } from "../../Utils";
const { Header } = Layout;

const AuthHeader = ({
  handleSideMenuClick,
  isDrawerOpen,
  toggleDrawer,
  fromHomePage,
  userDetails,
  selectedSideMenu,
  pathname,
}) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const notification = useSelector((state) => state?.auth?.notification);
  const [defaultPath, setDefaultPath] = useState(ROUTES.DEFAULT.PATH);
  const defaultDoctor = localStorage.getItem("defaultDoctor");
  const dispatch = useDispatch();
  const userFullName = userDetails
    ? `${userDetails?.firstName} ${userDetails?.lastName}`
    : "Loading...";
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");

  const renderUserMenu = () => {
    return (
      <Space>
        <Avatar className="avatar-icon">
          {userFullName?.charAt(0).toUpperCase()}
        </Avatar>

        {userFullName}
        {!isMobile && <DownOutlined className="ml025" />}
      </Space>
    );
  };

  const menuProps = {
    items: fromHomePage
      ? mobileMenuItems(notification)
      : desktopMenuItems(notification),
    onClick: handleSideMenuClick,
  };

  const getNotifications = async () => {
    await getApis(
      providerNotificationUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        dispatch(setNotification(response?.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (userType === USER_TYPES.PROVIDER) {
      if (!notification && fromHomePage) {
        getNotifications();
      }
    }
    if (window?.webkit?.messageHandlers?.uidMessageHandler) {
      setDefaultPath(ROUTES.LANDING.PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header className="fixed-header">
      <Flex
        justify="space-between"
        wrap="wrap"
        gap="small"
        className="align-center"
      >
        <Link to={defaultPath} className="flex-center">
          <img src={LOGO} alt="Logo" width="140" />
        </Link>

        {isMobile ? (
          <>
            <MenuOutlined
              onClick={toggleDrawer}
              style={{ fontSize: "22px" }}
              className="right"
            />

            <Drawer
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {renderUserMenu()}
                  <CloseOutlined
                    onClick={toggleDrawer}
                    className="cursor-pointer"
                  />
                </div>
              }
              width={"70%"}
              placement="right"
              closeIcon={false}
              onClose={toggleDrawer}
              open={isDrawerOpen}
            >
              <Menu
                className="mt1 remove-icon"
                selectedKeys={[selectedSideMenu]}
                defaultOpenKeys={[getOpenKeys(pathname)]}
                mode="inline"
                items={mobileMenuItems(notification)}
                onClick={handleSideMenuClick}
              />
            </Drawer>
          </>
        ) : (
          <Dropdown menu={menuProps} className="flex-center">
            {renderUserMenu()}
          </Dropdown>
        )}
      </Flex>
    </Header>
  );
};

export default AuthHeader;
