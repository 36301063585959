import { Col, Empty, Row, Spin } from "antd";

const WithoutEpisode = ({ showLoader, children, details }) => {
  const renderDetails = () => {
    if (showLoader) {
      return (
        <Row gutter={[16, 16]} justify="center" className="mt1">
          <Col span={24}>
            <Spin className="flex-center pd3" size="large" />
          </Col>
        </Row>
      );
    } else if ((details && Object.keys(details).length === 0) || !details) {
      return <Empty className="mt2" />;
    } else {
      return children;
    }
  };

  return renderDetails();
};

export default WithoutEpisode;
