import { Button, Divider, Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { processedPhoneNumber } from "../../Common/Utils";
import { auth } from "../../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { setOtpSent, setPhoneNumber } from "../../../Reducers/authSlice";
import { useDispatch } from "react-redux";
import { postApis } from "../../Common/Apis";
import { useState } from "react";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  VALIDATIONS,
} from "../../Common/Constants";
import { patientVerifyPhoneUrl } from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import { phoneInput } from "../../Common/FormFields";
import CustomForm from "../../Common/CustomForm";
const { Title } = Typography;

const PatientSendOtp = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const checkRegisteredPhone = async (phone) => {
    await postApis(patientVerifyPhoneUrl, {
      phoneNumber: phone,
    })
      .then(() => {
        signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            dispatch(setOtpSent(true));
            dispatch(setPhoneNumber(phone));
            navigate(ROUTES.PATIENT.LOGIN.PATHS.VERIFY_OTP);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notify(
              NOTIFICATIONS.ERROR.KEY,
              err?.message?.includes("too-many-requests")
                ? "Too many attempts. Try Later."
                : err?.message
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const phone = processedPhoneNumber(values);
    const parentDiv = document.getElementById("captchaDiv");
    const childDiv = document.createElement("div");
    childDiv.id = "recaptcha";
    parentDiv.appendChild(childDiv);

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
        callback: () => {
          console.log("Verified Recaptcha.");
        },
      });
    }
    checkRegisteredPhone(phone);
  };

  const onFinishFailed = (err) => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div>
      <Title>Login</Title>
      <div className="grey">Please enter your phone number below</div>
      <div className="grey mt05">
        We will send a verification code to your phone
      </div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[phoneInput]}
        initialValues={{ code: "+1" }}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.CONTINUE}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      />

      <Flex justify={"space-between"} className="mt1">
        <Link to={ROUTES.PATIENT.LOGIN.PATHS.EMAIL}>Email Login</Link>
      </Flex>

      <Divider>OR</Divider>

      <Button
        className="blue-btn"
        onClick={() => navigate(ROUTES.PATIENT.REGISTER.PATH)}
      >
        Register Here
      </Button>
    </div>
  );
};
export default PatientSendOtp;
