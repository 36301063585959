import React, { useEffect } from "react";
import * as echarts from "echarts";

const MonitorChart = ({ gender }) => {
  useEffect(() => {
    const chartDom = document.getElementById("monitoring");
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: "",
        left: "",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        bottom: "0",
        left: "center",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
            color: function (params) {
              var colorList = ["#f26511", "#34b19e", "#ffc100"];
              return colorList[params.dataIndex % colorList.length];
            },
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: gender?.map((item) => ({
            value: item.value,
            name: item.name,
          })),
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender]);

  return <div id="monitoring" data-testid="chart" style={{ width: "100%", height: "266px" }}></div>;
};

export default MonitorChart;
