import {
  patientBookingsListUrl,
  patientBookingsUrl,
} from "../../Common/Endpoints";
import { NOTIFICATIONS, ROUTES, SERVICES } from "../../Common/Constants";
import Bookings from "../../Common/Bookings";
import SleepQuestionnaireForm from "./SleepQuestionnaireForm";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../../Common/Utils";
import useNotify from "../../Common/Notify";
import { setUserDetails } from "../../Common/Utils";
import { useDispatch } from "react-redux";
import {
  patientQuestionnairesUrl,
  patientUpdateProfileUrl,
} from "../../Common/Endpoints";
import { postApis, putApis } from "../../Common/Apis";
import { setProfileDetails } from "../../../Reducers/profileSlice";

const PatientBookings = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [questionnaires, setQuestionnaires] = useState();
  const [isModalOpen, setIsModalOpen] = useState(
    userDetails?.hasOwnProperty("sfotoSleepQuestionnaire") &&
      !userDetails.sfotoSleepQuestionnaire
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { notify } = useNotify();

  useEffect(() => {
    getRemoteConfigData("QUESTIONNAIRES").then((result) => {
      setQuestionnaires(result);
    });
    localStorage.removeItem("bookingDetails");
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const {
      clinic,
      service,
      type,
      subType,
      feet,
      inches,
      lbsWeight,
      sfotoSleepQuestionnaire,
      ...answers
    } = values;

    const data = {
      ...{ clinic, service, type, subType },
      answers,
    };

    upateHeightWeight({ feet, inches, lbsWeight, sfotoSleepQuestionnaire });
    await postApis(patientQuestionnairesUrl, data)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.assign(`${ROUTES.LANDING.PATH}?fromBookings=true`);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  const upateHeightWeight = async (data) => {
    await putApis(patientUpdateProfileUrl, data)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
      })
      .catch(() => {});
  };

  return (
    <>
      <Bookings
        getUrl={patientBookingsListUrl}
        redirectUrl={ROUTES.PATIENT.SLEEP_STUDY.PATH}
        putUrl={patientBookingsUrl}
        locationType="sleep"
      />
      {isModalOpen && questionnaires && (
        <SleepQuestionnaireForm
          loading={loading}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          userDetails={userDetails}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          questionnaireDetails={questionnaires?.["SFOTO"]?.[SERVICES.SLEEP]}
        />
      )}
    </>
  );
};

export default PatientBookings;
