import { Button, Card, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import {
  patientProgramProvidersUrl,
  patientProgramsUrl,
  patientVerifyMrnUrl,
} from "../../Common/Endpoints";
import { getRemoteConfigData, reloadPatientPrograms } from "../../Common/Utils";
import CLINIC from "../../../Assets/images/clinic.png";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import { Link } from "react-router-dom";
import Join from "./Join";
import useNotify from "../../Common/Notify";
import { useSelector } from "react-redux";
import moment from "moment";
import { useApiLoader } from "../../Common/ApiLoaderContext";

const PatientPrograms = () => {
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const [staticPrograms, setStaticPrograms] = useState();
  const [dynamicPrograms, setDynamicPrograms] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState();
  const [optionalFields, setOptionalFields] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [selectedService, setSelectedService] = useState();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [providers, setProviders] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [form] = Form.useForm();
  const { notify } = useNotify();

  const showModal = (clinic, service, mandatoryFields, optionalFields) => {
    setSelectedClinic(clinic);
    setSelectedService(service);
    setMandatoryFields(mandatoryFields);
    setOptionalFields(optionalFields);
    setIsModalOpen(true);
    form.resetFields();
    form.setFieldsValue({
      mrn: userDetails?.mrn,
      addressLine1: userDetails?.addressLine1,
      addressLine2: userDetails?.addressLine2,
    });
  };

  const getProviderList = async () => {
    if (selectedClinic && selectedService) {
      await getApis(patientProgramProvidersUrl, {
        clinic: selectedClinic,
        service: selectedService,
      })
        .then((response) => {
          setProviders(response?.data);
        })
        .catch(() => {});
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const getDynamicPrograms = async () => {
    setShowLoader(true);
    await getApis(patientProgramsUrl)
      .then((response) => {
        setShowLoader(false);
        setDynamicPrograms(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getDynamicPrograms();
    getRemoteConfigData("PROGRAMS").then((result) => {
      setStaticPrograms(result);
    });
    getRemoteConfigData("DROPDOWNS").then((result) => {
      setStaticDropdownData(result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProviderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinic, selectedService]);

  const verifyMrn = async (formData) => {
    if (userDetails?.mrn) {
      joinProgram(formData);
    } else {
      if (formData?.mrn) {
        await postApis(patientVerifyMrnUrl, {
          mrn: formData?.mrn,
        })
          .then((response) => {
            if (response?.data?.mrnExists) {
              setLoading(false);
              notify(NOTIFICATIONS.ERROR.KEY, response?.data?.message);
            } else {
              setLoading(true);
              joinProgram(formData);
            }
          })
          .catch(() => setLoading(false));
      } else {
        joinProgram(formData);
      }
    }
  };

  const joinProgram = async (formData) => {
    await postApis(patientProgramsUrl, formData)
      .then((response) => {
        setLoading(false);
        setIsModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        reloadPatientPrograms();
      })
      .catch(() => setLoading(false));
  };

  const onFinish = (values) => {
    setLoading(true);
    let updatedValues = {
      ...values,
      clinic: selectedClinic,
      service: selectedService,
    };
    if (selectedService === SERVICES.PRE_POST_PROCEDURE) {
      updatedValues = {
        ...updatedValues,
        surgeryDate: moment(values?.surgeryDate).format("MM-DD-YYYY"),
      };
    }
    verifyMrn(updatedValues);
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    !showLoader && (
      <div>
        <Join
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          isModalOpen={isModalOpen}
          handleModalCancel={handleModalCancel}
          loading={loading}
          mandatoryFields={mandatoryFields}
          optionalFields={optionalFields}
          providers={providers}
          staticDropdownData={staticDropdownData}
          userDetails={userDetails}
        />
        <Card className="blue-card">
          <Row justify={"space-between"}>
            <Col
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
              className="flex-start-center"
            >
              <div>
                <div className="fs24 bold">Clinic Programs</div>
                <div className="mt05">Join and explore more programs here</div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={4} lg={4} xl={4}>
              <img src={CLINIC} alt="summary" width="100%" />
            </Col>
          </Row>
        </Card>
        {staticPrograms?.map((item, index) => {
          return (
            <Row key={index} className="mt1">
              <Col span={24}>
                <Card>
                  <div className="blue bold fs24">{item?.clinic?.label}</div>
                  <Row gutter={[16, 16]} key={index} className="mt1">
                    {item?.services?.map((service, sIndex) => {
                      return (
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} key={sIndex}>
                          <div className="orange-plane-card">
                            <Row gutter={[16, 16]}>
                              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <img
                                  src={service?.image}
                                  alt="Program"
                                  width="100%"
                                />
                              </Col>
                              <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                <div className="blue bold fs16">
                                  {service?.label}
                                </div>
                                {!window?.webkit?.messageHandlers
                                  ?.uidMessageHandler && (
                                  <div>
                                    <Link to={service?.knowMore}>
                                      Know More
                                    </Link>
                                  </div>
                                )}

                                {dynamicPrograms &&
                                  (dynamicPrograms?.[
                                    item?.clinic?.value
                                  ]?.includes(service?.value) ? (
                                    <div className="bold green mt1">
                                      Enrolled
                                    </div>
                                  ) : (
                                    <Button
                                      type="primary"
                                      size="small"
                                      className="mt1"
                                      onClick={() =>
                                        showModal(
                                          item?.clinic?.value,
                                          service?.value,
                                          service?.mandatoryFields,
                                          service?.optionalFields
                                        )
                                      }
                                    >
                                      Join Now
                                    </Button>
                                  ))}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
          );
        })}
      </div>
    )
  );
};

export default PatientPrograms;
