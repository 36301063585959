import { Col, Divider, Layout, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../../../Assets/images/whiteLogo.png";
import POWERED_BY from "../../../../Assets/images/poweredBy.svg";
import { PhoneFilled, MailFilled } from "@ant-design/icons";
import { FOOTER, ROUTES } from "../../Constants";

const { Footer } = Layout;

const LargeFooter = () => {
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    const path = e.currentTarget.getAttribute("href");
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <Footer className="dark-footer">
      <Row
        gutter={[0, 16]}
        justify={"space-around"}
        className="footer-text-center"
      >
        <Col xs={24} sm={24} md={12} lg={4} xl={4} className="flex-center">
          <Link to={ROUTES.DEFAULT.PATH} onClick={handleOnClick}>
            <img src={LOGO} alt="Logo" width="100" />
          </Link>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.WHAT_WE_DO.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.WHAT_WE_DO.LABEL}
              </Link>
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.COGNITIVE_JOURNEY.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.COGNITIVE_JOURNEY.LABEL}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.FOR_PATIENTS.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.FOR_PATIENTS.LABEL}
              </Link>
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.FAQ.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.FAQ.LABEL}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.FOR_PROVIDERS.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.FOR_PROVIDERS.LABEL}
              </Link>
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.CONTACT_US.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.CONTACT_US.LABEL}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.VIDEOS.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.VIDEOS.LABEL}
              </Link>
            </Col>
          </Row>

          <Row className="mt1">
            <Col span={24}>
              <Link
                to={ROUTES.PUBLIC.PRIVACY_POLICY.PATH}
                className="white"
                onClick={handleOnClick}
              >
                {ROUTES.PUBLIC.PRIVACY_POLICY.LABEL}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4} xl={4}>
          <Row className="mt1">
            <Col span={24}>
              <PhoneFilled className="mr05" style={{ fontSize: "16px" }} />
              {FOOTER.PHONE}
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <MailFilled className="mr05" style={{ fontSize: "16px" }} />
              {FOOTER.EMAIL}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider
        style={{
          borderBlockStart: "1px solid #fff",
          margin: "1.5rem 0 0.75rem 0",
        }}
      />
      <Row justify={"center"}>
        <Col span={24} className="flex-center">
          <div
            dangerouslySetInnerHTML={{
              __html: FOOTER.COPYRIGHT,
            }}
          />
          <Link to={ROUTES.SAIGEWARE} target={"_blank"} className="ml1">
            <img
              src={POWERED_BY}
              width="100%"
              alt="Powered By"
              className="top-margin"
            />
          </Link>
        </Col>
      </Row>
    </Footer>
  );
};

export default LargeFooter;
