import { Button, Col, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";

const Unregister = ({
  modalOpen,
  cancelModal,
  onFinish,
  onFinishFailed,
  loading,
}) => {
  return (
    <Popup
      title={"Unregister From Program"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={550}
    >
      <Form
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ margin: 0 }}
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item
          name="reason"
          size="large"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea className="mt1" rows={6} placeholder="Add a reason" />
        </Form.Item>

        <Row justify={"center"} className="mt1">
          <Col span={8}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={loading}
                size="large"
              >
                <Loader loading={loading} text={BUTTONS.UNREGISTER} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default Unregister;
