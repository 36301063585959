import { Alert, Button, Card, Col, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApis } from "../../Common/Apis";
import { providerViewPatientReportsUrl } from "../../Common/Endpoints";
import { displayDateTime } from "../../Common/Utils";

const Reports = ({ reportDetails, token, selectedEpisode, service }) => {
  const currentEpisode = reportDetails?.onDemand;
  const [message, setMessage] = useState();
  const isMobile = useSelector((state) => state?.app?.isMobile);

  const generateReport = async (type) => {
    setMessage(null);
    await postApis(providerViewPatientReportsUrl, {
      token: token,
      type: type,
      episodeNumber: selectedEpisode,
      service: service,
    })
      .then((response) => {
        setMessage(response?.data?.message);
      })
      .catch(() => {});
  };

  const gridStyle = {
    width: isMobile ? "100%" : "50%",
    textAlign: "center",
    background: "#f8f8f8",
  };

  return (
    <Col span={24}>
      <Card>
        <Card.Grid style={gridStyle}>
          <div>Download/Generate a report for Episode {selectedEpisode}</div>
          {reportDetails?.reports?.downloadLink ? (
            <Button
              href={reportDetails?.reports?.downloadLink}
              className="blue-btn mt1"
            >
              Download
            </Button>
          ) : (
            <Button
              onClick={() => generateReport("ON_DEMAND_REPORT")}
              className="blue-btn mt1"
            >
              Download
            </Button>
          )}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <div>Generate/Send a FAX for Episode {selectedEpisode}</div>
          <Button
            onClick={() => generateReport("ON_DEMAND_FAX")}
            className="blue-btn mt1"
          >
            FAX
          </Button>
        </Card.Grid>
      </Card>

      {message && (
        <Row className="mt1">
          <Col span={24}>
            <Alert message={message} type="success" closable />
          </Col>
        </Row>
      )}

      {currentEpisode && Object.keys(currentEpisode)?.length > 0 && (
        <Row justify={"center"}>
          <Col span={24} className="mt1">
            <Card>
              <div className="text-center">
                <div className="fs18 bold blue">
                  Episode - {currentEpisode?.episodeNumber}
                </div>
                <div className="mt05">
                  <b>Last Generated On:</b>{" "}
                  {displayDateTime(currentEpisode?.createdAt)}
                </div>
                <div>
                  Report generated on episode day{" "}
                  {currentEpisode?.generatedEpisodeDay}
                </div>

                <Button
                  href={currentEpisode?.url}
                  type="primary link"
                  className="mt1"
                >
                  Download
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default Reports;
