import { Button } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BUTTONS, ROUTES, USER_TYPES } from "./Constants";
import { logAnalyticsEvent } from "./Utils";

const ExperienceNow = ({ redirectPath }) => {
  const [buttonText, setButtonText] = useState(BUTTONS.EXPERIENCE_NOW);
  const isLoggedIn = localStorage.getItem("authToken");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");

  useEffect(() => {
    if (isLoggedIn) {
      setButtonText(BUTTONS.VIEW_DASHBOARD);
    } else {
      setButtonText(BUTTONS.EXPERIENCE_NOW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = () => {
    if (isLoggedIn) {
      if (userType === USER_TYPES.PATIENT) {
        if (userDetails?.dashboard?.length > 0) {
          navigate(
            `${ROUTES.PATIENT.DASHBOARD.PATH}/${userDetails?.dashboard[0]?.clinic}/${userDetails?.dashboard[0]?.service}`
          );
        } else {
          navigate(ROUTES.PATIENT.PROGRAMS.PATH);
        }
      } else if (userType === USER_TYPES.PROVIDER) {
        navigate(ROUTES.PROVIDER.DASHBOARD.PATH);
      } else if (userType === USER_TYPES.CLINIC_ADMIN) {
        navigate(ROUTES.CLINIC_ADMIN.DASHBOARD.PATH);
      }
    } else {
      navigate(redirectPath);
    }
    window.scrollTo(0, 0);
    logAnalyticsEvent("experienceNow");
  };

  return (
    <Button type="primary" size="large" onClick={onClickHandler}>
      {buttonText}
    </Button>
  );
};

export default ExperienceNow;
