import { ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Slider,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";

import Loader from "../../Common/Loader";
import { displayDateTime } from "../../Common/Utils";

const TinnitusForm = ({
  mainItem,
  questionnaireDetails,
  loading,
  onFinish,
  onFinishFailed,
}) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);

  return (
    <Card
      title={
        <Row justify={"end"} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="fs20">
            <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
              {mainItem?.service +
                " - " +
                questionnaireDetails?.[mainItem?.type]?.[mainItem?.subType]
                  ?.label}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="flex-end">
            <span className="mr05">
              <ClockCircleOutlined /> Submitted On:
            </span>
            <span>{displayDateTime(mainItem?.lastUpdated)}</span>
          </Col>
        </Row>
      }
      styles={{
        body: {
          padding: mainItem?.subType === "setTwo" ? "1.5rem 2rem" : "",
        },
      }}
      className="bdr3 box-shadow mt1"
    >
      <div className="bold mb2">
        {
          questionnaireDetails?.[mainItem?.type]?.[mainItem?.subType]
            ?.description
        }
      </div>
      <Form
        initialValues={{
          clinic: mainItem?.clinic,
          service: mainItem?.service,
          type: mainItem?.type,
          subType: mainItem?.subType,
        }}
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item name="clinic" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="service" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="subType" hidden>
          <Input />
        </Form.Item>

        {questionnaireDetails?.questionnaires?.[mainItem?.subType]?.data?.map(
          (questionnaire, mIndex) => {
            return (
              <div key={mIndex}>
                <div className="bold blue mb1 fs16">
                  <ArrowRightOutlined className="mr05" /> {questionnaire?.label}
                </div>

                {questionnaire?.questions?.map((question, qIndex) => {
                  return (
                    <div key={qIndex}>
                      <Form.Item
                        label={question?.title}
                        size="large"
                        name={question?.value}
                        className={
                          mainItem?.subType === "setOne"
                            ? "flex-start-center center-radio mt2"
                            : "mt2"
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please select an option",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{
                          marginBottom:
                            mainItem?.subType === "setOne" ? "15px" : "0px",
                        }}
                      >
                        {mainItem?.subType === "setOne" ? (
                          <Radio.Group className="m0">
                            <Space direction="vertical">
                              {questionnaire?.options?.map((opt, index) => {
                                return (
                                  <Radio key={index} value={opt?.value}>
                                    {opt?.label}
                                  </Radio>
                                );
                              })}
                            </Space>
                          </Radio.Group>
                        ) : (
                          <Slider
                            min={question?.minScale}
                            max={question?.maxScale}
                            step={question?.stepScale}
                            dots
                            marks={
                              isMobile
                                ? {
                                    [question?.minScale]: question?.minScale,
                                    [question?.maxScale]: question?.maxScale,
                                  }
                                : question?.scale
                            }
                          />
                        )}
                      </Form.Item>
                      {mainItem?.subType === "setTwo" && (
                        <div className="slider-flex">
                          <div className="fs13 grey mb1 text-left">
                            {question?.startLabel}
                          </div>
                          <div className="fs13 grey mb1 text-right">
                            {question?.endLabel}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                <Divider />
              </div>
            );
          }
        )}
        <Row justify={"center"}>
          <Col xs={12} sm={8} md={8} lg={4} xl={4}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
              className="mt1"
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default TinnitusForm;
