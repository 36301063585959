import {
  ClockCircleOutlined,
  CloseCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { BUTTONS, SERVICES, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import { displayDateTime } from "../../Common/Utils";
const { TextArea } = Input;

const QuestionsForm = ({
  questionnaires,
  mainItem,
  onFinish,
  onFinishFailed,
  loading,
  loadingRowId,
}) => {
  return (
    <Card
      title={
        <Row justify={"end"} gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="fs20">
            <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
              {mainItem?.service +
                " - " +
                questionnaires?.[mainItem?.service]?.[mainItem?.type]?.[
                  mainItem?.subType
                ]?.label}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="flex-end">
            <span className="mr05">
              <ClockCircleOutlined /> Submitted On:
            </span>
            <span>{displayDateTime(mainItem?.lastUpdated)}</span>
          </Col>
        </Row>
      }
      className="bdr3 box-shadow mt1"
    >
      <Form
        name={`${mainItem?.clinic}-${mainItem?.service}-${mainItem?.type}${
          mainItem?.subType ? `-${mainItem?.subType}` : ""
        }`}
        initialValues={{
          clinic: mainItem?.clinic,
          service: mainItem?.service,
          type: mainItem?.type,
          subType: mainItem?.subType,
        }}
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        {questionnaires?.[mainItem?.service]?.[mainItem?.type]?.[
          mainItem?.subType
        ]?.data?.map((item, index) => {
          return (
            <div key={index}>
              <div className="mb1">
                <span className="mr025">{index + 1}.</span> {item?.title}
              </div>
              <Form.Item name="clinic" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="service" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="subType" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                size="large"
                name={`Q${index + 1}`}
                className="ml1"
                rules={[
                  {
                    required: true,
                    message: "Please select an option",
                  },
                ]}
              >
                <Radio.Group>
                  {item?.options?.map((opt, index) => {
                    return (
                      <Radio key={index} value={index + 1}>
                        {opt?.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
              <Divider />
            </div>
          );
        })}
        {mainItem?.service === SERVICES.SLIT && (
          <>
            <div className="bold fs20">Vial Details</div>
            <Form.List name="Q6" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <div
                      key={key}
                      className="grey-fill pd1 mt1"
                      style={{ position: "relative" }}
                    >
                      {key !== 0 && (
                        <CloseCircleFilled
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                          onClick={() => remove(name)}
                          className="fs24 orange right"
                          title="Delete this row"
                        />
                      )}
                      <Row
                        key={key}
                        gutter={[16, 16]}
                        className={key !== 0 ? "mt105" : "mt1"}
                      >
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                          <Form.Item
                            name={[name, "vial"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            className="mb0"
                          >
                            <Select
                              placeholder="Vials Given"
                              size="large"
                              options={
                                questionnaires?.[mainItem?.service]?.vials
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                          <Form.Item
                            name={[name, "drops"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            className="mb0"
                          >
                            <Select
                              placeholder="No. of Drops Taken"
                              size="large"
                              options={
                                questionnaires?.[mainItem?.service]?.drops
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                          <Form.Item
                            name={[name, "reaction"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            className="mb0"
                          >
                            <TextArea rows={1} placeholder="Reaction" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Form.Item className="mt2">
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusCircleFilled className="fs24 green" />}
                    >
                      More Vial Updates
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
        <Row justify={"center"}>
          <Col xs={12} sm={8} md={8} lg={4} xl={4}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading && loadingRowId === mainItem?.subType}
              className="mt1"
            >
              <Loader
                loading={loading && loadingRowId === mainItem?.subType}
                text={BUTTONS.SUBMIT}
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default QuestionsForm;
