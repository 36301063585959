import { useEffect } from "react";
import * as echarts from "echarts";
import { displayOnlyTime } from "../../../Common/Utils";

const AreaChart = ({
  isMobile,
  vitalHeader,
  graphColor,
  vitalsData,
  filterType,
}) => {
  const getData = () => {
    if (filterType === "day") {
      return [
        ["Time", ""],
        ...vitalsData.map((entry) => {
          const timestamp = Object.keys(entry)[0];
          const value = entry[timestamp];
          if (!timestamp || isNaN(new Date(timestamp))) {
            return ["", value];
          }
          return [displayOnlyTime(timestamp), value];
        }),
      ];
    } else {
      return [
        ["Date", ""],
        ...vitalsData.map((entry) => {
          const date = Object.keys(entry)[0];
          let value = entry[date];

          if (value === null) {
            value = 0;
          }

          const parsedDate = new Date(date);
          if (isNaN(parsedDate)) {
            return ["", value];
          }

          const day = new Date(date).getDate();
          const month = new Date(date).toLocaleDateString("en-US", {
            month: "short",
          });

          const daySuffix = (day) => {
            if (day > 3 && day < 21) return "th";
            switch (day % 10) {
              case 1:
                return "st";
              case 2:
                return "nd";
              case 3:
                return "rd";
              default:
                return "th";
            }
          };

          const formattedDay = `${day}${daySuffix(day)}`;
          const formattedDate = `${formattedDay} ${month}`;

          return [formattedDate, value];
        }),
      ];
    }
  };

  useEffect(() => {
    const chartDom = document.getElementById("AreaChart");
    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart =
      process.env.NODE_ENV === "test"
        ? echarts.init(chartDom, null, {
            width: 600,
            height: 400,
          })
        : echarts.init(chartDom);

    const option = {
      title: {
        text: vitalHeader,
        left: "center",
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        axisLabel: { rotate: 45 },
        boundaryGap: false,
        data: getData()
          .slice(1)
          .map((item) => item[0]),
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          type: "line",
          data: getData()
            .slice(1)
            .map((item) => item[1]),
          areaStyle: { color: graphColor },
          itemStyle: {
            color: graphColor,
          },
        },
      ],
      grid: {
        top: 50,
        bottom: 50,
        left: isMobile ? "12%" : "5%",
        right: "5%",
      },
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  return <div id="AreaChart" style={{ width: "100%", height: "280px" }}></div>;
};

export default AreaChart;
