import { Button, Divider, Table } from "antd";
import { displayDateTime, displayOnlyDate } from "../../Common/Utils";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { BUTTONS } from "../../Common/Constants";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const TinnitusAnswers = ({
  profileDetails,
  questionnaireDetails,
  questionnaires,
  service,
}) => {
  const setTwoColumns = [
    {
      title: "Filled On",
      dataIndex: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "Form",
      dataIndex: "url",
      render: (text, record) => (
        <Button
          size="small"
          className="mr05"
          onClick={() => window.open(record?.url, "_blank")}
          disabled={!record?.url}
        >
          <>
            <EyeOutlined className="mr05" />
            {BUTTONS.VIEW} and Download
          </>
        </Button>
      ),
    },
  ];

  const ratingsColumns = [
    {
      title: "Rated On",
      dataIndex: "created",
      render: (_, record) => displayDateTime(record?.created),
    },
    {
      title: "Score",
      dataIndex: "score",
    },
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const questionKeys = new Set();

  Object.keys(questionnaireDetails?.questionnaires)?.map((item) => {
    return Object.keys(questionnaireDetails?.questionnaires?.[item][0]).forEach(
      (key) => {
        if (
          item !== "setTwo" &&
          !["created", "subType", "type"].includes(key)
        ) {
          questionKeys.add(key);
        }
      }
    );
  });

  const mapAnswerToLabel = (questionIndex, answer, key) => {
    const options = questionnaires?.[service]?.questionnaires?.[
      key
    ]?.data?.find((item) => questionIndex?.charAt(1) === item?.value)?.options;

    if (options && answer !== null && answer !== undefined) {
      return options?.find((i) => i?.value === answer)?.label || "-";
    }
    return "-";
  };

  const getQuestions = (key) => {
    const allQuestions = questionnaireDetails?.questionnaires?.[key]?.flatMap(
      (item) => {
        return Object.keys(item)
          .filter((questionKey) => questionKey.startsWith("Q"))
          .map((questionKey) => {
            const question = questionnaires?.[service]?.questionnaires?.[
              key
            ]?.data
              ?.find((dataItem) => questionKey?.charAt(1) === dataItem?.value)
              ?.questions?.find((q) => questionKey === q?.value);
            return { key: questionKey, title: question?.title };
          });
      }
    );

    const uniqueQuestions = Array.from(
      new Map(allQuestions.map((q) => [q.key, q])).values()
    );

    return uniqueQuestions;
  };

  const handleDownload = (key) => {
    const questionMap = getQuestions(key).reduce((acc, { key, title }) => {
      acc[key] = title;
      return acc;
    }, {});

    const formattedData = questionnaireDetails?.questionnaires?.[key]?.map(
      (item) => {
        const formattedItem = {
          "Filled On": displayDateTime(item.created),
        };

        Object.keys(item).forEach((questionKey) => {
          if (questionKey.startsWith("Q")) {
            const questionTitle = questionMap[questionKey] || questionKey;
            formattedItem[questionTitle] = mapAnswerToLabel(
              questionKey,
              item[questionKey],
              key
            );
          }
        });

        return formattedItem;
      }
    );

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${profileDetails?.firstName}_${profileDetails?.lastName}_Tinnitus_Questionnaires_${key}`;
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleRateDownload = (key) => {
    const formattedData = questionnaireDetails?.ratings?.[key]?.map((item) => {
      return {
        "Rated On": displayDateTime(item.created),
        Score: item.score,
      };
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${profileDetails?.firstName}_${profileDetails?.lastName}_Tinnitus_Ratings_${key}`;
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const setOneColumns = [
    {
      title: "Filled On",
      dataIndex: "created",
      key: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    ...Array.from(questionKeys).map((key) => ({
      title: key,
      dataIndex: [key],
      key: `${key}`,
      render: (text) => {
        return text;
      },
    })),
  ];

  return (
    <>
      {questionnaireDetails?.ratings &&
        Object.keys(questionnaireDetails?.ratings)?.map((key, index) => {
          return (
            <div key={index}>
              <div className="slider-flex">
                <div className="bold blue fs18 text-left">
                  Self Reported Logs
                </div>
                <div className="text-right">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleRateDownload(key)}
                  >
                    <DownloadOutlined
                      style={{ color: "#fff", fontSize: "16px" }}
                    />
                    Download
                  </Button>
                </div>
              </div>
              <Divider style={{ margin: "0.25rem 0 2rem 0" }} />

              <div>
                This entry documents the patient's self-reported experience of
                ringing sensations on a scale of 1 to 5.
              </div>

              <Table
                rowKey={(record) => `${new Date(record?.created).getTime()}`}
                dataSource={questionnaireDetails?.ratings?.[key]}
                columns={ratingsColumns}
                bordered
                scroll={{ x: "100%" }}
                pagination={false}
                className="mt2"
              />
            </div>
          );
        })}

      {Object.keys(questionnaireDetails?.questionnaires)?.map((key, index) => {
        return (
          <div
            key={index}
            className={questionnaireDetails?.ratings ? "mt3" : ""}
          >
            <div className="slider-flex">
              <div className="bold blue fs18 text-left">{`${questionnaires?.[service]?.questionnaires?.[key]?.label} - Questionnaires`}</div>
              {key === "setOne" && (
                <div className="text-right">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleDownload(key)}
                  >
                    <DownloadOutlined
                      style={{ color: "#fff", fontSize: "16px" }}
                    />
                    Download Answers
                  </Button>
                </div>
              )}
            </div>
            <Divider style={{ margin: "0.25rem 0 2rem 0" }} />

            {key === "setOne" &&
              getQuestions(key)?.map((question, index) => {
                return (
                  <p key={index}>
                    <strong>{question.key}</strong> - {question.title}
                  </p>
                );
              })}

            <Table
              rowKey={(record) => `${new Date(record?.created).getTime()}`}
              dataSource={questionnaireDetails?.questionnaires?.[key]}
              columns={key === "setTwo" ? setTwoColumns : setOneColumns}
              bordered
              scroll={{ x: "100%" }}
              pagination={false}
              className="mt1"
            />
          </div>
        );
      })}
    </>
  );
};

export default TinnitusAnswers;
