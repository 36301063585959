import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import { providerViewPatientEmrDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import { renderKeyValueDetails } from "../../../Common/Utils";

const BasicDetails = ({
  emrDetails,
  staticDropdownData,
  token,
  getEmrData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientEmrDataUrl, {
      token: token,
      category: "basicDetails",
      formData: values,
    })
      .then(() => {
        setLoading(false);
        getEmrData(true);
        dispatch(setEmrSelectedKey(2));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(emrDetails?.basicDetails)?.length > 0 ? (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ADMIT_TYPE,
          emrDetails?.basicDetails?.admitType
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ADMITTED_FROM_NON_HEALTHCARE,
          emrDetails?.basicDetails?.admittedFrom
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ALCOHOL_STATUS,
          emrDetails?.basicDetails?.alcoholStatus
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.BED_TYPES,
          emrDetails?.basicDetails?.bedTypes
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.BLOOD_GROUP,
          emrDetails?.basicDetails?.bloodGroup
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.DISCHARGE_TO,
          emrDetails?.basicDetails?.dischargeTo
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ECG_REPORT,
          emrDetails?.basicDetails?.ecgReport
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ETHNICITY,
          emrDetails?.basicDetails?.ethnicity
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.HEIGHT,
          emrDetails?.basicDetails?.height
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.WEIGHT,
          emrDetails?.basicDetails?.weight
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.PAST_EMERGENCY_ADMIT,
          emrDetails?.basicDetails?.pastEmergencyAdmit
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.PROCESS_TYPES,
          emrDetails?.basicDetails?.processTypes
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.SMOKING_STATUS,
          emrDetails?.basicDetails?.smokingStatus
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.STAY_DURATION,
          emrDetails?.basicDetails?.durationOfStay
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.ETHNICITY}
            name="ethnicity"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.ethnicity}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.HEIGHT}
            name="height"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.HEIGHT} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.WEIGHT}
            name="weight"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.WEIGHT} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.PAST_EMERGENCY_ADMIT}
            name="pastEmergencyAdmit"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[0-9]+$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.PAST_EMERGENCY_ADMIT} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.STAY_DURATION}
            name="durationOfStay"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[0-9]+$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.STAY_DURATION} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.ALCOHOL_STATUS}
            name="alcoholStatus"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.alcoholStatus}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.SMOKING_STATUS}
            name="smokingStatus"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.smokeStatus}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.DISCHARGE_TO}
            name="dischargeTo"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.booleanType}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.ADMIT_TYPE}
            name="admitType"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.admitType}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.BLOOD_GROUP}
            name="bloodGroup"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.bloodGroup}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.ECG_REPORT}
            name="ecgReport"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.ecgStatus}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.ADMITTED_FROM_NON_HEALTHCARE}
            name="admittedFrom"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.booleanType}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.PROCESS_TYPES}
            name="processTypes"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.processType}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.BED_TYPES}
            name="bedTypes"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.bedType}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDetails;
