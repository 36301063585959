import { Carousel } from "antd";
import BANNER_1 from "../../Assets/images/Home/bg1.png";
import BANNER_2 from "../../Assets/images/Home/bg2.png";
import BANNER_3 from "../../Assets/images/Home/bg3.png";
import { HOME } from "../Common/AppContent";
import HeroSection from "../Common/Layouts/HeroSection";

const Home = ({ isMobile, isTablet }) => {
  const renderCarousel = () => {
    return (
      <Carousel autoplay arrows={false} slidesToShow={1} dots={false}>
        {[BANNER_1, BANNER_2, BANNER_3].map((item, index) => {
          return (
            <div key={index}>
              <img src={item} alt={index} className="home-banner-image" />
            </div>
          );
        })}
      </Carousel>
    );
  };

  return (
    <HeroSection
      carousel={renderCarousel()}
      title={isMobile || isTablet ? HOME.MOBILE_TITLE : HOME.TITLE}
      subTitle={HOME.SUB_TITLE}
      description={HOME.DESCRIPTION}
      descColSpan={22}
    />
  );
};

export default Home;
