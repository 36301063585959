import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doctors: {},
  defaultDoctor: localStorage.getItem("defaultDoctor"),
  alerts: { status: "Pending" },
  claims: { status: "Pending" },
  patients: {},
  providers: { role: "Provider" },
  categories: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    resetFilterState: (state) => {
      state.doctors = {};
      state.defaultDoctor = null;
      state.categories = null;
      state.alerts = { status: "Pending" };
      state.claims = { status: "Pending" };
      state.patients = {};
      state.providers = { role: "Provider" };
    },
    resetAlertFilter: (state) => {
      state.alerts = { status: "Pending" };
    },
    setDoctors: (state, action) => {
      state.doctors = action.payload;
    },
    setDefaultDoctor: (state, action) => {
      state.defaultDoctor = action.payload;
    },
    setAlertFilters: (state, action) => {
      state.alerts = { ...state.alerts, ...action.payload };
    },
    setClaimFilters: (state, action) => {
      state.claims = { ...state.claims, ...action.payload };
    },
    setPatientFilters: (state, action) => {
      state.patients = { ...state.patients, ...action.payload };
    },
    setProviderFilters: (state, action) => {
      state.providers = { ...state.providers, ...action.payload };
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const {
  setDoctors,
  resetFilterState,
  setDefaultDoctor,
  setAlertFilters,
  setPatientFilters,
  setProviderFilters,
  resetAlertFilter,
  setClaimFilters,
  setCategories,
} = filterSlice.actions;
export default filterSlice.reducer;
