import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Components/Common/Routing";
import "./App.css";
import { auth } from "./firebase";
import { setIsMobile, setIsTablet } from "./Reducers/appSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getRemoteConfigData,
  reloadPatientPrograms,
} from "./Components/Common/Utils";
import { useApiLoader } from "./Components/Common/ApiLoaderContext";
import { postApis } from "./Components/Common/Apis";
import { patientRingDetailsUrl } from "./Components/Common/Endpoints";
import { Spin } from "antd";

const App = () => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const profileDetails = useSelector((state) => state?.profile?.profileDetails);
  const [uid, setUid] = useState();
  const { showFullLoader, setShowFullLoader } = useApiLoader();

  const mobileAppErrorHandler = () => {
    try {
      auth?.onAuthStateChanged((user) => {
        if (user) {
          setUid(user?.uid);
          window?.webkit?.messageHandlers?.uidMessageHandler?.postMessage({
            uid: user?.uid,
            programs: profileDetails?.programs || userDetails?.programs,
          });
        }
      });
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    mobileAppErrorHandler();

    window.callReactFunction = (data) => {
      const testing = JSON.parse(data);
      window.location.assign(testing?.deepLink);
    };

    window.deviceConnectionDetails = (data) => {
      localStorage.setItem("deviceConnectionDetails", data);
      return data;
    };

    window.deviceBatteryDetails = (data) => {
      localStorage.setItem("deviceBatteryDetails", data);
      return data;
    };

    window.ringStatusUpdate = async (data) => {
      setShowFullLoader(true);
      localStorage.setItem("ringStatusDetails", data);
      await postApis(patientRingDetailsUrl, JSON.parse(data))
        .then(async () => await reloadPatientPrograms(true))
        .catch(() => setShowFullLoader(false));
      return data;
    };

    window.getUserInfo = () => {
      if (uid) {
        return {
          uid: uid,
          programs: profileDetails?.programs || userDetails?.programs,
        };
      } else {
        return "UID not found";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, profileDetails]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth < 768));
      dispatch(
        setIsTablet(window.innerWidth < 1110 && window.innerWidth > 768)
      );
    };
    window.addEventListener("resize", handleResize);

    const metaTag = document.querySelector('meta[name="description"]');
    getRemoteConfigData("RELEASES").then((res) => {
      if (metaTag) {
        metaTag.setAttribute(
          "content",
          `SH Version ${res?.version} and Released On ${res?.date}`
        );
      }
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routing />
      {showFullLoader && <Spin fullscreen size="large" />}
    </BrowserRouter>
  );
};

export default App;
