import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../../Common/Utils";
import BG from "../../../Assets/images/videoBg.png";
import ImageCard from "../../Common/ImageCard";
import { VIDEOS } from "../../Common/AppContent";
import HeroSection from "../../Common/Layouts/HeroSection";
import { useSelector } from "react-redux";
const { Paragraph } = Typography;

const Videos = () => {
  const [data, setData] = useState();
  const isTablet = useSelector((state) => state?.app?.isTablet);

  useEffect(() => {
    getRemoteConfigData("VIDEOS").then((result) => setData(result?.videos));
  }, []);

  return (
    <div data-testid="videos">
      <HeroSection
        imgSrc={BG}
        titleColSpan={isTablet ? 20 : 12}
        title={VIDEOS.TITLE}
        subTitle={VIDEOS.SUB_TITLE}
        description={VIDEOS.DESCRIPTION}
      />

      <div className="mt3">
        {data?.map((obj, oIndex) => {
          return (
            <div key={oIndex}>
              <div
                style={{ backgroundColor: obj?.color }}
                className="section-bg"
              >
                {obj?.section}
              </div>
              <div className="videos">
                <Row gutter={[32, 32]} justify={"space-evenly"}>
                  {obj?.list?.map((item, index) => {
                    return (
                      <Col xs={24} sm={12} md={12} lg={6} xl={6} key={index}>
                        <ImageCard
                          linkedCard={true}
                          minHeight="240px"
                          id={item?.id}
                          imgSrc={item?.thumbnails?.withIcon}
                          title={
                            <Paragraph
                              ellipsis={{ rows: 2 }}
                              style={{ marginBottom: 0 }}
                            >
                              {item?.title}
                            </Paragraph>
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Videos;
