import { Card, Col, Row } from "antd";
import MonitorChart from "./MonitorChart";

const Updates = ({ dashboardDetails }) => {
  return (
    dashboardDetails?.gender && (
      <Row gutter={[16, 16]} className="mt1">
        <Col span={24}>
          <Card title="Gender">
            <MonitorChart gender={dashboardDetails?.gender} />
          </Card>
        </Col>
      </Row>
    )
  );
};

export default Updates;
