import SECURITY from "../../Assets/images/Home/security.png";
import INSURANCE from "../../Assets/images/Home/insurance.png";
import DOES_IT_WORKS from "../../Assets/images/Home/itWorks.png";
import JUNK from "../../Assets/images/Home/junk.png";
import { Col, Row } from "antd";
import { HOME } from "../Common/AppContent";

const AboutUs = ({ isMobile }) => {
  const imageFollowedText = (text, image) => {
    return (
      <Row justify={"space-evenly"} className="mt2" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} className="flex-center">
          <div className="paragraph mt1 fs20">{text}</div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <img src={image} alt="what we do" width="100%" className="right" />
        </Col>
      </Row>
    );
  };

  const textFollowedImage = (text, image) => {
    return (
      <Row justify={"space-evenly"} className="mt2" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <img src={image} alt="what we do" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} className="flex-center">
          <div className="paragraph mt1 fs20">{text}</div>
        </Col>
      </Row>
    );
  };

  return (
    <div className="pd3" data-testid="aboutUs">
      {imageFollowedText(HOME.ABOUT_US.P1, SECURITY)}
      {isMobile
        ? imageFollowedText(HOME.ABOUT_US.P2, INSURANCE)
        : textFollowedImage(HOME.ABOUT_US.P2, INSURANCE)}
      {imageFollowedText(HOME.ABOUT_US.P3, DOES_IT_WORKS)}
      {isMobile
        ? imageFollowedText(HOME.ABOUT_US.P4, JUNK)
        : textFollowedImage(HOME.ABOUT_US.P4, JUNK)}
    </div>
  );
};

export default AboutUs;
