import { Card, Col, Divider, Empty, Row } from "antd";
import { displayDateTime } from "../../Common/Utils";

const Vitals = ({ vitalDetails }) => {
  const span = 24 / Object.keys(vitalDetails).length;

  return Object.keys(vitalDetails).map((key, index) => (
    <Col xs={24} sm={24} md={12} lg={span} xl={span} key={index}>
      <Card
        title={key}
        styles={{
          body: {
            height: vitalDetails[key]?.value.length > 0 ? "350px" : "160px",
            overflow: "auto",
            padding: "10px",
            background: "#f8f8f8",
          },
        }}
      >
        {vitalDetails[key]?.value.length > 0 ? (
          vitalDetails[key]?.value?.map((item, index) => (
            <div key={index}>
              {Object.keys(item).map((subKey, index) => (
                <Row key={index} className="mt05">
                  <Col span={14} className="fs11 flex-center">
                    {displayDateTime(subKey * 1000)}
                  </Col>
                  <Col
                    span={10}
                    className="fs12 bold flex-center"
                    style={{ color: `${vitalDetails[key]?.color}` }}
                  >
                    {item[subKey]}
                  </Col>
                  <Divider style={{ margin: "2px" }} />
                </Row>
              ))}
            </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    </Col>
  ));
};

export default Vitals;
