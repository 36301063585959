import { Flex } from "antd";
import { Typography } from "antd";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../../firebase";
import { setOtpSent, setAllStepDetails } from "../../../../Reducers/authSlice";
import { postApis } from "../../Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import CustomForm from "../../CustomForm";
import { patientVerifyUserUrl } from "../../Endpoints";
import {
  labelledConfirmPasswordInput,
  labelledEmailInput,
  labelledPasswordInput,
  labelledPhoneInput,
} from "../../FormFields";
import useNotify from "../../Notify";
import { processedPhoneNumber } from "../../Utils";
import { providerVerifyUserUrl } from "../../Endpoints";
const { Title, Text } = Typography;

const ProviderStepOne = ({ setCurrentStep }) => {
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state?.app?.userType);
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const checkRegisteredUser = async (values) => {
    const phone = await processedPhoneNumber(values);

    const updatedValues = {
      ...values,
      phoneNumber: phone,
    };
    delete updatedValues["confirmPassword"];

    await postApis(
      userType === USER_TYPES.PATIENT
        ? patientVerifyUserUrl
        : providerVerifyUserUrl,
      {
        phoneNumber: phone,
        email: values?.email,
      }
    )
      .then((response) => {
        if (response?.data?.userExists) {
          setLoading(false);
          notify(NOTIFICATIONS.ERROR.KEY, response?.data?.message);
        } else {
          setLoading(true);
          signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              dispatch(setOtpSent(true));
              dispatch(setAllStepDetails(updatedValues));
              setLoading(false);
              setCurrentStep(1);
            })
            .catch((error) => {
              setLoading(false);
              notify(
                NOTIFICATIONS.ERROR.KEY,
                error?.message?.includes("invalid-phone-number")
                  ? "Invalid Phone Number"
                  : error?.message
              );
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: () => {
        console.log("Verified Recaptcha.");
      },
    });
    checkRegisteredUser(values);
  };

  return (
    <div data-testid="stepOne">
      <Flex vertical={true} className="media-mt-3">
        <Title>Registration</Title>
        <Text>Please enter your phone number below</Text>
        <Text>We will send a verification code to your phone</Text>
      </Flex>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={() => setLoading(false)}
        formFields={[
          labelledPhoneInput,
          labelledEmailInput,
          labelledPasswordInput,
          labelledConfirmPasswordInput,
        ]}
        initialValues={{ code: "+1" }}
        style={{
          width: 400,
        }}
        loading={loading}
        text={BUTTONS.REGISTER}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      />
    </div>
  );
};

export default ProviderStepOne;
