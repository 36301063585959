import { Layout } from "antd";
import PublicHeader from "./Headers/Public";
import LargeFooter from "./Footers/Large";

const { Content } = Layout;

const SignUpLayout = (props) => {
  return (
    <Layout className="mh100vh" data-testid="signup-layout">
      <PublicHeader hideMenuBar={true} />
      <div className="auth-layout">
        <Content className="shadow mt1 mb1">
          <div id="recaptcha" className="flex-center"></div>
          {props.children}
        </Content>
      </div>
      {!window?.webkit?.messageHandlers?.uidMessageHandler && <LargeFooter />}
    </Layout>
  );
};
export default SignUpLayout;
