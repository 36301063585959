import "./style.css";
import Overview from "./Overview";
import Updates from "./Updates";
import { useEffect, useState } from "react";
import { providerDashboardUrl } from "../../Common/Endpoints";
import { getApis } from "../../Common/Apis";
import { useSelector } from "react-redux";
import { Card, Col, Empty, Flex, Row } from "antd";
import { useApiLoader } from "../../Common/ApiLoaderContext";

const ProviderDashboard = () => {
  const [dashboardDetails, setDashboardDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);

  const getDashboardData = async () => {
    setShowLoader(true);
    await getApis(
      providerDashboardUrl,
      defaultDoctor ? { doctor: defaultDoctor } : {}
    )
      .then((response) => {
        setShowLoader(false);
        setDashboardDetails(response?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getDashboardData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDoctor]);

  return (
    !showLoader && (
      <>
        {dashboardDetails ? (
          <div data-testid="provider-dashboard">
            {defaultDoctor && (
              <Row className="mb2">
                <Col span={24} className="flex-start-center">
                  <Flex wrap="wrap" justify={"start"}>
                    <div className="bold mr05">Selected Provider: </div>
                    <div>{defaultDoctor}</div>
                  </Flex>
                </Col>
              </Row>
            )}
            <Overview dashboardDetails={dashboardDetails} />
            <Updates dashboardDetails={dashboardDetails} />
          </div>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </>
    )
  );
};

export default ProviderDashboard;
