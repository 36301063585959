import { Col, Row, Typography } from "antd";
import APP_SCREEN from "../../Assets/images/Home/appScreen.png";
import BTN from "../../Assets/images/Home/appBtn.png";
import { HOME } from "../Common/AppContent";
import { Link } from "react-router-dom";
const { Title } = Typography;

const DownloadApp = ({ isMobile, isTablet }) => {
  return (
    <div className="pd3">
      <Row justify={"space-around"} gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          className="flex-start-center"
        >
          <div>
            <Title
              level={2}
              className={isTablet || isMobile ? "text-center" : ""}
            >
              <div className="orange mr075">{HOME.APP.ORANGE_HEADER}</div>
              <div className="blue">{HOME.APP.BLUE_HEADER}</div>
            </Title>
            <div className="paragraph mt2 fs18">{HOME.APP.DESCRIPTION}</div>
            <div
              className={
                isTablet || isMobile ? "mt2 mb1 text-center" : "mb1 mt2"
              }
            >
              <Link
                to="https://apps.apple.com/us/app/saiwell/id6504409622"
                target={"_blank"}
              >
                <img src={BTN} alt="download" />
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={18} sm={12} md={12} lg={6} xl={6}>
          <img src={APP_SCREEN} alt="screen" width="100%" />
        </Col>
      </Row>
    </div>
  );
};

export default DownloadApp;
