import { clinicAdminFotonaBookingsUrl } from "../../../Common/Endpoints";
import Bookings from "../../../Common/Bookings";

const ClinicAdminFotona = () => {
  return (
    <Bookings
      getUrl={clinicAdminFotonaBookingsUrl}
      redirectUrl={false}
      putUrl={clinicAdminFotonaBookingsUrl}
      locationType="fotona"
    />
  );
};

export default ClinicAdminFotona;
