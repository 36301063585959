import { Col } from "antd";
import SlitAnswers from "./Slit";
import BiologicsAnswers from "./Biologics";
import { SERVICES } from "../../Common/Constants";
import TinnitusAnswers from "./Tinnitus";

const Questionnaires = ({
  questionnaireDetails,
  service,
  profileDetails,
  episodeDetails,
  questionnaires,
}) => {
  
  return (
    <Col span={24}>
      {service === SERVICES.SLIT ? (
        <SlitAnswers
          questionnaireDetails={questionnaireDetails}
          profileDetails={profileDetails}
          episodeDetails={episodeDetails}
          questionnaires={questionnaires}
        />
      ) : service === SERVICES.TINNITUS ? (
        <TinnitusAnswers
          service={service}
          questionnaireDetails={questionnaireDetails}
          questionnaires={questionnaires}
          profileDetails={profileDetails}
        />
      ) : (
        <BiologicsAnswers
          questionnaireDetails={questionnaireDetails}
          profileDetails={profileDetails}
          episodeDetails={episodeDetails}
          questionnaires={questionnaires}
        />
      )}
    </Col>
  );
};

export default Questionnaires;
