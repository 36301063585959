import { Empty, Table } from "antd";
import { useEffect, useState } from "react";

const BaselineDetails = ({ emrDetails }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (emrDetails?.baselineResults) {
      const sortKey = (item) => {
        const label = item.numberOfDays;
        if (label.startsWith(">")) {
          return Infinity;
        } else {
          return parseInt(label.split()[0], 10);
        }
      };

      const sortedData = [...emrDetails?.baselineResults].sort(
        (a, b) => sortKey(a) - sortKey(b)
      );
      setData(sortedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Number Of Days",
      dataIndex: "numberOfDays",
    },
    {
      title: "Risk(%)",
      dataIndex: "risk",
    },
  ];

  return data ? (
    <Table
      bordered
      rowKey={(record) => record?.numberOfDays}
      columns={columns}
      dataSource={data}
      scroll={{ x: "100%" }}
      pagination={false}
    />
  ) : (
    <Empty />
  );
};

export default BaselineDetails;
