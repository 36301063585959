import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({ loading, text }) => {
  return loading ? (
    <Spin indicator={<LoadingOutlined className="spinner" />} />
  ) : (
    text
  );
};

export default Loader;
