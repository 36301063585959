import { Col, Typography } from "antd";
import { Link } from "react-router-dom";
import { COMMON } from "./AppContent";
const { Title } = Typography;

const ColorWidget = ({
  colSpan,
  minHeight,
  imgSrc,
  imgWidth,
  title,
  description,
  path,
  textClass,
  widgetClass,
  linkClass,
}) => {
  return (
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={colSpan ? colSpan : 6}
      xl={colSpan ? colSpan : 6}
    >
      <div
        className={`${widgetClass} text-center`}
        style={{ minHeight: minHeight }}
      >
        <img src={imgSrc} alt="icon" width={imgWidth ? imgWidth : "90"} />
        <div className="mt2">
          <Title level={4} className={textClass}>
            {title}
          </Title>
        </div>
        <div className="text-center mt1 lh105">{description}</div>
        {path && (
          <div className="text-center mt2">
            <Link
              to={path}
              className={`${linkClass} bold center-bottom`}
              target={"_blank"}
            >
              {COMMON.LEARN_MORE}
            </Link>
          </div>
        )}
      </div>
    </Col>
  );
};

export default ColorWidget;
