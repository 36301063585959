import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Result,
  Row,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import { providerViewPatientUnlinkDeviceUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";
import { getRemoteConfigData } from "../../Common/Utils";

const Devices = ({ deviceDetails, token, service }) => {
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deviceImages, setDeviceImages] = useState();
  const { confirm } = Modal;
  const { notify } = useNotify();

  useEffect(() => {
    getRemoteConfigData("DEVICES").then((result) => {
      setDeviceImages(result?.images);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showConfirm = (values) => {
    confirm({
      title: "Are you sure?",
      content: "This action will unlink the devices.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        onFinish(values);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      ...{
        token: token,
        service: service,
        devices: selectedDevices,
      },
    };
    await postApis(providerViewPatientUnlinkDeviceUrl, formData)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const validateCheckbox = (_, value) => {
    if (value && value.length > 0) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Please select at least one item."));
    }
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedDevices(checkedValues);
  };

  return (
    <Card>
      <div className="box-shadow pd1">
        If patient wants to return the devices to the clinic please choose the
        necessary devices and click on the Unlink button. If all the devices are
        selected the patient will be unregistered from the program
        automatically.
      </div>
      {deviceDetails?.map((data, index) => {
        return (
          <Form
            key={index}
            layout="vertical"
            initialValues={{}}
            onFinish={showConfirm}
            onFinishFailed={onFinishFailed}
            style={{ margin: 0 }}
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
          >
            <div className={"orange bold fs20 mt2"}>{data?.name}</div>
            <Divider style={{ margin: "0.25rem 0 1.75rem 0" }} />
            {data?.connected?.length === 0 ? (
              <Result title="Devices data not found" />
            ) : (
              <>
                <div className="bold fs16 text-center" title="Gateway ID">
                  Gateway: {data?.gatewayId}
                </div>
                <Row className="mt1">
                  <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                    <Form.Item
                      name="devices"
                      rules={[{ validator: validateCheckbox }]}
                    >
                      <Checkbox.Group
                        value={selectedDevices}
                        onChange={handleCheckboxChange}
                        style={{ display: "block" }}
                        direction="column"
                      >
                        {data?.connected?.map((item, subIndex) => (
                          <div key={subIndex} className="mt1">
                            <Checkbox value={item?.device} name={item?.device}>
                              <div className="flex-center">
                                <img
                                  src={deviceImages?.[item?.device]}
                                  alt={item?.device}
                                  width="60"
                                  style={{
                                    border: "solid 1px #eee",
                                    borderRadius: "4px",
                                  }}
                                />
                                <div className="display-inline ml1">
                                  <div className="bold fs16 light-green">
                                    {item?.device}
                                  </div>
                                  <div className="grey fs13">
                                    MAC: {item?.macAddress}
                                  </div>
                                </div>
                              </div>
                            </Checkbox>
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={0} sm={0} md={0} lg={2} xl={2}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                    <Form.Item
                      name="reason"
                      size="large"
                      rules={[
                        {
                          required:
                            selectedDevices?.length === data?.connected?.length,
                        },
                      ]}
                    >
                      <TextArea
                        className="mt1"
                        rows={data?.connected?.length === 3 ? 8 : 4}
                        placeholder="Add a reason"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={"center"} className="mt1">
                  <Col xs={24} sm={24} md={12} lg={6} xl={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        disabled={loading}
                      >
                        <Loader loading={loading} text={BUTTONS.UNLINK} />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        );
      })}
    </Card>
  );
};

export default Devices;
