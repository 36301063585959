import { Button, Layout, Menu, Drawer, Row, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LOGO from "../../../../Assets/images/logo.png";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ROUTES } from "../../Constants";
import { useSelector } from "react-redux";
import AuthHeader from "./Auth";
const { Header } = Layout;

const items = [
  {
    key: ROUTES.DEFAULT.PATH,
    label: ROUTES.DEFAULT.LABEL,
  },
  {
    key: ROUTES.PUBLIC.WHAT_WE_DO.PATH,
    label: ROUTES.PUBLIC.WHAT_WE_DO.LABEL,
  },
  {
    key: ROUTES.PUBLIC.FOR_PATIENTS.PATH,
    label: ROUTES.PUBLIC.FOR_PATIENTS.LABEL,
  },
  {
    key: ROUTES.PUBLIC.FOR_PROVIDERS.PATH,
    label: ROUTES.PUBLIC.FOR_PROVIDERS.LABEL,
  },
  {
    key: ROUTES.PUBLIC.VIDEOS.PATH,
    label: ROUTES.PUBLIC.VIDEOS.LABEL,
  },
  {
    key: ROUTES.PUBLIC.COGNITIVE_JOURNEY.PATH,
    label: ROUTES.PUBLIC.COGNITIVE_JOURNEY.LABEL,
  },
  {
    key: ROUTES.PUBLIC.RESPIRATORY_CARE.PATH,
    label: ROUTES.PUBLIC.RESPIRATORY_CARE.LABEL,
  },
  {
    key: ROUTES.PUBLIC.TINNITUS.PATH,
    label: ROUTES.PUBLIC.TINNITUS.LABEL,
  },
  {
    key: ROUTES.PUBLIC.FAQ.PATH,
    label: ROUTES.PUBLIC.FAQ.LABEL,
  },
  {
    key: ROUTES.PUBLIC.CONTACT_US.PATH,
    label: ROUTES.PUBLIC.CONTACT_US.LABEL,
  },
];

const PublicHeader = ({ hideMenuBar }) => {
  const isLoggedIn = localStorage.getItem("authToken");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderLogo = (className) => {
    return (
      <Link
        to={ROUTES.DEFAULT.PATH}
        className={`${className ? className : ""}`}
      >
        <img src={LOGO} alt="Logo" width="140" />
      </Link>
    );
  };

  const renderActions = () => {
    if (window?.webkit?.messageHandlers?.uidMessageHandler) {
      return (
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              block
              className="blue-btn mr05"
              onClick={() => navigate(ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP)}
            >
              Patient Login
            </Button>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              block
              className="blue-btn mr05"
              onClick={() => navigate(ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP)}
            >
              Patient Login
            </Button>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              block
              onClick={() => navigate(ROUTES.PROVIDER.LOGIN.PATHS.EMAIL)}
            >
              Provider/Staff Login
            </Button>
          </Col>
        </Row>
      );
    }
  };

  const handleMenuClick = (e) => {
    navigate(e.key);
    setIsDrawerOpen(false);
  };

  const renderLoggedInHeaders = () => {
    if (isLoggedIn) {
      return (
        <AuthHeader
          handleSideMenuClick={handleMenuClick}
          toggleDrawer={toggleDrawer}
          isDrawerOpen={isDrawerOpen}
          userDetails={userDetails}
          fromHomePage={true}
          pathname={location.pathname}
        />
      );
    } else {
      return isMobile ? (
        <Header className="fixed-header">
          {renderLogo()}

          {!window?.webkit?.messageHandlers?.uidMessageHandler && (
            <>
              <MenuOutlined
                onClick={toggleDrawer}
                style={{ fontSize: "22px" }}
                className="mt05 right"
              />

              <Drawer
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CloseOutlined
                      onClick={toggleDrawer}
                      className="cursor-pointer"
                    />
                  </div>
                }
                width={"70%"}
                placement="right"
                closable={false}
                onClose={toggleDrawer}
                open={isDrawerOpen}
              >
                {items.map((item, index) => (
                  <div key={index} className="pd1">
                    <a href={item.key}>{item.label}</a>
                  </div>
                ))}
              </Drawer>
            </>
          )}

          <div className="mt1">{renderActions()}</div>
        </Header>
      ) : (
        <Header className="fixed-header">
          <Row justify={"space-between"} className="flex-center">
            <Col xs={24} sm={12} md={12} lg={16} xl={16}>
              {renderLogo()}
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              {renderActions()}
            </Col>
          </Row>
        </Header>
      );
    }
  };

  return (
    <>
      {renderLoggedInHeaders()}
      {!hideMenuBar && !isMobile && (
        <Header className="menu">
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            style={{
              flex: 1,
              minWidth: 0,
              backgroundColor: "#21575B",
              color: "#fff",
            }}
            items={items}
            onClick={handleMenuClick}
          />
        </Header>
      )}
    </>
  );
};

export default PublicHeader;
