import { Divider, Table } from "antd";
import { displayDateTime } from "../../Common/Utils";

const Transactions = ({ transactions }) => {
  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Payment Time",
      dataIndex: "created",
      render: (_, record) => displayDateTime(record?.created),
    },
  ];

  return Object.keys(transactions)?.map((clinic, cIndex) => {
    return (
      <div key={cIndex} className={cIndex > 0 ? "mt2" : "mt05"}>
        <div className="bold blue fs18">{clinic}</div>
        <Divider style={{ margin: "0.25rem 0 1.5rem 0" }} />
        <Table
          bordered
          scroll={{
            x: "100%",
            y: 250,
          }}
          rowKey={(record) => record?.created}
          columns={columns}
          dataSource={transactions?.[clinic]?.transactions}
          pagination={false}
        />
      </div>
    );
  });
};

export default Transactions;
