import { Layout } from "antd";
import Banner from "./Banner";
import Home from "./Home";
import "./style.css";
import WhatWeDo from "./WhatWeDo";
import Clients from "./Clients";
import PublicHeader from "../Common/Layouts/Headers/Public";
import LargeFooter from "../Common/Layouts/Footers/Large";
import Video from "./Video";
import AboutUs from "./AboutUs";
import { ROUTES } from "../Common/Constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRemoteConfigData } from "../Common/Utils";
import { useSelector } from "react-redux";
import DownloadApp from "./DownloadApp";
import ForPatient from "./ForPatient";
const { Content } = Layout;

const Welcome = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;
  const isLoggedIn = localStorage.getItem("authToken");
  const auth = useSelector((state) => state.auth);
  const [videos, setVideos] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash) {
      const targetId = window.location.hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
    getRemoteConfigData("VIDEOS").then((res) => setVideos(res));
    if (!isLoggedIn && auth?.isLoggedIn) {
      navigate(ROUTES.LOGOUT.PATH);
    }

    if (window?.webkit?.messageHandlers?.uidMessageHandler) {
      navigate(ROUTES.PATIENT.PROFILE.PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="mh100vh" data-testid="home-layout">
      <PublicHeader />
      <Content className="mh100vh">
        <Home isTablet={isTablet} isMobile={isMobile} />
        <WhatWeDo />
        <Video videos={videos} />
        <Clients />
        <AboutUs isMobile={isMobile} />
        <Banner isMobile={isMobile} />
        <ForPatient videos={videos} />
        <DownloadApp isTablet={isTablet} isMobile={isMobile} />
      </Content>
      <LargeFooter />
    </Layout>
  );
};
export default Welcome;
