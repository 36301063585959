import { Card, Empty } from "antd";

const MentalWellness = () => {
  return (
    <Card>
      <Empty />
    </Card>
  );
};

export default MentalWellness;
