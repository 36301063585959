import { Avatar, Col, Divider, Flex, Row, Typography } from "antd";
import { displayUserName } from "../../Common/Utils";
import COVER_PHOTO from "../../../Assets/images/coverPhoto.png";
import { FORM_FIELDS } from "../../Common/Constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../../../Reducers/profileSlice";
const { Title } = Typography;

const ProviderProfile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const dispatch = useDispatch();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);

  useEffect(() => {
    if (Object.entries(profileDetails).length === 0) {
      dispatch(setProfileDetails(userDetails));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <Row gutter={[16, 16]} className="mt1">
          <Col span={24} className="flex-center">
            <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
              <div>{label}: </div>
              <div className="grey">
                {Array.isArray(value) ? value.join(", ") : value}
              </div>
            </Flex>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Row gutter={[16, 16]} justify="center" data-testid="provider-profile">
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <div className="small-left-widget">
          <div className="cover-photo-container">
            <img src={COVER_PHOTO} alt="CoverPic" className="fit cover-photo" />
            <Avatar size={94} className="avatar avatar-letter">
              {displayUserName()?.charAt(0).toUpperCase()}
            </Avatar>
          </div>
          <div className="text-center mt4">
            <Row>
              <Col span={24}>
                <Title level={3}>{displayUserName()}</Title>
              </Col>
            </Row>

            <Divider style={{ margin: "0.5rem 0 1.5rem 0" }} />
            <div className="mb2">
              {renderProfileDetails(
                FORM_FIELDS.CATEGORY,
                userDetails?.specialization
              )}
              {renderProfileDetails(FORM_FIELDS.CLINIC, userDetails?.clinic)}
              {renderProfileDetails(FORM_FIELDS.ROLE, userDetails?.role)}
              {renderProfileDetails(FORM_FIELDS.GENDER, userDetails?.gender)}
              {renderProfileDetails(FORM_FIELDS.EMAIL, userDetails?.email)}
              {renderProfileDetails(
                FORM_FIELDS.PHONE,
                userDetails?.phoneNumber
              )}
              {renderProfileDetails(
                FORM_FIELDS.PROGRAMS,
                userDetails?.programs?.join(", ")
              )}
              {defaultDoctor && (
                <>
                  <Divider style={{ margin: "1rem 0 0 0" }} />
                  {renderProfileDetails("Selected Provider", defaultDoctor)}
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProviderProfile;
