import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Divider, Result, Row } from "antd";
import { useSelector } from "react-redux";
import { BUTTONS, ROUTES } from "../../../Common/Constants";
import { centalisedSubmitBtn, rowDataWithIcon } from "../../../Common/Utils";
import { displayFotonaConfirmInfo } from "./Info";

const Confirm = () => {
  const bookingDetails =
    useSelector((state) => state?.bookings?.details) ||
    JSON.parse(localStorage.getItem("bookingDetails"));

  const onClickHandler = () => {
    localStorage.removeItem("bookingDetails");
    window.location.assign(ROUTES.PATIENT.BOOKINGS.PATH);
  };

  const cardBody = () => {
    if (bookingDetails) {
      return (
        <div>
          {displayFotonaConfirmInfo(bookingDetails)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
          {centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        </div>
      );
    } else {
      return (
        <Result
          subTitle="No data found"
          extra={centalisedSubmitBtn(false, BUTTONS.DONE, onClickHandler, 6)}
        />
      );
    }
  };

  return (
    <Row justify={"center"} className="mt2">
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div className="bold fs20 blue">
          <div className="flex-start-center">
            <InfoCircleFilled className="icon24 mr05" /> Booking Status
          </div>
        </div>
        <Divider style={{ margin: "0.5rem 0 2rem 0" }} />
        {cardBody()}
      </Col>
    </Row>
  );
};

export default Confirm;
