import { Col, Collapse, Row } from "antd";
import { useState } from "react";
import { devicesFaqs } from "./Data";

const Devices = ({ searchTerm }) => {
  const [selectedKey, setSelectedKey] = useState("1");

  const filteredItems = devicesFaqs.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnClick = (e) => {
    setSelectedKey(e.key);
  };

  return (
    <Row className="mt1">
      <Col span={24}>
        <Collapse
          expandIconPosition="end"
          items={filteredItems}
          defaultActiveKey={[selectedKey]}
          onClick={handleOnClick}
          className="fs18"
        />
      </Col>
    </Row>
  );
};

export default Devices;
