import { Table, Tag } from "antd";
import { displayOnlyDate, tagColors } from "../../Common/Utils";

const Claims = ({ claimDetails }) => {
  const columns = [
    {
      title: "Episode Number",
      dataIndex: "episodeNumber",
    },
    {
      title: "Episode Start Date",
      dataIndex: "episodeStartDate",
      render: (_, record) => displayOnlyDate(record?.episodeStartDate),
    },
    {
      title: "Raised At",
      dataIndex: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    {
      title: "Status",
      dataIndex: "billingStatus",
    },
    {
      title: "CPT Codes",
      dataIndex: "cptCodes",
      render: (_, { cptCodes }) => (
        <>
          {cptCodes.map((item, index) => {
            return (
              <Tag color={tagColors[index % tagColors.length]} key={index}>
                {item}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => `${record?.mrn}-${record?.episodeNumber}`}
      columns={columns}
      dataSource={claimDetails}
      scroll={{ x: "100%" }}
      pagination={false}
    />
  );
};

export default Claims;
