import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tag,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import {
  providerViewPatientEmrDataUrl,
  providerViewPatientSearchMedicinesUrl,
} from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";

const MedicineDetails = ({ emrDetails, token, getEmrData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientEmrDataUrl, {
      token: token,
      category: "medicineDetails",
      formData: values,
    })
      .then(() => {
        setLoading(false);
        getEmrData(true);
        dispatch(setEmrSelectedKey(6));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = async (e) => {
    const value = e.target.value;
    if (value === "") {
      setSearchResults(null);
    } else {
      if (value.length >= 4) {
        await postApis(providerViewPatientSearchMedicinesUrl, {
          target: value.toUpperCase(),
        })
          .then((response) => {
            setSearchResults(response?.data?.suggestions);
          })
          .catch(() => {});
      }
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onClickHandler = (value) => {
    const currentSelectedItems = form.getFieldValue("medicine") || [];
    if (!currentSelectedItems.includes(value)) {
      const updatedSelectedItems = [...currentSelectedItems, value];

      form.setFieldsValue({
        medicine: updatedSelectedItems,
      });
    }
  };

  return Object.keys(emrDetails?.medicineDetails)?.length > 0 ? (
    <div>
      <Divider>{FORM_FIELDS.SLECTED_MEDS}</Divider>
      <Row gutter={[16, 20]} justify={"center"} className="mt105">
        {emrDetails?.medicineDetails?.medicine?.map((item, index) => {
          return (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              className="text-center"
            >
              <div className="pd05 box-shadow grey-fill bold blue">{item}</div>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item label={FORM_FIELDS.MEDICINE} size="large">
            <Input
              placeholder={PLACEHOLDER.MEDICINE}
              style={{ textTransform: "uppercase" }}
              onChange={handleSearch}
            />
          </Form.Item>
          {searchResults && (
            <Card title="Choose a Medicine" className="ovf-auto">
              {searchResults?.map((item, index) => {
                return (
                  <Tag
                    key={index}
                    className="blue mt05 cursor-pointer"
                    onClick={() => onClickHandler(item)}
                  >
                    {item}
                  </Tag>
                );
              })}
            </Card>
          )}
        </Col>
        <Col span={24}>
          <Form.Item
            label={FORM_FIELDS.SLECTED_MEDS}
            name="medicine"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select mode="multiple" placeholder={FORM_FIELDS.SLECTED_MEDS} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MedicineDetails;
