import { Card, Col, Empty, Row, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES, SERVICES } from "../../Common/Constants";

const EpisodeLayout = ({
  children,
  showLoader,
  episodeDetails,
  graphDetails,
  selectedEpisode,
  onEpisodeChange,
  service,
}) => {
  const navigate = useNavigate();

  return (
    !showLoader && (
      <>
        {service === SERVICES.SLIT && (
          <Row className="mb1">
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => navigate(ROUTES.PATIENT.ORDERS.PATH)}
              >
                Order Vials
              </Button>
            </Col>
          </Row>
        )}
        {episodeDetails?.currentEpisodeNumber && graphDetails ? (
          <div data-testid="general-data">
            <Row gutter={[16, 16]} className="flex-start-center">
              <Col xs={24} sm={12} md={12} lg={6} xl={4} className="bold">
                Episode Number:
              </Col>

              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Select
                  size="large"
                  className="full-width"
                  placeholder="Select an Episode"
                  value={selectedEpisode}
                  options={Array.from(
                    { length: episodeDetails?.currentEpisodeNumber },
                    (_, index) => index + 1
                  ).map((item) => {
                    return { label: item, value: item };
                  })}
                  onChange={onEpisodeChange}
                />
              </Col>
            </Row>
            {children}
          </div>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </>
    )
  );
};

export default EpisodeLayout;
