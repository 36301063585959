import { Col, Flex, Row, Typography } from "antd";
import { HOME } from "../Common/AppContent";
import VideoPlayer from "../Common/VideoPlayer";
const { Title } = Typography;

const Video = ({ videos }) => {
  return (
    <div id="excitingAspects" className="mt2">
      <Row>
        <Col span={24}>
          <Title level={2}>
            <Flex wrap="wrap" className="flex-center">
              <span className="blue mr075">{HOME.FUTURE.BLUE_HEADER}</span>
              <span className="orange">{HOME.FUTURE.ORANGE_HEADER}</span>
            </Flex>
          </Title>
        </Col>
      </Row>

      <div className="mt2 mb2">
        <VideoPlayer
          link={videos?.home?.links?.intro}
          thumbnailWithIcon={videos?.home?.thumbnails?.withIcon?.intro}
          thumbnailWithoutIcon={videos?.home?.thumbnails?.withoutIcon?.intro}
        />
      </div>
    </div>
  );
};

export default Video;
