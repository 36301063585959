import { Button, Divider, Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { setLoggedIn } from "../../../Reducers/authSlice";
import { useDispatch } from "react-redux";
import { logAnalyticsEvent, setAccessTokens } from "../../Common/Utils";
import { useState } from "react";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Common/Constants";
import useNotify from "../../Common/Notify";
import { postApis } from "../../Common/Apis";
import {
  patientAccessTokenUrl,
  patientVerifyEmailUrl,
} from "../../Common/Endpoints";
import {
  emailInput,
  passwordInput,
  patientForgotPasswordLink,
} from "../../Common/FormFields";
import CustomForm from "../../Common/CustomForm";

const { Title } = Typography;

const PatientLogin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notify } = useNotify();

  const getAccessToken = async (uid, email) => {
    await postApis(patientAccessTokenUrl, {
      uid: uid,
    })
      .then((response) => {
        dispatch(setLoggedIn());
        setAccessTokens(response?.data);
        navigate(ROUTES.PATIENT.PROFILE.PATH);
        logAnalyticsEvent("login", {
          userType: USER_TYPES.PATIENT,
          loginType: "Email",
          data: email,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(patientVerifyEmailUrl, {
      email: values?.email,
    })
      .then(() => {
        signInWithEmailAndPassword(auth, values?.email, values?.password)
          .then((response) => {
            getAccessToken(response?.user?.uid, values?.email);
          })
          .catch((error) => {
            setLoading(false);
            notify(
              NOTIFICATIONS.ERROR.KEY,
              error?.message?.includes("wrong-password")
                ? NOTIFICATIONS.ERROR.INVALID_PASSWORD
                : error?.message
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div>
      <Title>Login</Title>
      <div className="grey">Please enter your login details below</div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[emailInput, passwordInput, patientForgotPasswordLink]}
        initialValues={{}}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.LOGIN}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      />

      <Flex justify={"space-between"} className="mt1">
        <Link to={ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP}>Phone Login</Link>
      </Flex>

      <Divider>OR</Divider>

      <Button
        className="blue-btn"
        onClick={() => navigate(ROUTES.PATIENT.REGISTER.PATH)}
      >
        Register Here
      </Button>
    </div>
  );
};
export default PatientLogin;
