import React, { useEffect } from "react";
import * as echarts from "echarts";

const PatientVitals = ({ graphData }) => {
  const divId = graphData?.title?.toLowerCase().replace(/\s+/g, "-");

  const getData = () => {
    let series = [];
    if (divId.includes("blood-pressure")) {
      Object.keys(graphData).forEach((key) => {
        series.push({
          data: graphData[key]?.list || [],
          type: "line",
          areaStyle: {
            color: graphData[key]?.color,
          },
          itemStyle: {
            color: graphData[key]?.color,
          },
        });
      });
    } else if (divId.includes("peak-expiratory-flow")) {
      series.push({
        data: graphData?.list || [],
        type: "line",
        areaStyle: { color: "none" },
        lineStyle: { color: "#28696D" },
        itemStyle: {
          color: "#28696D",
        },
        markArea: {
          data:
            graphData?.list?.length > 0
              ? [
                  [
                    {
                      yAxis: 0,
                      itemStyle: {
                        color: "#FF3535",
                        opacity: 0.6,
                      },
                    },
                    {
                      yAxis: graphData?.markLine?.best50 || 0,
                    },
                  ],
                  [
                    {
                      yAxis: graphData?.markLine?.best50 || 0,
                      itemStyle: {
                        color: "#FF7D01",
                        opacity: 0.6,
                      },
                    },
                    {
                      yAxis: graphData?.markLine?.best80 || 0,
                    },
                  ],
                  [
                    {
                      yAxis: graphData?.markLine?.best80 || 0,
                      itemStyle: {
                        color: "#00A73E",
                        opacity: 0.6,
                      },
                    },
                    {
                      yAxis: graphData?.markLine?.best100 || 0,
                    },
                  ],
                ]
              : [],
        },
      });
    } else {
      series.push({
        data: graphData?.list || [],
        type: "line",
        areaStyle: {},
        itemStyle: {
          color: graphData?.color,
        },
      });
    }

    return series;
  };

  useEffect(() => {
    const chartDom = document.getElementById(divId);
    let existingChart = echarts.getInstanceByDom(chartDom);
    if (existingChart) {
      existingChart.dispose();
    }

    const myChart =
      process.env.NODE_ENV === "test"
        ? echarts.init(chartDom, null, {
            width: 600,
            height: 400,
          })
        : echarts.init(chartDom);

    const seriesData = getData();
    const isEmpty = seriesData?.every((series) => series.data?.length === 0);

    const option = {
      title: {
        text: graphData?.title,
        left: "center",
      },
      xAxis: {
        type: "category",
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        scale: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        top: 60,
        bottom: 20,
        left: "10%",
        right: "14%",
      },

      series: seriesData,
    };

    if (isEmpty) {
      option.graphic = {
        type: "text",
        left: "center",
        top: "middle",
        style: {
          text: "No data",
          textAlign: "center",
          fill: "#999",
          fontSize: 20,
        },
      };
    }

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, divId]);

  return <div id={divId} style={{ width: "100%", height: "280px" }}></div>;
};

export default PatientVitals;
