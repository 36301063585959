import { Col, Layout, Row } from "antd";
import { Link } from "react-router-dom";
import POWERED_BY from "../../../../Assets/images/poweredBy.svg";
import { FOOTER, ROUTES } from "../../Constants";

const { Footer } = Layout;

const SmallFooter = () => {
  return (
    <Footer className="white-footer">
      <Row justify={"center"}>
        <Col xs={24} sm={24} md={12} lg={7} xl={7} className="flex-center">
          <div
            dangerouslySetInnerHTML={{
              __html: FOOTER.COPYRIGHT,
            }}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={3}
          lg={3}
          xl={3}
          className="flex-center top-margin"
        >
          <Link to={ROUTES.SAIGEWARE} target={"_blank"}>
            <img
              src={POWERED_BY}
              alt="Powered By"
              className="flex-center"
              width="100%"
            />
          </Link>
        </Col>
      </Row>
    </Footer>
  );
};

export default SmallFooter;
