import { Col, Row } from "antd";
import { Typography } from "antd";
import { WHAT_WE_DO } from "../Common/AppContent";
import { ROUTES } from "../Common/Constants";
import ExperienceNow from "../Common/ExperienceNow";
const { Title } = Typography;

const Banner = ({ isMobile }) => {
  return (
    <div className={"blue-bg"} data-testid="banner">
      <Row justify={"center"}>
        <Col xs={24} sm={24} md={24} lg={16} xl={20} className="pd1">
          <Title
            level={isMobile ? 3 : 2}
            className="fw400"
            style={{ color: "inherit" }}
          >
            {WHAT_WE_DO.HIGHLIGHT}
          </Title>
        </Col>
      </Row>
      <Row justify={"center"} className="pd1">
        <Col xs={16} sm={8} md={6} lg={5} xl={5}>
          <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
