import { useDispatch, useSelector } from "react-redux";
import { SERVICES } from "../../Common/Constants";
import General from "./General";
import { useParams } from "react-router-dom";
import Wellness from "./Wellness";
import PrePostProcedure from "./PrePostProcedure";
import EpisodeLayout from "./EpisodeLayout";
import { useEffect, useState } from "react";
import { getApis } from "../../Common/Apis";
import {
  setBestValueDetails,
  setEpisodeDetails,
  setGraphDetails,
  setSelectedEpisode,
} from "../../../Reducers/profileSlice";
import {
  patientGeneralEpisodeDetailsUrl,
  patientGeneralVitalsUrl,
  patientRespiratoryBestPefGraphUrl,
  patientRespiratoryEpisodeDetailsUrl,
  patientRespiratoryVitalsUrl,
  patientSurgeryEpisodeDetailsUrl,
  patientSurgeryVitalsUrl,
} from "../../Common/Endpoints";
import Ring from "./Ring";
import Respiratory from "./Respiratory";
import MentalWellness from "./MentalWellness";
import { getPageHeader } from "../../Common/Utils";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import Tinnitus from "./Tinnitus";

const PatientDashboard = () => {
  let { clinic, service } = useParams();
  service = service === SERVICES.SLIT ? service : getPageHeader(service);
  const profile = useSelector((state) => state.profile);
  const profileDetails = profile?.profileDetails;
  const graphDetails = profile?.graphDetails;
  const episodeDetails = profile?.episodeDetails;
  const selectedEpisode = profile?.selectedEpisode;
  const bestValueDetails = profile?.bestValueDetails;
  const { showLoader, setShowLoader } = useApiLoader();
  const [vitalsApiUrl, setVitalsApiUrl] = useState();
  const dispatch = useDispatch();

  const getEpisodeDetailsData = async (episodeUrl, vitalsUrl) => {
    setShowLoader(true);
    await getApis(episodeUrl, {
      clinic: clinic,
      service: service,
    })
      .then((response) => {
        dispatch(setEpisodeDetails(response?.data));
        dispatch(setSelectedEpisode(response?.data?.currentEpisodeNumber));
        if (vitalsUrl && response?.data?.currentEpisodeNumber) {
          getGraphData(response?.data?.currentEpisodeNumber, vitalsUrl);
          if ([SERVICES.BIOLOGICS, SERVICES.SLIT].includes(service)) {
            getBestPefGraph();
          }
        } else {
          setShowLoader(false);
        }
      })
      .catch(() => setShowLoader(false));
  };

  const getGraphData = async (episodeNumber, url) => {
    setShowLoader(true);
    await getApis(url, {
      episodeNumber: episodeNumber,
      clinic: clinic,
      service: service,
    })
      .then((response) => {
        setShowLoader(false);
        dispatch(setGraphDetails(response?.data));
      })
      .catch(() => setShowLoader(false));
  };

  const getBestPefGraph = async () => {
    await getApis(patientRespiratoryBestPefGraphUrl, {
      clinic: clinic,
      service: service,
    })
      .then((response) => {
        dispatch(setBestValueDetails(response?.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if ([SERVICES.BIOLOGICS, SERVICES.SLIT].includes(service)) {
      setVitalsApiUrl(patientRespiratoryVitalsUrl);
      getEpisodeDetailsData(
        patientRespiratoryEpisodeDetailsUrl,
        patientRespiratoryVitalsUrl
      );
    } else if (service === SERVICES.PRE_POST_PROCEDURE) {
      setVitalsApiUrl(patientSurgeryVitalsUrl);
      getEpisodeDetailsData(
        patientSurgeryEpisodeDetailsUrl,
        patientSurgeryVitalsUrl
      );
    } else if (service === SERVICES.GENERAL) {
      setVitalsApiUrl(patientGeneralVitalsUrl);
      getEpisodeDetailsData(
        patientGeneralEpisodeDetailsUrl,
        patientGeneralVitalsUrl
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  const onEpisodeChange = (value) => {
    getGraphData(value, vitalsApiUrl);
    dispatch(setSelectedEpisode(value));
  };

  const getServicePages = (service) => {
    switch (service) {
      case SERVICES.WELLNESS:
        return (
          <Wellness
            showLoader={showLoader}
            profileDetails={profileDetails}
            clinic={clinic}
          />
        );
      case SERVICES.TINNITUS:
        return (
          <Tinnitus
            profileDetails={profileDetails}
            clinic={clinic}
            service={service}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
          />
        );
      case SERVICES.BIOLOGICS:
      case SERVICES.SLIT:
        return (
          <EpisodeLayout
            onEpisodeChange={onEpisodeChange}
            showLoader={showLoader}
            episodeDetails={episodeDetails}
            selectedEpisode={selectedEpisode}
            graphDetails={graphDetails}
            service={service}
          >
            <Respiratory
              graphDetails={graphDetails}
              bestValueDetails={bestValueDetails}
            />
          </EpisodeLayout>
        );
      case SERVICES.PRE_POST_PROCEDURE:
        return (
          <EpisodeLayout
            onEpisodeChange={onEpisodeChange}
            showLoader={showLoader}
            episodeDetails={episodeDetails}
            selectedEpisode={selectedEpisode}
            graphDetails={graphDetails}
            service={service}
          >
            <PrePostProcedure graphDetails={graphDetails} />
          </EpisodeLayout>
        );
      case SERVICES.GENERAL:
        return (
          <EpisodeLayout
            onEpisodeChange={onEpisodeChange}
            showLoader={showLoader}
            episodeDetails={episodeDetails}
            selectedEpisode={selectedEpisode}
            graphDetails={graphDetails}
            service={service}
          >
            <General graphDetails={graphDetails} />
          </EpisodeLayout>
        );
      case SERVICES.RING:
        return <Ring setShowLoader={setShowLoader} showLoader={showLoader} />;
      case SERVICES.MENTAL_WELLNESS:
        return <MentalWellness />;
      default:
        return "";
    }
  };

  return <div data-testid="patient-dashboard">{getServicePages(service)}</div>;
};

export default PatientDashboard;
