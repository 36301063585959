import { Col } from "antd";
import BestPEF from "./BestPEF";
import Graphs from "./Graphs";

const Dashboard = ({ vitalDetails, graphDetails, bestPefDetails }) => {
  return (
    <>
      <Graphs graphDetails={graphDetails} />
      <Col span={24} className="mt1">
        <BestPEF vitalDetails={vitalDetails} bestPefDetails={bestPefDetails} />
      </Col>
    </>
  );
};

export default Dashboard;
