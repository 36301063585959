import { Modal } from "antd";

const Popup = (props) => {
  return (
    <Modal
      forceRender
      data-testid="modal"
      title={props?.title}
      open={props?.open}
      onCancel={props?.cancel}
      footer={props?.footer}
      maskClosable={props?.maskClosable || null}
      keyboard={props?.keyboard || null}
      width={props?.width || null}
      height={props?.height || null}
      styles={{ body: props?.bodyStyle || null }}
      style={props?.style || null}
    >
      {props?.children}
    </Modal>
  );
};

export default Popup;
