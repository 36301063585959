import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import { providerViewPatientEmrDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import { renderKeyValueDetails } from "../../../Common/Utils";

const LabDetails = ({ emrDetails, token, getEmrData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientEmrDataUrl, {
      token: token,
      category: "labDetails",
      formData: values,
    })
      .then(() => {
        setLoading(false);
        getEmrData(true);
        dispatch(setEmrSelectedKey(3));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(emrDetails?.labDetails)?.length > 0 ? (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.BLOOD_GLUCOSE,
          emrDetails?.labDetails?.bloodGlucose
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.BLOOD_UREA_NITROGEN,
          emrDetails?.labDetails?.bloodUreaNitrogen
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.HAEMOGLOBIN_LEVEL,
          emrDetails?.labDetails?.haemoglobinLevel
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.SERUM_POTASSIUM,
          emrDetails?.labDetails?.serumPotassium
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.SERUM_SODIUM,
          emrDetails?.labDetails?.serumSodium
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.SERUM_POTASSIUM}
            name="serumPotassium"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.SERUM_POTASSIUM} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.BLOOD_UREA_NITROGEN}
            name="bloodUreaNitrogen"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.BLOOD_UREA_NITROGEN} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            label={FORM_FIELDS.SERUM_SODIUM}
            name="serumSodium"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.SERUM_SODIUM} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.BLOOD_GLUCOSE}
            name="bloodGlucose"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.BLOOD_GLUCOSE} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.HAEMOGLOBIN_LEVEL}
            name="haemoglobinLevel"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.HAEMOGLOBIN_LEVEL} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default LabDetails;
