import { useState } from "react";
import ReactHlsPlayer from "react-hls-player";

const VideoPlayer = ({ link, thumbnailWithIcon, thumbnailWithoutIcon }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="video-container" onClick={handlePlayClick}>
      {link && isPlaying ? (
        <ReactHlsPlayer
          className="video-player"
          data-testid="videoPlayer"
          src={link}
          controls
          autoPlay={isPlaying}
          playsInline
          poster={thumbnailWithoutIcon}
          onContextMenu={handleContextMenu}
        />
      ) : (
        <video
          className="video-player"
          data-testid="videoPlayer"
          poster={thumbnailWithIcon}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
