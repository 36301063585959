import { Table } from "antd";
import { displayDateTime } from "../../Common/Utils";

const BestPefValues = ({ bestValueDetails }) => {
  const columns = [
    {
      title: "Best PEF Value",
      dataIndex: "value",
    },
    {
      title: "Vital Collected At",
      dataIndex: "vitalCollectedTimestamp",
      render: (_, record) => displayDateTime(record?.vitalCollectedTimestamp),
    },
  ];

  return (
    <Table
      className="mt2"
      rowKey={(record) => new Date(record?.vitalCollectedTimestamp).getTime()}
      columns={columns}
      dataSource={bestValueDetails}
      scroll={{ x: "100%" }}
      pagination={false}
    />
  );
};

export default BestPefValues;
