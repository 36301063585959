import { Col, Row, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApis } from "../../../Common/Apis";
import { bookingsInfo, userInfo } from "../../../Common/Bookings/Info";
import { BUTTONS, NOTIFICATIONS } from "../../../Common/Constants";
import { clinicAdminSleepBookSlotUrl } from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import { centalisedSubmitBtn, rowDataWithIcon } from "../../../Common/Utils";
const { Title } = Typography;

const Confirm = () => {
  const bookings = useSelector((state) => state?.bookings);
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const submitHandler = async () => {
    setLoading(true);
    const params = {
      ...bookings?.details,
      ...bookings?.patientDetails,
      bookingType: "SFOTO-HOME-SLEEP-DEVICE",
    };
    await postApis(clinicAdminSleepBookSlotUrl, params)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  return (
    <div className="mt2" data-testid="confirmManualBooking">
      <Title level={2} className="text-center">
        Confirm Here
      </Title>
      <Row justify={"center"} className="mt2">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {userInfo(bookings?.patientDetails)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
          {bookingsInfo(bookings?.details)?.map((item, index) => {
            return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
          })}
        </Col>
      </Row>
      {centalisedSubmitBtn(loading, BUTTONS.CONFIRM, submitHandler, 4)}
    </div>
  );
};

export default Confirm;
