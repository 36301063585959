import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Card, Divider, Spin, Timeline } from "antd";
import { useSelector } from "react-redux";

const DynamicList = ({
  clinic,
  service,
  timelineStaticData,
  timelineApiData,
  onBtnClick,
  loading,
  type,
}) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);

  const getItems = () => {
    let list = [];

    if (timelineStaticData) {
      const commonKeys = Object.keys(timelineStaticData);

      commonKeys?.forEach((key) => {
        const cardKeys = Object.keys(timelineStaticData[key]?.cards);

        const showIcon =
          timelineApiData?.[key]?.completed ||
          timelineApiData?.[key]?.isDeviceOrdered;
        let obj = {};

        list.push({
          ...obj,
          dot: showIcon && (
            <CheckCircleFilled
              style={{
                fontSize: "22px",
                color: "#00A73E",
                background: "rgb(238, 238, 238)",
              }}
              title="Action Completed"
            />
          ),
          position: isMobile ? "left" : timelineStaticData[key].position,
          label: !isMobile && (
            <>
              <div>{timelineStaticData[key].label}</div>
              <div className="fs12 grey">
                {timelineApiData[key]?.dates?.startDate}
                {timelineApiData[key]?.dates?.endDate &&
                  ` - ${timelineApiData[key]?.dates?.endDate}`}
              </div>
            </>
          ),
          children: cardKeys?.map((item, index) => {
            return (
              <div key={index}>
                {isMobile && index === 0 && (
                  <div className="mb1">
                    <div className="timeline-label">
                      {timelineStaticData[key].label}
                    </div>
                    <div className="timeline-sub-text">
                      {timelineApiData[key]?.dates?.startDate}
                      {timelineApiData[key]?.dates?.endDate &&
                        ` - ${timelineApiData[key]?.dates?.endDate}`}
                    </div>
                  </div>
                )}
                <Card
                  className={index !== 0 && "mt1"}
                  title={timelineStaticData[key]?.cards[item]?.title}
                  styles={{ body: { padding: "15px 20px" } }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: timelineStaticData[key]?.cards[item]?.description,
                    }}
                  />
                  {timelineApiData[key]?.cards[item]?.address && (
                    <div
                      className="bold mt05"
                      dangerouslySetInnerHTML={{
                        __html: timelineApiData[key]?.cards[item]?.address,
                      }}
                    />
                  )}
                  {!timelineApiData?.[key]?.cards[item]?.completed &&
                    timelineStaticData[key]?.cards[item]?.buttonText && (
                      <div className="text-center">
                        <Divider style={{ margin: "1rem 0" }} />
                        <Button
                          className="blue-btn"
                          onClick={() =>
                            onBtnClick(
                              clinic,
                              service,
                              timelineStaticData,
                              key,
                              item,
                              type
                            )
                          }
                        >
                          {timelineStaticData[key]?.cards[item]?.buttonText}
                        </Button>
                      </div>
                    )}
                  {loading && <Spin fullscreen size="large" />}
                </Card>
              </div>
            );
          }),
        });
      });
    }
    return list;
  };

  return (
    <div data-testid="timeline-list">
      <Timeline
        mode={isMobile ? "left" : "alternate"}
        items={getItems()}
        className="pd1"
      />
    </div>
  );
};

export default DynamicList;
