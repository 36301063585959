import { Card, Col, Row, Typography } from "antd";
import ICON from "../../../Assets/images/Dashboard/icon.png";
const { Title } = Typography;

const Overview = ({ dashboardDetails }) => {
  return (
    <Row gutter={[8, 16]}>
      {dashboardDetails?.cards?.map((item, index) => {
        return (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card
              style={{ backgroundColor: item.colour }}
              styles={{ body: { padding: "20px 15px" } }}
            >
              <Row
                gutter={[16, 8]}
                justify={"space-between"}
                className="flex-center"
              >
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <img src={ICON} alt="Patients" width="100%" />
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16} className="right">
                  <Title level={5} className="white m0">
                    {item?.label}
                  </Title>
                  <Title level={4} className="white m0">
                    {item?.value}
                  </Title>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Overview;
