import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import { providerViewPatientVialsUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { titleizeWord, getPageHeader } from "../../Common/Utils";
import { Link } from "react-router-dom";

const Vials = ({
  vialDetails,
  notify,
  service,
  token,
  defaultDoctor,
  charges,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showEditModal = () => {
    form.resetFields();
    setIsModalOpen(true);
    form.setFieldsValue({
      dateOfLastVisit:
        vialDetails?.lastVisitData?.dateOfLastVisit &&
        dayjs(vialDetails?.lastVisitData?.dateOfLastVisit, "MM/DD/YYYY"),
      price: vialDetails?.lastVisitData?.price,
    });
  };

  const cancelEdtModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientVialsUrl, {
      ...values,
      dateOfLastVisit: values?.dateOfLastVisit?.format("MM/DD/YYYY"),
      token: token,
      service: service,
      doctor: defaultDoctor,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <>
      <Card className="blue-card">
        <Row gutter={[16, 16]} justify="space-between">
          <Col xs={24} sm={16} md={18} lg={18} xl={18} className="white">
            {vialDetails?.lastVisitData?.dateOfLastVisit && (
              <div>
                <b className="mr05">Last Visited On:</b>
                {vialDetails?.lastVisitData?.dateOfLastVisit}
              </div>
            )}
            {vialDetails?.lastVisitData?.price && (
              <div className="mt05">
                <b className="mr05">Price:</b>
                {vialDetails?.lastVisitData?.price}
              </div>
            )}
            {!vialDetails?.lastVisitData?.dateOfLastVisit &&
              !vialDetails?.lastVisitData?.price && (
                <div className="bold flex-start-center">Last Visit Details</div>
              )}
          </Col>
          <Col xs={24} sm={8} md={6} lg={4} xl={3} className="flex-center">
            <Button
              block
              onClick={() => showEditModal()}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Col>
        </Row>
      </Card>

      <div className="bold blue fs18 mt2">Order History</div>
      <Divider style={{ margin: "0.25rem 0 2rem 0" }} />

      <Row gutter={[16, 16]}>
        {vialDetails?.orders?.map((item, index) => {
          return (
            <Col key={index} xs={24} sm={12} md={12} lg={8} xl={6}>
              <Card
                className="text-center box-shadow"
                actions={[
                  item?.fileUrl ? (
                    <Link
                      onClick={() => window.open(item?.fileUrl, "_blank")}
                      disabled={!item?.fileUrl}
                      className="bold"
                    >
                      <>
                        <EyeOutlined className="mr05" />
                        {BUTTONS.VIEW} Order Form
                      </>
                    </Link>
                  ) : (
                    <span className="grey">Form Not Found</span>
                  ),
                ]}
              >
                {item?.orderedOn && (
                  <div className="flex-start-center">
                    <span className="mr05">Ordered On:</span>
                    <span className="grey">{item?.orderedOn}</span>
                  </div>
                )}
                <div className="flex-start-center mt05">
                  <span className="mr05">Payment:</span>
                  <span className="grey">
                    {item?.paymentStatus
                      ? getPageHeader(item?.paymentStatus)
                      : "NA"}
                  </span>
                </div>
                <div className="flex-start-center mt05">
                  <span className="mr05">Status:</span>
                  <span className="grey">
                    {item?.status ? titleizeWord(item?.status) : "NA"}
                  </span>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Popup
        title="Last Visit Details"
        open={isModalOpen}
        cancel={cancelEdtModal}
        footer={null}
        width={500}
      >
        <Form
          initialValues={{}}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Form.Item
            label="Last Visit Date"
            name="dateOfLastVisit"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              disabledDate={(current) =>
                current && current.isAfter(moment().endOf("day"))
              }
              inputReadOnly
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Please Select" options={charges} />
          </Form.Item>

          <Form.Item className="mt2">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Form>
      </Popup>
    </>
  );
};

export default Vials;
