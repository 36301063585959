import { Button, Col, Flex, Modal, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../Common/Constants";
import { providerVialsUrl } from "../../Common/Endpoints";
import { getPageHeader, getRemoteConfigData } from "../../Common/Utils";
import useNotify from "../../Common/Notify";
import { useSelector } from "react-redux";
import { EyeOutlined, RollbackOutlined } from "@ant-design/icons";

const Vials = () => {
  const { showLoader, setShowLoader } = useApiLoader();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [vials, setVials] = useState();
  const [status, setStatus] = useState();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);
  const { notify } = useNotify();
  const { confirm } = Modal;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Last Visit",
      dataIndex: "dateOfLastVisit",
      render: (_, record) =>
        record?.dateOfLastVisit ? record?.dateOfLastVisit : "NA",
    },
    {
      title: "Last Order",
      dataIndex: "dateOfLastOrder",
      render: (_, record) =>
        record?.dateOfLastOrder ? record?.dateOfLastOrder : "NA",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (_, record) => (record?.reason ? record?.reason : "NA"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Payment",
      dataIndex: "paymentStatus",
      render: (_, record) => {
        return record?.paymentStatus
          ? getPageHeader(record?.paymentStatus)
          : "NA";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <>
          <Button
            size="small"
            className="mr05"
            onClick={() => window.open(record?.fileUrl, "_blank")}
            disabled={!record?.fileUrl}
          >
            <>
              <EyeOutlined className="mr05" />
              {BUTTONS.VIEW} Order Form
            </>
          </Button>

          {record.status !== "COMPLETED" && (
            <>
              {record?.fileUrl && record?.paymentStatus === "paid" && (
                <Button
                  size="small"
                  className="blue-btn mr05"
                  onClick={() => showRefundConfirm(record)}
                >
                  <>
                    <RollbackOutlined className="mr05" />
                    Refund
                  </>
                </Button>
              )}
              <Select
                size="small"
                style={{ minWidth: "120px" }}
                value={record?.status}
                placeholder="Select Status"
                options={
                  record.status === "HOLD"
                    ? staticDropdownData?.common?.vialStatus?.filter((item) =>
                        record?.questionnaireStatus
                          ? item?.value === "PENDING"
                          : item?.value === "RESOLVED"
                      )
                    : record.status === "PENDING"
                    ? staticDropdownData?.common?.vialStatus?.filter(
                        (item) => item?.value === "COMPLETED"
                      )
                    : staticDropdownData?.common?.vialStatus
                }
                onChange={(selectedValue) =>
                  onStatusActionChange(selectedValue, record)
                }
              />
            </>
          )}
        </>
      ),
    },
  ];

  const showRefundConfirm = (record) => {
    confirm({
      title: "Are you sure?",
      content:
        "If you click 'Yes', the order will be cancelled, and the payment will be refunded.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        onStatusActionChange("START", record, true);
      },
      onCancel() {},
    });
  };

  const onStatusActionChange = async (value, record, refundStatus) => {
    await postApis(providerVialsUrl, {
      oldStatus: status,
      newStatus: value,
      dateOfLastVisit: record.dateOfLastVisit,
      name: record.name,
      doctor: defaultDoctor,
      refundStatus,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (!staticDropdownData) {
      getRemoteConfigData("DROPDOWNS").then((result) => {
        setStaticDropdownData(result);
        setStatus(result?.common?.vialStatus?.[0]?.value);
      });
    }
    if (status) {
      getVails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const getVails = async () => {
    setShowLoader(true);

    await getApis(providerVialsUrl, { status: status, doctor: defaultDoctor })
      .then((response) => {
        setShowLoader(false);
        setVials(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  return (
    !showLoader && (
      <div data-testid="claims">
        <Row justify={"space-between"} gutter={[16, 8]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="flex-start-center"
          >
            {defaultDoctor && (
              <Flex wrap="wrap" justify={"start"}>
                <div className="bold mr05">Provider: </div>
                <div>{defaultDoctor}</div>
              </Flex>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Select
              size="large"
              value={status}
              placeholder="Select Status"
              options={staticDropdownData?.common?.vialStatus?.filter(
                (item) => item?.value !== "RESOLVED"
              )}
              className="full-width"
              onChange={handleStatusChange}
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) => new Date(record?.created).getTime()}
              columns={
                status === "HOLD"
                  ? columns?.filter((i) => i.dataIndex !== "status")
                  : status === "COMPLETED"
                  ? columns?.filter((i) => i.dataIndex !== "reason")
                  : columns?.filter(
                      (i) => !["status", "reason"].includes(i.dataIndex)
                    )
              }
              dataSource={vials}
              scroll={{ x: "100%" }}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default Vials;
