import { Carousel, Col, Flex, Row, Typography } from "antd";
import BG1 from "../../Assets/images/ForPatients/bg1.png";
import BG2 from "../../Assets/images/ForPatients/bg2.png";
import { ROUTES } from "../Common/Constants";
import CARE from "../../Assets/images/ForPatients/care.png";
import VITALS from "../../Assets/images/ForPatients/vitals.png";
import REGISTER from "../../Assets/images/ForPatients/registration.png";
import PROFILE from "../../Assets/images/ForPatients/profile.png";
import TIMELINE from "../../Assets/images/ForPatients/timelines.png";
import { useSelector } from "react-redux";
import ExperienceNow from "../Common/ExperienceNow";
import { FOR_PATEINTS } from "../Common/AppContent";
import HeroSection from "../Common/Layouts/HeroSection";

const { Title } = Typography;

const ForPatients = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  const renderCarousel = () => {
    return (
      <Carousel autoplay arrows={false} slidesToShow={1} dots={false}>
        <div>
          <img src={BG1} alt="Banner 1" className="home-banner-image" />
        </div>
        <div>
          <img src={BG2} alt="Banner 2" className="home-banner-image" />
        </div>
      </Carousel>
    );
  };

  const mobileBasedVitalsText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="blue mr075">
              {FOR_PATEINTS.FEATURES.TWO.BLUE_HEADER}
            </span>
            <span className="orange">
              {FOR_PATEINTS.FEATURES.TWO.ORANGE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          {FOR_PATEINTS.FEATURES.TWO.DESCRIPTION}
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  const mobileBasedHealthText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="blue mr075">
              {FOR_PATEINTS.FEATURES.FOUR.BLUE_HEADER}
            </span>
            <span className="orange">
              {FOR_PATEINTS.FEATURES.FOUR.ORANGE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          {FOR_PATEINTS.FEATURES.FOUR.DESCRIPTION}
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div data-testid="patient-home">
      <HeroSection
        carousel={renderCarousel()}
        title={
          isMobile || isTablet ? FOR_PATEINTS.MOBILE_TITLE : FOR_PATEINTS.TITLE
        }
        subTitle={FOR_PATEINTS.SUB_TITLE}
        description={FOR_PATEINTS.DESCRIPTION}
        titleColSpan={18}
        descColSpan={22}
      />

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <img src={CARE} alt="Care" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {FOR_PATEINTS.FEATURES.ONE.ORANGE_HEADER}
              </span>
              <span className="blue">
                {FOR_PATEINTS.FEATURES.ONE.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div
            className="paragraph mt2 fs16"
            dangerouslySetInnerHTML={{
              __html: FOR_PATEINTS.FEATURES.ONE.DESCRIPTION,
            }}
          />

          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      {isMobile || isTablet ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <img src={VITALS} alt="Vitals" width="100%" />
          </Col>
          {mobileBasedVitalsText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {mobileBasedVitalsText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={VITALS} alt="Vitals" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={REGISTER} alt="Registration" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {FOR_PATEINTS.FEATURES.THREE.ORANGE_HEADER}
              </span>
              <span className="blue">
                {FOR_PATEINTS.FEATURES.THREE.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {FOR_PATEINTS.FEATURES.THREE.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      {isMobile || isTablet ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={PROFILE} alt="Profile" width="100%" />
          </Col>
          {mobileBasedHealthText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {mobileBasedHealthText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={PROFILE} alt="Profile" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={TIMELINE} alt="Timeline" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {FOR_PATEINTS.FEATURES.FIVE.ORANGE_HEADER}
              </span>
              <span className="blue">
                {FOR_PATEINTS.FEATURES.FIVE.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {FOR_PATEINTS.FEATURES.FIVE.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForPatients;