import { Button, Col, Form, Input, Row, Select } from "antd";
import { MaskedInput } from "antd-mask-input";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { validatePhoneLength } from "../../Common/Utils";
const { Option } = Select;

const AddProvider = ({
  form,
  isModalOpen,
  handleCancel,
  loading,
  onFinish,
  onFinishFailed,
  staticDropdownData,
  clinic,
}) => {
  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+1">+1</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Popup
      title="Add Provider"
      open={isModalOpen}
      cancel={handleCancel}
      footer={null}
      width={700}
      style={{ top: "25px" }}
    >
      <Form
        initialValues={{ code: "+1" }}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.FIRST_NAME}
              name="firstName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.FIRST_NAME} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.LAST_NAME}
              name="lastName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.LAST_NAME} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.PHONE}
              name="phoneNumber"
              size="large"
              rules={[
                {
                  required: true,
                  validator: validatePhoneLength,
                },
              ]}
            >
              <MaskedInput
                addonBefore={prefixSelector}
                mask={"(000)000-0000"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.EMAIL}
              name="email"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.PASSWORD}
              name="password"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  min: 8,
                  message: VALIDATIONS.DEFAULT.PASSWORD_MIN_LENGTH,
                },
              ]}
            >
              <Input.Password placeholder={PLACEHOLDER.PASSWORD} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.GENDER}
              name="gender"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Please Select"
                options={staticDropdownData?.common?.genders}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.PROGRAMS}
              name="programs"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder="Please Select"
                options={
                  staticDropdownData?.providers?.specialisations?.[clinic]
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.ROLE}
              name="role"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Please Select"
                options={staticDropdownData?.providers?.roles}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex-center">
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button
              type="primary"
              className="mt05"
              htmlType="submit"
              block
              disabled={loading}
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default AddProvider;
