import { Button, Table } from "antd";

const SleepDevicesReturn = ({ devices, showReturnConfirm }) => {
  const columns = [
    {
      title: "Patient",
      dataIndex: "name",
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Device",
      dataIndex: "deviceId",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentStatus",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Button size="small" onClick={() => showReturnConfirm(record)}>
            Return
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <div className="grey-fill pd1">
        <div className="bold">Instructions:</div>
        <ul>
          <li>
            The table displays all the appointments based on return date for the
            selected day(Filter By Date).
          </li>
          <li>
            Please use the Return button to collect the device from the patient.
          </li>
        </ul>
      </div>
      <Table
        className="mt1"
        bordered
        rowKey={(record) => new Date(record?.created).getTime()}
        columns={columns}
        dataSource={devices}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default SleepDevicesReturn;
