import { Card, Col, Row } from "antd";
import PatientVitals from "../../Common/PatientVitals";
import BestPefValues from "./BestPefValues";

const Respiratory = ({ graphDetails, bestValueDetails }) => {
  return (
    <div data-testid="general-data">
     
      <Row gutter={[16, 16]} className="mt1" justify="center">
        {graphDetails?.map((item, index) => {
          return (
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
              <Card>
                <PatientVitals graphData={item} />
              </Card>
            </Col>
          );
        })}
      </Row>

      {bestValueDetails && (
        <Row justify="center">
          <Col span={24}>
            <BestPefValues bestValueDetails={bestValueDetails} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Respiratory;
