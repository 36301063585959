import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: !!localStorage.getItem("authToken"),
  otpSent: false,
  allStepDetails: {},
  phoneNumber: "",
  notification: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.isLoggedIn = false;
      state.otpSent = false;
      state.allStepDetails = {};
      state.phoneNumber = "";
      state.notification = null;
    },
    setLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
    },
    setOtpSent: (state, action) => {
      state.otpSent = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setAllStepDetails: (state, action) => {
      state.allStepDetails = { ...state.allStepDetails, ...action.payload };
      state.phoneNumber = action.payload.phoneNumber;
    },
  },
});

export const {
  setLoggedIn,
  setLoggedOut,
  setOtpSent,
  setAllStepDetails,
  setPhoneNumber,
  resetAuthState,
  setNotification,
} = authSlice.actions;
export default authSlice.reducer;
