import { Progress } from "antd";

const ImageProgressBar = ({ percent, size, imageSrc, status, strokeColor }) => (
  <div
    style={{
      position: "relative",
      width: size,
      height: size,
      display: "inline-block",
    }}
  >
    <Progress
      type="circle"
      percent={percent}
      size={size}
      status={status}
      strokeColor={strokeColor}
      format={() => ""}
      strokeWidth={10}
    />
    <img
      src={imageSrc}
      alt="progress"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: size / 2,
        height: size / 2,
        pointerEvents: "none",
      }}
    />
  </div>
);

export default ImageProgressBar;
