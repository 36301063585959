import { Col, Flex, Row, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Timer from "../../Common/Timer";
import { setLoggedIn, setOtpSent } from "../../../Reducers/authSlice";
import { logAnalyticsEvent, setAccessTokens } from "../../Common/Utils";
import { useEffect, useState } from "react";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Common/Constants";
import { signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";
import useNotify from "../../Common/Notify";
import { patientAccessTokenUrl } from "../../Common/Endpoints";
import { postApis } from "../../Common/Apis";
import { otpInput } from "../../Common/FormFields";
import CustomForm from "../../Common/CustomForm";

const { Title } = Typography;

const PatientVerifyOtp = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRedux = useSelector((state) => state.auth);
  const { notify } = useNotify();

  useEffect(() => {
    if (!window.confirmationResult) {
      navigate(ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendOtp = () => {
    signInWithPhoneNumber(
      auth,
      authRedux?.phoneNumber,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        dispatch(setOtpSent(true));
      })
      .catch((err) => {
        notify(
          NOTIFICATIONS.ERROR.KEY,
          err?.message?.includes("too-many-requests")
            ? "Too many attempts. Try Later."
            : err?.message
        );
      });
  };

  const getAccessToken = async (data) => {
    await postApis(patientAccessTokenUrl, {
      uid: data?.uid,
    })
      .then((response) => {
        dispatch(setLoggedIn());
        setAccessTokens(response?.data);
        navigate(ROUTES.PATIENT.PROFILE.PATH);
        logAnalyticsEvent("login", {
          userType: USER_TYPES.PATIENT,
          loginType: "Phone",
          data: data?.phoneNumber,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    window.confirmationResult
      .confirm(values?.otp)
      .then((response) => {
        getAccessToken(response?.user);
      })
      .catch((err) => {
        setLoading(false);
        notify(
          NOTIFICATIONS.ERROR.KEY,
          err?.message?.includes("invalid-verification-code")
            ? "Invalid Passcode"
            : err?.message
        );
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div>
      <Title>Login</Title>
      <div className="grey">Verification code has been sent</div>
      <div className="grey mt05">Please enter your One Time Passcode below</div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[otpInput]}
        initialValues={{}}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.VERIFY}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      />

      <Row justify={"start"} className="mt1">
        {authRedux?.otpSent ? (
          <Col span={24}>
            <Flex wrap="wrap" gap="small" className="align-center">
              <div className="grey">Resend code in</div>
              <Timer />
            </Flex>
          </Col>
        ) : (
          <Col span={24}>
            <Link className="left" onClick={resendOtp}>
              Resend Code
            </Link>
          </Col>
        )}
      </Row>

      <Flex justify={"space-between"} className="mt2">
        <Link to={ROUTES.PATIENT.LOGIN.PATHS.EMAIL}>Email Login</Link>
        <Link to={ROUTES.PATIENT.REGISTER.PATH}>Sign Up</Link>
      </Flex>
    </div>
  );
};
export default PatientVerifyOtp;
