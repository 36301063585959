import { Col, Flex, Row, Typography } from "antd";
import { RESPIRATORY_CARE, WHAT_WE_DO } from "../Common/AppContent";
import { ROUTES } from "../Common/Constants";
import ExperienceNow from "../Common/ExperienceNow";
import MAIN from "../../Assets/images/Respiratory/main.png";
import CARE from "../../Assets/images/ForPatients/profile.png";
import ASTHMA from "../../Assets/images/Respiratory/asthma.png";
import COPD from "../../Assets/images/Respiratory/copd.png";
import ALLERGY from "../../Assets/images/Respiratory/allergy.png";
import ImageCard from "../Common/ImageCard";
import { useSelector } from "react-redux";
import WwdCards from "./WwdCards";

const { Title } = Typography;

const RespiratoryCare = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  const respiratoryCareText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="orange mr075">
              {RESPIRATORY_CARE.FEATURES.ONE.ORANGE_HEADER}
            </span>
            <span className="blue">
              {RESPIRATORY_CARE.FEATURES.ONE.BLUE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          {RESPIRATORY_CARE.FEATURES.ONE.DESCRIPTION}
        </div>
      </Col>
    );
  };

  const copdText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="orange mr075">
              {RESPIRATORY_CARE.FEATURES.THREE.ORANGE_HEADER}
            </span>
            <span className="blue">
              {RESPIRATORY_CARE.FEATURES.THREE.BLUE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          {RESPIRATORY_CARE.FEATURES.THREE.DESCRIPTION}
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div data-testid="respiratory">
      <Row justify={"space-evenly"} gutter={[0, 16]} className="respiratory-bg">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <img src={MAIN} alt="main" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title level={1} className="white flex-start-center">
            {RESPIRATORY_CARE.TITLE}
          </Title>
          <div className="white fs20 mt2 ln-hgt105">
            {RESPIRATORY_CARE.DESCRIPTION}
          </div>
          <Row className="mt3">
            <Col xs={24} sm={12} md={10} lg={10} xl={10}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pd2 mb1">
        <Row>
          <Col span={24} className="mt-home">
            <Title level={2} className="text-center">
              <div className="orange">
                {WHAT_WE_DO.TRANSFORMS.ORANGE_HEADER}
              </div>
              <div className="blue fs32">
                {WHAT_WE_DO.TRANSFORMS.BLUE_HEADER}
              </div>
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3 mb1">
          {WHAT_WE_DO.TRANSFORMS.CARDS?.map((item, index) => {
            return (
              <WwdCards
                isMobile={isMobile}
                isTablet={isTablet}
                key={index}
                index={index}
                minHeight="350px"
                imgSrc={item?.IMG_SRC}
                title={item?.TITLE}
                description={item?.DESCRIPTION}
                borderColor={item?.BORDER}
              />
            );
          })}
        </Row>
      </div>
      {isMobile ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="grey-fill flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10} className="mt2">
            <img src={CARE} alt="Care" width="100%" />
          </Col>
          {respiratoryCareText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="grey-fill flex-align-center pd3"
        >
          {respiratoryCareText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={CARE} alt="Care" width="100%" />
          </Col>
        </Row>
      )}
      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={ASTHMA} alt="Asthma" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {RESPIRATORY_CARE.FEATURES.TWO.BLUE_HEADER}
              </span>
              <span className="blue">
                {RESPIRATORY_CARE.FEATURES.TWO.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {RESPIRATORY_CARE.FEATURES.TWO.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      {isMobile ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="grey-fill flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={COPD} alt="Copd" width="100%" />
          </Col>
          {copdText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="grey-fill flex-align-center pd3"
        >
          {copdText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={COPD} alt="Copd" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={ALLERGY} alt="Allergy" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {RESPIRATORY_CARE.FEATURES.FOUR.BLUE_HEADER}
              </span>
              <span className="blue">
                {RESPIRATORY_CARE.FEATURES.FOUR.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {RESPIRATORY_CARE.FEATURES.FOUR.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <div className="blue">
                {RESPIRATORY_CARE.RPM_BENEFITS.BLUE_HEADER}
              </div>
              <div className="orange">
                {RESPIRATORY_CARE.RPM_BENEFITS.ORANGE_HEADER}
              </div>
            </Title>
          </div>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
            {RESPIRATORY_CARE.RPM_BENEFITS.CARDS?.map((item, index) => {
              return (
                <ImageCard
                  key={index}
                  minHeight="460px"
                  imgSrc={item?.IMG_SRC}
                  title={item?.TITLE}
                  description={item?.DESCRIPTION}
                  textClass={"fs16"}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RespiratoryCare;
