import { Button, Col, Flex, Row, Typography } from "antd";
import BANNER from "../../Assets/images/ForProviders/bg.png";
import { BUTTONS, ROUTES } from "../Common/Constants";
import DASHBOARD from "../../Assets/images/ForProviders/dashboard.png";
import PROFILE from "../../Assets/images/ForProviders/profile.png";
import LISTS from "../../Assets/images/ForProviders/lists.png";
import OUTCOMES from "../../Assets/images/ForProviders/outcomes.png";
import DIRECT from "../../Assets/images/ForProviders/direct.png";
import INDIRECT from "../../Assets/images/ForProviders/indirect.png";
import SW_LOGO from "../../Assets/images/CognitiveJourney/swLogo.svg";
import { useSelector } from "react-redux";
import ExperienceNow from "../Common/ExperienceNow";
import { COMMON, FOR_PROVIDERS } from "../Common/AppContent";
import HeroSection from "../Common/Layouts/HeroSection";
const { Title } = Typography;

const ForProviders = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  const mobileBasedIndirectBenefitsText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="orange mr075">
              {FOR_PROVIDERS.INDIRECT_FINANCIAL_BENEFITS.ORANGE_HEADER}
            </span>
            <span className="blue">
              {FOR_PROVIDERS.INDIRECT_FINANCIAL_BENEFITS.BLUE_HEADER}
            </span>
          </Flex>
        </Title>
        <div
          dangerouslySetInnerHTML={{
            __html: FOR_PROVIDERS.INDIRECT_FINANCIAL_BENEFITS.ONE,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: FOR_PROVIDERS.INDIRECT_FINANCIAL_BENEFITS.TWO,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: FOR_PROVIDERS.INDIRECT_FINANCIAL_BENEFITS.THREE,
          }}
        />
      </Col>
    );
  };

  const mobileBasedMonitoringText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="blue mr075">
              {FOR_PROVIDERS.FEATURES.ONE.BLUE_HEADER}
            </span>
            <span className="orange">
              {FOR_PROVIDERS.FEATURES.ONE.ORANGE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph fs16 mt2">
          {FOR_PROVIDERS.FEATURES.ONE.DESCRIPTION}
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PUBLIC.CONTACT_US.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  const mobileBasedOutcomesText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="blue mr075">
              {FOR_PROVIDERS.FEATURES.THREE.BLUE_HEADER}
            </span>
            <span className="orange">
              {FOR_PROVIDERS.FEATURES.THREE.ORANGE_HEADER}
            </span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          {FOR_PROVIDERS.FEATURES.THREE.DESCRIPTION}
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PUBLIC.CONTACT_US.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div data-testid="provider-home">
      <HeroSection
        titleColSpan={isTablet ? 22 : null}
        imgSrc={BANNER}
        title={
          isMobile || isTablet
            ? FOR_PROVIDERS.MOBILE_TITLE
            : FOR_PROVIDERS.TITLE
        }
        subTitle={FOR_PROVIDERS.SUB_TITLE}
        description={FOR_PROVIDERS.DESCRIPTION}
        descColSpan={16}
        redirectPath={ROUTES.PUBLIC.CONTACT_US.PATH}
      />

      <Row justify={"center"} className="text-center pd2">
        <Col span={24}>
          <Title className="fs28 orange">
            {FOR_PROVIDERS.CAPTION.ORANGE_HEADER}
          </Title>
          <Title className="fs28 blue">
            {FOR_PROVIDERS.CAPTION.BLUE_HEADER}
          </Title>

          <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <div className="ln-hgt fs16 mt1">
                {FOR_PROVIDERS.CAPTION.DESCRIPTION}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={11}>
          <img src={DIRECT} alt="direct" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="blue mr075">
                {FOR_PROVIDERS.DIRECT_FINANCIAL_BENEFITS.BLUE_HEADER}
              </span>
              <span className="orange">
                {FOR_PROVIDERS.DIRECT_FINANCIAL_BENEFITS.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: FOR_PROVIDERS.DIRECT_FINANCIAL_BENEFITS.ONE,
            }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: FOR_PROVIDERS.DIRECT_FINANCIAL_BENEFITS.TWO,
            }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: FOR_PROVIDERS.DIRECT_FINANCIAL_BENEFITS.THREE,
            }}
          />
        </Col>
      </Row>

      {isMobile || isTablet ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={11}>
            <img src={INDIRECT} alt="direct" width="100%" />
          </Col>
          {mobileBasedIndirectBenefitsText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {mobileBasedIndirectBenefitsText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={11}>
            <img src={INDIRECT} alt="direct" width="100%" />
          </Col>
        </Row>
      )}

      {isMobile || isTablet ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={DASHBOARD} alt="Dashboard" width="100%" />
          </Col>
          {mobileBasedMonitoringText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {mobileBasedMonitoringText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={DASHBOARD} alt="Dashboard" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={PROFILE} alt="Profile" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {FOR_PROVIDERS.FEATURES.TWO.ORANGE_HEADER}
              </span>
              <span className="blue">
                {FOR_PROVIDERS.FEATURES.TWO.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph fs16 mt2">
            {FOR_PROVIDERS.FEATURES.TWO.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PUBLIC.CONTACT_US.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      {isMobile || isTablet ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={OUTCOMES} alt="Outcomes" width="100%" />
          </Col>
          {mobileBasedOutcomesText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {mobileBasedOutcomesText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={OUTCOMES} alt="Outcomes" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={LISTS} alt="Lists" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr075">
                {FOR_PROVIDERS.FEATURES.FOUR.ORANGE_HEADER}
              </span>
              <span className="blue">
                {FOR_PROVIDERS.FEATURES.FOUR.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {FOR_PROVIDERS.FEATURES.FOUR.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PUBLIC.CONTACT_US.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[0, 16]} justify={"space-evenly"} className="pd3 grey-fill">
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <div className="sw-blue flex-start-center mt1">
            <img alt="logo" src={SW_LOGO} width="50" />
            <span className="bold fs32 ml05">{COMMON.SAIGEWARE.VISION}</span>
          </div>
          <div className="paragraph fs16 mt105">
            {COMMON.SAIGEWARE.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
              <Button
                type="primary"
                size="large"
                href={ROUTES.SAIGEWARE}
                target={"_blank"}
                className="sw-blue-btn-lg"
              >
                {BUTTONS.LEARN_MORE}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={4} xl={4}>
          <img alt="logo" src={SW_LOGO} width="100%" />
        </Col>
      </Row>
    </div>
  );
};
export default ForProviders;
