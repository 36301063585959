import { Col, Flex, Row, Typography } from "antd";
import TEAM from "../../Assets/images/CognitiveJourney/team.png";
import WELLNESS from "../../Assets/images/CognitiveJourney/wellness.png";
import HEARING from "../../Assets/images/CognitiveJourney/hearing.png";
import RESEARCH from "../../Assets/images/CognitiveJourney/research.png";
import HEALTH from "../../Assets/images/CognitiveJourney/health.png";
import "./style.css";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../Common/Utils";
import { useSelector } from "react-redux";
import { ROUTES } from "../Common/Constants";
import ExperienceNow from "../Common/ExperienceNow";
import { COGNITIVE_JOURNEY, COMMON } from "../Common/AppContent";
import ColorWidget from "../Common/ColorWidget";
import ImageCard from "../Common/ImageCard";
const { Title } = Typography;

const CognitiveJourney = () => {
  const [videos, setVideos] = useState();
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  useEffect(() => {
    getRemoteConfigData("VIDEOS").then((result) =>
      setVideos(result?.cognition)
    );
  }, []);

  const cognitiveResearchMobileBasedText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <ol
          className="fs16"
          style={{ listStyleType: "none", paddingInlineStart: "20px" }}
        >
          <li>
            <div
              className="paragraph108 mt105"
              dangerouslySetInnerHTML={{
                __html: COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_4,
              }}
            />
          </li>
          <li>
            <div
              className="paragraph108 mt105"
              dangerouslySetInnerHTML={{
                __html: COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_5,
              }}
            />
          </li>
          <li>
            <div
              className="paragraph108 mt105"
              dangerouslySetInnerHTML={{
                __html: COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_6,
              }}
            />
          </li>
        </ol>
      </Col>
    );
  };

  return (
    <div data-testid="cognitive-journey">
      {isMobile || isTablet ? (
        <Row className="blue-banner-1 flex-center">
          <Col span={24}>
            <Row justify={"space-evenly"} gutter={[0, 32]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Title level={2} className="white flex-start-center">
                  {COGNITIVE_JOURNEY.TITLE}
                </Title>
                <div className="white fs20 mt2">
                  {COGNITIVE_JOURNEY.DESCRIPTION}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={11}>
                <img src={TEAM} alt="Team" width="100%" />
              </Col>
            </Row>
            <Row className="mt2 mb1 flex-center">
              <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row
          justify={"space-evenly"}
          gutter={[0, 16]}
          className="blue-banner-1 flex-center"
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Title level={1} className="white flex-start-center">
              {COGNITIVE_JOURNEY.TITLE}
            </Title>
            <div className="white fs20 mt2">
              {COGNITIVE_JOURNEY.DESCRIPTION}
            </div>
            <Row className="mt3">
              <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={11}>
            <img src={TEAM} alt="Team" width="100%" />
          </Col>
        </Row>
      )}

      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col xs={24} sm={24} md={24} lg={10} xl={9}>
          <img src={WELLNESS} alt="Wellness" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-center">
              <span className="orange mr075">
                {COGNITIVE_JOURNEY.WELLNESS_MATTERS.ORANGE_HEADER}
              </span>
              <span className="blue">
                {COGNITIVE_JOURNEY.WELLNESS_MATTERS.BLUE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph108 mt2 fs16">
            {COGNITIVE_JOURNEY.WELLNESS_MATTERS.DESCRIPTION}
          </div>
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="pd3">
        <Col span={24} className="text-center">
          <Title level={2}>
            <Flex wrap="wrap" className="flex-center">
              <span className="blue mr075">
                {COGNITIVE_JOURNEY.BENEFITS.BLUE_HEADER}
              </span>
              <span className="orange">
                {COGNITIVE_JOURNEY.BENEFITS.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt2">
            {COGNITIVE_JOURNEY.BENEFITS.CARDS?.map((item, index) => {
              return (
                <ColorWidget
                  key={index}
                  colSpan={7}
                  imgWidth={item?.IMG_WIDTH}
                  minHeight={"400px"}
                  imgSrc={item?.IMG_SRC}
                  title={item?.TITLE}
                  description={item?.DESCRIPTION}
                  textClass={item?.TEXT_CLASS}
                  widgetClass={item?.WIDGET_CLASS}
                />
              );
            })}
          </Row>
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col span={24} className="text-center">
          <Title level={2}>
            <Flex wrap="wrap" className="flex-center">
              <span className="blue mr075">
                {COGNITIVE_JOURNEY.RELATIONSHIP.BLUE_HEADER}
              </span>
              <span className="orange">
                {COGNITIVE_JOURNEY.RELATIONSHIP.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <img src={RESEARCH} alt="Wellness" width="100%" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Title className="fs28">
                <Flex wrap="wrap">
                  <span className="orange mr075">
                    {
                      COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH
                        .ORANGE_HEADER
                    }
                  </span>
                  <span className="blue">
                    {
                      COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH
                        .BLUE_HEADER
                    }
                  </span>
                </Flex>
              </Title>
              <div className="paragraph108 mt2 fs16">
                {COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.DESCRIPTION}
              </div>
              <ol
                className="fs16"
                style={{ listStyleType: "none", paddingInlineStart: "20px" }}
              >
                <li>
                  <div
                    className="paragraph108"
                    dangerouslySetInnerHTML={{
                      __html:
                        COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_1,
                    }}
                  />
                </li>
                <li>
                  <div
                    className="paragraph108 mt105"
                    dangerouslySetInnerHTML={{
                      __html:
                        COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_2,
                    }}
                  />
                </li>
                <li>
                  <div
                    className="paragraph108 mt105"
                    dangerouslySetInnerHTML={{
                      __html:
                        COGNITIVE_JOURNEY.RELATIONSHIP.COGNITIVE_RESEARCH.LI_3,
                    }}
                  />
                </li>
              </ol>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {isMobile || isTablet ? (
            <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <img src={HEALTH} alt="Wellness" width="100%" />
              </Col>
              {cognitiveResearchMobileBasedText()}
            </Row>
          ) : (
            <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
              {cognitiveResearchMobileBasedText()}
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <img src={HEALTH} alt="Wellness" width="100%" />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <span className="blue mr075">
                {COGNITIVE_JOURNEY.CLINICAL_STUDIES.BLUE_HEADER}
              </span>
              <span className="orange">
                {COGNITIVE_JOURNEY.CLINICAL_STUDIES.ORANGE_HEADER}
              </span>
            </Title>
          </div>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
            {COGNITIVE_JOURNEY.CLINICAL_STUDIES.CARDS?.map((item, index) => {
              return (
                <ImageCard
                  key={index}
                  minHeight="420px"
                  imgSrc={item?.IMG_SRC}
                  title={item?.TITLE}
                  description={item?.DESCRIPTION}
                  path={item?.PATH}
                  textClass={item?.TEXT_CLASS}
                />
              );
            })}
          </Row>
        </Col>
      </Row>

      <Row justify={"space-evenly"} gutter={[0, 16]} className="blue-banner-2">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Title level={2} className="yellow flex-start-center">
            {COMMON.APP_NAME}
          </Title>
          <Title level={2} className="white flex-start-center">
            {COGNITIVE_JOURNEY.HEALTH_PARTNER.TITLE}
          </Title>
          <div className="paragraph108 mt105">
            {COGNITIVE_JOURNEY.HEALTH_PARTNER.DESCRIPTION}
          </div>
          <Row>
            <Col xs={24} sm={12} md={10} lg={10} xl={10} className="mt2">
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={HEARING} alt="Hearing" width="100%" />
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <span className="blue mr075">
                {COGNITIVE_JOURNEY.VIDEOS.BLUE_HEADER}
              </span>
              <br />
              <span className="orange">
                {COGNITIVE_JOURNEY.VIDEOS.ORANGE_HEADER}
              </span>
            </Title>
          </div>
          <Row gutter={[32, 16]} justify={"center"} className="mt3">
            {videos?.map((item, index) => {
              return (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
                  <ImageCard
                    minHeight="300px"
                    linkedCard={true}
                    id={item?.id}
                    imgSrc={item?.thumbnails?.withIcon}
                    title={<div className="bold fs16">{item?.title}</div>}
                    textClass={"fs16"}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default CognitiveJourney;
