import Popup from "../Popup";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useSelector } from "react-redux";
import { PDF_WORKER, USER_TYPES } from "../Constants";
import { useEffect, useState } from "react";
import { getRemoteConfigData } from "../Utils";

const TermsOfUseModal = ({ isTermsModalOpen, handleTermsModalCancel }) => {
  const userType = useSelector((state) => state?.app?.userType);
  const [pdfUrl, setPdfUrl] = useState();

  useEffect(() => {
    getRemoteConfigData("DOCUMENTS").then((res) =>
      setPdfUrl(
        userType === USER_TYPES.PROVIDER
          ? res?.provider?.termsOfUse
          : res?.patient?.termsOfUse
      )
    );
  }, [userType]);

  return (
    <Popup
      title="Terms of Use"
      open={isTermsModalOpen}
      cancel={handleTermsModalCancel}
      footer={null}
      width="100%"
      height="100%"
      bodyStyle={{ maxHeight: "85vh", overflow: "auto" }}
      style={{ top: "8px" }}
    >
      {pdfUrl && (
        <Worker workerUrl={PDF_WORKER.URL}>
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      )}
    </Popup>
  );
};

export default TermsOfUseModal;
