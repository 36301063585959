import { Col, Collapse, Row } from "antd";
import { useEffect, useState } from "react";
import BasicDetails from "./BasicDetails";
import LabDetails from "./LabDetails";
import VitalDetails from "./VitalDetails";
import DiagnosisDetails from "./DiagnosisDetails";
import MedicineDetails from "./MedicineDetails";
import { getRemoteConfigData } from "../../../Common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import BaselineDetails from "./BaselineDetails";

const EmrData = ({ emrDetails, token, getEmrData }) => {
  const [staticDropdownData, setStaticDropdownData] = useState();
  const selectedKey = useSelector((state) => state?.patient?.emrSelectedKey);
  const dispatch = useDispatch();

  useEffect(() => {
    getRemoteConfigData("EPIC_DATA").then((res) => setStaticDropdownData(res));
  }, []);

  const items = [
    {
      key: "1",
      label: "Readmission Risk",
      children: <BaselineDetails emrDetails={emrDetails} />,
    },
    {
      key: "2",
      label: "Basic Details",
      children: (
        <BasicDetails
          token={token}
          getEmrData={getEmrData}
          emrDetails={emrDetails}
          staticDropdownData={staticDropdownData}
        />
      ),
    },
    {
      key: "3",
      label: "Lab Details",
      children: (
        <LabDetails
          emrDetails={emrDetails}
          token={token}
          getEmrData={getEmrData}
        />
      ),
    },
    {
      key: "4",
      label: "Vital Details",
      children: (
        <VitalDetails
          emrDetails={emrDetails}
          token={token}
          getEmrData={getEmrData}
        />
      ),
    },
    {
      key: "5",
      label: "Diagnosis Details",
      children: (
        <DiagnosisDetails
          emrDetails={emrDetails}
          token={token}
          getEmrData={getEmrData}
          staticDropdownData={staticDropdownData}
        />
      ),
    },
    {
      key: "6",
      label: "Medicine Details",
      children: (
        <MedicineDetails
          token={token}
          getEmrData={getEmrData}
          emrDetails={emrDetails}
        />
      ),
    },
  ];

  const handleOnClick = (e) => {
    dispatch(setEmrSelectedKey(e.key));
  };

  return (
    <Row className="mt1">
      <Col span={24}>
        <Collapse
          expandIconPosition="end"
          items={items}
          defaultActiveKey={[selectedKey]}
          onClick={handleOnClick}
          className="fs18"
        />
      </Col>
    </Row>
  );
};

export default EmrData;
