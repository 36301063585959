import { Col, Divider, Flex, Row, Tag } from "antd";
import { Typography } from "antd";
import COVER_PHOTO from "../../../Assets/images/coverPhoto.png";
import "./style.css";
import { useEffect } from "react";
import { displayOnlyDate, displayUserName } from "../../Common/Utils";
import { FORM_FIELDS } from "../../Common/Constants";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./EditProfile";
const { Title } = Typography;

const PatientProfile = () => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.entries(profileDetails)?.length === 0) {
      dispatch(setProfileDetails(userDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <Row gutter={[16, 16]} className="mt105">
          <Col span={24} className="flex-center">
            <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
              <div>{label}: </div>
              <div className="grey">{value}</div>
            </Flex>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Row
      gutter={[16, 16]}
      justify="space-between"
      data-testid="patient-profile"
    >
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <div className="left-widget">
          <div className="cover-photo-container">
            <img src={COVER_PHOTO} alt="CoverPic" className="fit cover-photo" />

            <div className="avatar">
              <div className="box-shadow">
                <img
                  src={`data:image/png;base64,${profileDetails?.qr}`}
                  alt="Base64"
                  width="110"
                />
              </div>
            </div>
          </div>
          <div className="text-center mt5">
            <Row>
              <Col span={24}>
                <Title level={3}>{displayUserName()}</Title>
              </Col>
            </Row>

            {profileDetails?.addressLine1 && (
              <div className="flex-center fs12">
                <Flex wrap="wrap" gap="small">
                  <div className="grey">{profileDetails?.addressLine1}</div>
                </Flex>
              </div>
            )}

            <Divider style={{ margin: "1rem  0 0 0" }} />
            <div className="pd1">
              {renderProfileDetails("Age", profileDetails?.age)}
              {renderProfileDetails(FORM_FIELDS.GENDER, profileDetails?.gender)}
              {renderProfileDetails(
                FORM_FIELDS.DOB,
                displayOnlyDate(profileDetails?.dateOfBirth)
              )}
              {renderProfileDetails(FORM_FIELDS.MRN, profileDetails?.mrn)}
              {renderProfileDetails(FORM_FIELDS.EMAIL, profileDetails?.email)}
              {renderProfileDetails(
                FORM_FIELDS.SURGERY_DATE,
                profileDetails?.surgeryDate
              )}
              {renderProfileDetails(
                FORM_FIELDS.PHONE,
                profileDetails?.phoneNumber
              )}
              {renderProfileDetails(
                FORM_FIELDS.FEET_HEIGHT,
                profileDetails?.feet && profileDetails?.inches
                  ? `${profileDetails?.feet}.${profileDetails?.inches}`
                  : null
              )}
              {renderProfileDetails(
                FORM_FIELDS.LBS_WEIGHT,
                profileDetails?.lbsWeight
              )}
              {profileDetails?.providerName && (
                <Row gutter={[16, 16]} className="mt105">
                  <Col span={24}>
                    <div className="text-center">Providers: </div>
                    <div className="text-center grey">
                      {profileDetails?.providerName?.map((item, index) => {
                        return (
                          <Tag key={index} className="mt05">
                            {item}
                          </Tag>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              )}
              {renderProfileDetails(
                FORM_FIELDS.INSURANCE,
                profileDetails?.insurance
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <EditProfile />
      </Col>
    </Row>
  );
};
export default PatientProfile;
