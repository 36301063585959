import { Col, Row } from "antd";
import { ROUTES } from "../Constants";
import ExperienceNow from "../ExperienceNow";

const HeroSection = ({
  carousel,
  imgSrc,
  titleColSpan,
  descColSpan,
  title,
  subTitle,
  description,
  redirectPath,
}) => {
  return (
    <Row justify="center">
      <Col span={24}>
        <div className="home-banner-container">
          {carousel ? carousel : ""}
          {imgSrc ? (
            <img src={imgSrc} alt="Banner" className="home-banner-image" />
          ) : (
            ""
          )}
          <div className="flex-center">
            <div className="home-banner-text">
              <Row justify="center">
                <Col
                  xs={24}
                  sm={24}
                  md={titleColSpan ? titleColSpan : 20}
                  lg={titleColSpan ? titleColSpan : 18}
                  xl={titleColSpan ? titleColSpan : 18}
                >
                  <div
                    className="home-banner-head"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </Col>
              </Row>
              {subTitle && (
                <Row justify="center">
                  <Col xs={22} sm={22} md={24} lg={24} xl={24}>
                    <div className="home-caption mt1">{subTitle}</div>
                  </Col>
                </Row>
              )}
              <Row justify="center" className="home-center-p-btn">
                <Col xs={16} sm={8} md={6} lg={5} xl={5}>
                  <ExperienceNow
                    redirectPath={
                      redirectPath ? redirectPath : ROUTES.PATIENT.REGISTER.PATH
                    }
                  />
                </Col>
              </Row>
            </div>
            {description && (
              <div className="home-banner-desc-layout">
                <Row justify="center">
                  <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={descColSpan ? descColSpan : 18}
                    xl={descColSpan ? descColSpan : 18}
                  >
                    <div
                      className="home-banner-p"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HeroSection;
