import React, { createContext, useState, useContext } from "react";

const ApiLoaderContext = createContext();

export const ApiLoaderProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showFullLoader, setShowFullLoader] = useState(false);

  return (
    <ApiLoaderContext.Provider
      value={{ showLoader, setShowLoader, showFullLoader, setShowFullLoader }}
    >
      {children}
    </ApiLoaderContext.Provider>
  );
};

export const useApiLoader = () => {
  return useContext(ApiLoaderContext);
};
