import { Col } from "antd";
import PatientVitals from "../../Common/PatientVitals";

const Graphs = ({ graphDetails }) => {
  return (
    <>
      {graphDetails?.map((item, index) => {
        return (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
            <div className="widget box-shadow">
              <PatientVitals graphData={item} />
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default Graphs;
