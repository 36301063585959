import { Card, Col, Divider, Flex, Row } from "antd";
import HR from "../../../Assets/images/Ring/hr.png";
import HRV from "../../../Assets/images/Ring/hrv.png";
import SPO2 from "../../../Assets/images/Ring/spo2.png";
import RESPIRATORY from "../../../Assets/images/Ring/respiratory.png";
import STEPS from "../../../Assets/images/Ring/steps.png";
import CALORIES from "../../../Assets/images/Ring/calories.png";
import DISTANCE from "../../../Assets/images/Ring/distance.png";
import SLEEP from "../../../Assets/images/Ring/deepSleep.png";

const Health = ({ healthDetails }) => {
  const dataMappings = {
    heartRate: { label: "Heart Rate", image: HR, units: "bpm" },
    hrv: { label: "HRV", image: HRV, units: "ms" },
    respirationRate: {
      label: "Respiration Rate",
      image: RESPIRATORY,
      units: "breaths/min",
    },
    bloodOxygen: { label: "SpO2", image: SPO2, units: "%" },
    calories: { label: "Calories", image: CALORIES, units: "kcal" },
    step: { label: "Steps", image: STEPS, units: "" },
    distance: { label: "Distance", image: DISTANCE, units: "mi" },
    totalSleepSeconds: { label: "Sleep", image: SLEEP, units: "" },
  };

  const renderCards = (index, span, key, data) => {
    return (
      dataMappings[key] &&
      (key === "totalSleepSeconds" ? (
        <Col key={key} xs={24} sm={12} md={12} lg={12} xl={6}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "1rem",
              },
            }}
          >
            <Row key={key} justify={"space-between"}>
              <Col span={16}>
                <div className="fs16 bold grey">{dataMappings[key]?.label}</div>
                <div className="mt2">
                  <Flex wrap="wrap" gap="small" className="flex-baseline">
                    <div className="fs30 bold">{data[key].hrs}</div>
                    <div>hrs</div>
                    <div className="fs30 bold">{data[key].mins}</div>
                    <div>mins</div>
                  </Flex>
                </div>
              </Col>
              <Col span={8}>
                <img src={dataMappings[key]?.image} alt="sleep" width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
      ) : (
        <Col key={index} xs={24} sm={12} md={12} lg={8} xl={span}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "1rem",
              },
            }}
          >
            <Row justify={"space-between"}>
              <Col span={16}>
                <div className="fs16 bold grey">{dataMappings[key]?.label}</div>
                <div className="mt2">
                  <Flex wrap="wrap" gap="small" className="flex-baseline">
                    <div className="fs30 bold">{data[key]}</div>
                    <div>{dataMappings[key]?.units}</div>
                  </Flex>
                </div>
              </Col>
              <Col span={8}>
                <img src={dataMappings[key]?.image} alt={key} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
      ))
    );
  };

  return (
    <>
      {Object.entries(healthDetails?.weeklyAverages)?.length > 0 && (
        <>
          <div className="bold fs20">Weekly Average</div>
          <Divider style={{ margin: "0.5rem 0 1rem 0" }} />
          <Row gutter={[16, 16]} className="mt2" justify="start">
            {Object.keys(healthDetails?.weeklyAverages)?.map((key, index) => {
              return (
                key !== "toString" &&
                renderCards(index, 6, key, healthDetails?.weeklyAverages)
              );
            })}
          </Row>
        </>
      )}
      {Object.entries(healthDetails?.monthlyAverages)?.length > 0 && (
        <>
          <div className="bold mt3 fs20">Monthly Average</div>
          <Divider style={{ margin: "0.5rem 0 1rem 0" }} />
          <Row gutter={[16, 16]} className="mt2" justify="start">
            {Object.keys(healthDetails?.monthlyAverages)?.map((key, index) => {
              return (
                key !== "toString" &&
                renderCards(index, 6, key, healthDetails?.monthlyAverages)
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};

export default Health;
