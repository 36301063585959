import { clinicAdminSleepBookingsUrl } from "../../../Common/Endpoints";
import Bookings from "../../../Common/Bookings";

const SleepBookings = () => {
  return (
    <Bookings
      getUrl={clinicAdminSleepBookingsUrl}
      redirectUrl={false}
      putUrl={clinicAdminSleepBookingsUrl}
      locationType="sleep"
    />
  );
};

export default SleepBookings;
