import { Card, Col, Divider, Empty, Form, Radio, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApis, postApis } from "../Apis";
import { BUTTONS, NOTIFICATIONS, USER_TYPES, VALIDATIONS } from "../Constants";
import MonthlyCalendar from "../MonthlyCalendar";
import Popup from "../Popup";
import {
  centalisedSubmitBtn,
  disabledBookingsDate,
  disabledFotonaBookingDates,
  getOnlyWeekDays,
} from "../Utils";
import { setTimeSlots } from "../../../Reducers/bookingsSlice";
import {
  clinicAdminFotonaSlotsUrl,
  clinicAdminSleepSlotsUrl,
} from "../Endpoints";
import useNotify from "../Notify";
import moment from "moment";

const Reschedule = ({
  form,
  modalOpen,
  cancelModal,
  details,
  bookingsUrl,
  userType,
  selectedRow,
}) => {
  const bookings = useSelector((state) => state?.bookings);
  const timeSlots = bookings?.timeSlots;
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [newSlot, setNewSlot] = useState();
  const [newDays, setNewDays] = useState();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const currentDate = moment();

  const currentData = {
    bookingID: details?.bookingID,
    location: details?.location,
    bookingDate: details?.bookingDate,
    returnDate: details?.returnDate,
    days: details?.days,
    phoneNumber: details?.phoneNumber,
  };

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots]);

  useEffect(() => {
    form.setFieldsValue({
      location: selectedRow?.location,
      bookingDate: selectedRow?.bookingDate,
      returnDate: selectedRow?.returnDate,
      days: selectedRow?.days,
      rescheduleStartDate: currentDate.format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const onFinish = async (values) => {
    setLoading(true);
    const resultDate = getOnlyWeekDays(
      values?.rescheduleStartDate,
      values?.days
    );
    await postApis(
      bookingsUrl,
      details?.bookingType === "FOTONA-NIGHTLASE"
        ? {
            ...values,
            bookingID: details?.bookingID,
            action: "reschedule",
            clinicName: details?.clinicName,
          }
        : {
            ...values,
            bookingID: details?.bookingID,
            rescheduleEndDate: resultDate.format("YYYY-MM-DD"),
            action: "reschedule",
            clinicName: details?.clinicName,
          }
    )
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };

  const getAvailableSlots = async (params) => {
    setShowLoader(true);
    await getApis(
      userType === USER_TYPES.PATIENT
        ? bookingsUrl
        : details?.bookingType === "FOTONA-NIGHTLASE"
        ? clinicAdminFotonaSlotsUrl
        : clinicAdminSleepSlotsUrl,
      params
    )
      .then((response) => {
        dispatch(setTimeSlots(response?.data));
        setNewSlot();
        form.setFieldsValue({
          slot: null,
        });
        setShowLoader(false);
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    const resultDate = getOnlyWeekDays(
      date.format("YYYY-MM-DD"),
      newDays || details?.days
    );
    const values = {
      ...currentData,
      days: newDays || details?.days,
      bookingDate: date.format("YYYY-MM-DD"),
      returnDate: resultDate.format("YYYY-MM-DD"),
    };

    form.setFieldsValue({
      rescheduleStartDate: date.format("YYYY-MM-DD"),
    });

    getAvailableSlots(values);
  };

  const handleTagClick = (value) => {
    setNewSlot(value);
    form.setFieldsValue({
      slot: value,
    });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.days) {
      setNewDays(changedValues?.days);
    }
    const resultDate = getOnlyWeekDays(
      allValues?.rescheduleStartDate,
      allValues?.days
    );
    const params = {
      ...allValues,
      location: details?.location,
      bookingDate: allValues?.rescheduleStartDate || details?.bookingDate,
      returnDate: resultDate?.format("YYYY-MM-DD"),
      bookingID: details?.bookingID,
    };
    getAvailableSlots(params);
  };

  return (
    <Popup
      title={"Reschedule"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={850}
      style={{ top: "8%" }}
    >
      <Row justify={"center"}>
        <Col span={24} className="flex-center">
          <Form
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            autoComplete="off"
            layout="vertical"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
            style={{ width: "100%" }}
          >
            <div className="grey-fill pd1">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                  <Row>
                    <Col span={8}>Slot:</Col>
                    <Col span={16}>
                      <b>{details?.slot}</b>
                    </Col>
                  </Row>
                  <Row className="mt05">
                    <Col span={8}>Scheduled For:</Col>
                    <Col span={16}>
                      <b>{details?.bookingDate}</b>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                  <Row>
                    <Col span={8}>Location:</Col>
                    <Col span={16}>
                      <b>{details?.location}</b>
                    </Col>
                  </Row>
                  {details?.insurance && (
                    <Row className="mt05">
                      <Col span={8}>Insurance:</Col>
                      <Col span={16}>
                        <b>{details?.insurance}</b>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>

            {details?.bookingType !== "FOTONA-NIGHTLASE" && (
              <Row gutter={[16, 16]} className="mt2">
                <Col span={24}>
                  <Form.Item
                    label="Rent device for?"
                    name="days"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Radio.Group className="mt05">
                      <Radio value={1}>1 Day</Radio>
                      <Radio value={2}>2 Days</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <div className="bold blue text-center fs18 mt2">Select Again</div>
            <Divider style={{ margin: "0.5rem 0 2rem 0" }} />

            <Row gutter={[16, 16]} className="mt1">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Booking Date"
                  name="rescheduleStartDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Card>
                    <MonthlyCalendar
                      handleDateSelect={handleDateSelect}
                      disabledDate={(current) =>
                        details?.bookingType === "FOTONA-NIGHTLASE"
                          ? disabledFotonaBookingDates(current)
                          : disabledBookingsDate(current, details?.weeks)
                      }
                    />
                  </Card>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Slot"
                  name="slot"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Card
                    styles={{
                      body: {
                        minHeight: "424px",
                        height: "424px",
                        overflow: "auto",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <div className="flex-center">
                      {showLoader ? (
                        <Spin size="large" />
                      ) : timeSlots?.length > 0 ? (
                        <div>
                          <div className="bold blue">Morning</div>
                          <Divider style={{ margin: "0.5rem 0" }} />
                          {amTimes?.map((item, index) => {
                            return (
                              <Tag
                                key={index}
                                className={`mt1 ml05 cursor-pointer ${
                                  newSlot === item ? "selected-tag" : ""
                                }`}
                                onClick={() => handleTagClick(item)}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                          <div className="bold blue mt2">Afternoon</div>
                          <Divider style={{ margin: "0.5rem 0" }} />
                          {pmTimes?.map((item, index) => {
                            return (
                              <Tag
                                key={index}
                                className={`mt1 ml05 cursor-pointer ${
                                  newSlot === item ? "selected-tag" : ""
                                }`}
                                onClick={() => handleTagClick(item)}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </div>
                      ) : (
                        <Empty />
                      )}
                    </div>
                  </Card>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              {centalisedSubmitBtn(loading, BUTTONS.SUBMIT, null, 6)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Popup>
  );
};

export default Reschedule;
