import { Button, Col, Form, Table } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useState } from "react";
import { NOTIFICATIONS } from "../../Common/Constants";
import { postApis } from "../../Common/Apis";
import { providerResolveAlertUrl } from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import ResolveAlert from "../Alerts/ResolveAlert";
import { displayDateTime } from "../../Common/Utils";

const Alerts = ({ alertDetails, mrn, service, defaultDoctor }) => {
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();
  const [form] = Form.useForm();

  const showModal = (record) => {
    setSelectedAlert(record);
    setModalOpen(true);
    form.resetFields();
  };

  const cancelModal = () => {
    setSelectedAlert(null);
    setModalOpen(false);
  };

  const columns = [
    {
      title: "Vital Name",
      dataIndex: "vitalName",
    },
    {
      title: "Vital",
      dataIndex: "vitalValue",
      render: (_, record) => (
        <div
          className={record?.priority === "High" ? "high-alert" : "low-alert"}
        >
          <WarningFilled className="mr025" /> {record?.vitalValue} {"  "}
          {record?.vitalName}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "alertStatus",
    },
    {
      title: "Vital Collected At",
      dataIndex: "vitalCollectedTime",
      render: (_, record) => displayDateTime(record?.vitalCollectedTime),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="flex-center">
          {record?.alertStatus === "Pending" ? (
            <Button
              size="small"
              type="primary"
              className="mr05 blue-btn"
              onClick={() => showModal(record)}
            >
              Resolve
            </Button>
          ) : (
            <Button
              size="small"
              className="mr05"
              onClick={() => showModal(record)}
            >
              Resolved
            </Button>
          )}
        </div>
      ),
    },
  ];

  const onFinish = async () => {
    const formData = {
      reason: selectedReason,
      description: otherReason,
      vitalName: selectedAlert?.vitalName,
      vitalValue: selectedAlert?.vitalValue,
      mrn: mrn,
      provider: defaultDoctor,
      service: service,
      vitalCollectedTime: new Date(selectedAlert?.vitalCollectedTime).getTime(),
    };
    setLoading(true);
    await postApis(providerResolveAlertUrl, formData)
      .then((response) => {
        setLoading(false);
        setModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTextAreaChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleDropdownChange = (value) => {
    setSelectedReason(value);
    setOtherReason("");
  };

  return (
    <>
      <ResolveAlert
        form={form}
        selectedAlert={selectedAlert}
        handleTextAreaChange={handleTextAreaChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        handleDropdownChange={handleDropdownChange}
        cancelModal={cancelModal}
        modalOpen={modalOpen}
        loading={loading}
        selectedReason={selectedReason}
      />
      <Col span={24}>
        <Table
          rowKey={(record) =>
            `${record?.vitalName}-${record?.mrn}-${new Date(
              record?.vitalCollectedTime
            ).getTime()}`
          }
          bordered
          columns={columns}
          dataSource={alertDetails}
          scroll={{ x: "100%" }}
          pagination={false}
        />
      </Col>
    </>
  );
};

export default Alerts;
