import { Card, Col } from "antd";
import { Link } from "react-router-dom";
import { COMMON } from "./AppContent";

const ImageCard = ({
  linkedCard,
  minHeight,
  id,
  imgSrc,
  title,
  description,
  path,
  textClass,
}) => {
  return linkedCard ? (
    <Link to={`/video/${id}`} target="_blank">
      <Card styles={{ body: { minHeight: minHeight, padding: "1rem" } }}>
        <img alt="example" src={imgSrc} width="100%" className="mb05" />
        {title}
      </Card>
    </Link>
  ) : (
    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
      <Card
        styles={{
          body: {
            minHeight: minHeight,
            padding: "1rem",
          },
        }}
      >
        <img alt="example" src={imgSrc} width="100%" className="mb05" />
        <div className={`${textClass} blue bold mt05`}>{title}</div>
        <div className="left-aligned-pgp fs13 mt1">{description}</div>
        {path && (
          <div className="mt1 bold">
            <Link to={path} className="left-bottom" target={"_blank"}>
              {COMMON.READ_MORE}
            </Link>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default ImageCard;
