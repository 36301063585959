import { Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApis } from "../../Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import { providerForgotPasswordUrl } from "../../Endpoints";
import useNotify from "../../Notify";
import { patientForgotPasswordUrl } from "../../Endpoints";
import CustomForm from "../../CustomForm";
import { useSelector } from "react-redux";
import { emailInput } from "../../FormFields";
const { Title } = Typography;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { notify } = useNotify();
  const userType = useSelector((state) => state?.app?.userType);

  const onFinish = async (values) => {
    setLoading(true);

    await postApis(
      userType === USER_TYPES.PROVIDER
        ? providerForgotPasswordUrl
        : patientForgotPasswordUrl,
      {
        email: values?.email,
        link: `${window.location.origin}${
          userType === USER_TYPES.PROVIDER
            ? ROUTES.PROVIDER.RESET_PASSWORD.PATH
            : ROUTES.PATIENT.RESET_PASSWORD.PATH
        }`,
      }
    )
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        navigate(
          userType === USER_TYPES.PROVIDER
            ? ROUTES.PROVIDER.LOGIN.PATHS.EMAIL
            : ROUTES.PATIENT.LOGIN.PATHS.EMAIL
        );
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div className="mt2">
      <Title>Forgot Password</Title>
      <div className="grey">Please enter your email-id below</div>

      <div className="grey mt05">
        We will send a reset password link to your inbox
      </div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[emailInput]}
        initialValues={{}}
        loading={loading}
        text={BUTTONS.SEND}
        style={{ width: "100%" }}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
      />
    </div>
  );
};
export default ForgotPassword;
