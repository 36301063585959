import { Card, Col, Empty, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import { clinicAdminDashboardUrl } from "../../Common/Endpoints";
const { Title } = Typography;

const ClinicAdminDashboard = () => {
  const [dashboardDetails, setDashboardDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();

  const getDashboardDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminDashboardUrl, {})
      .then((response) => {
        setShowLoader(false);
        setDashboardDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getDashboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionDetails = (data) => {
    let actionList = [];
    data?.map((item, index) => {
      return actionList.push(
        <Row key={index} className="blue" style={{ cursor: "default" }}>
          <Col span={24}>
            <div className="bold fs28">{item?.value}</div>
            <div className="bold fs12">{item?.label}</div>
          </Col>
        </Row>
      );
    });
    return actionList;
  };

  const renderRows = (data, title, color) => {
    return (
      <Row className="mt1">
        <Col span={24}>
          <Card actions={actionDetails(data?.details)} className="text-center">
            <Title level={1} className={`${color} m0`}>
              {data?.count}
            </Title>
            <div className="blue bold">{title}</div>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    !showLoader && (
      <div data-testid="clinicAdmin-dashboard">
        {dashboardDetails ? (
          <>
            {renderRows(
              dashboardDetails?.providers,
              "Total Providers",
              "orange"
            )}
            {renderRows(
              dashboardDetails?.activePatients,
              "Total Patients",
              "green"
            )}
          </>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </div>
    )
  );
};

export default ClinicAdminDashboard;
