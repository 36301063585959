import { Col, Row, Steps } from "antd";
import { useState } from "react";
import StepTwo from "./StepTwo";
import { useSelector } from "react-redux";
import PatientStepThree from "./PatientStepThree";
import ProviderStepThree from "./ProviderStepThree";
import { USER_TYPES } from "../../Constants";
import ProviderStepOne from "./ProviderStepOne";
import PatientStepOne from "./PatientStepOne";

const Registration = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const userType = app?.userType;

  return (
    <div className="signUpWidget" data-testid="registration">
      <Row>
        <Col span={24}>
          {isMobile ? (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="horizontal"
              style={{ flexDirection: "row", textAlign: "center" }}
              items={[
                {
                  title: "",
                },
                {
                  title: "",
                },
                {
                  title: "",
                },
              ]}
            />
          ) : (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="vertical"
              items={[
                {
                  title: "Contact Details",
                },
                {
                  title: "Verification",
                },
                {
                  title: "Personal Details",
                },
              ]}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {currentStep === 0 && userType === USER_TYPES.PATIENT && (
            <PatientStepOne setCurrentStep={setCurrentStep} />
          )}
          {currentStep === 0 && userType === USER_TYPES.PROVIDER && (
            <ProviderStepOne setCurrentStep={setCurrentStep} />
          )}
          {currentStep === 1 && <StepTwo setCurrentStep={setCurrentStep} />}
          {currentStep === 2 && userType === USER_TYPES.PATIENT && (
            <PatientStepThree />
          )}
          {currentStep === 2 && userType === USER_TYPES.PROVIDER && (
            <ProviderStepThree />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Registration;
