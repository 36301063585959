import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  graphDetails: null,
  episodeDetails: {},
  selectedEpisode: null,
  profileDetails: JSON.parse(localStorage.getItem("userDetails")) || {},
  bestValueDetails: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileState: (state) => {
      state.graphDetails = null;
      state.profileDetails = {};
      state.episodeDetails = {};
      state.selectedEpisode = null;
      state.bestValueDetails = null;
    },
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
    setGraphDetails: (state, action) => {
      state.graphDetails = action.payload;
    },
    setEpisodeDetails: (state, action) => {
      state.episodeDetails = action.payload;
    },
    setSelectedEpisode: (state, action) => {
      state.selectedEpisode = action.payload;
    },
    setBestValueDetails: (state, action) => {
      state.bestValueDetails = action.payload;
    },
  },
});

export const {
  setProfileDetails,
  setGraphDetails,
  resetProfileState,
  setEpisodeDetails,
  setSelectedEpisode,
  setBestValueDetails,
} = profileSlice.actions;
export default profileSlice.reducer;
