import { Button, Card, Col, Form, Input, Row, Slider } from "antd";
import { useState } from "react";
import { BUTTONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";

const TinnitusRating = ({ mainItem, onFinish, loading, onFinishFailed }) => {
  const [value, setValue] = useState(0);
  const [form] = Form.useForm();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const getTrackColor = () => {
    if (value <= 2) return "#FF7D01";
    if (value <= 4) return "#FFCD00";
    if (value === 5) return "#00A73E";
  };

  return (
    <Card title="Tinnitus - Rate Here" className="bdr3 box-shadow mt1">
      <p>
        How's the ringing feeling today? Let's track your tinnitus together!
        Rate this on a scale of 1 to 5, with 1 being the least and 5 being the
        highest, so your provider can stay updated and support you better.
      </p>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          score: 1,
          clinic: mainItem?.clinic,
          service: mainItem?.service,
          type: mainItem?.type,
          subType: mainItem?.subType,
        }}
        size="large"
      >
        <Form.Item name="clinic" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="service" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="type" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="subType" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="score"
          label={false}
          rules={[{ required: true, message: "Please select a value" }]}
        >
          <Slider
            onChange={handleChange}
            min={1}
            max={5}
            step={1}
            dots
            marks={{
              1: "1",
              2: "2",
              3: "3",
              4: "4",
              5: "5",
            }}
            trackStyle={{ backgroundColor: getTrackColor() }}
          />
        </Form.Item>

        <Row justify={"center"}>
          <Col xs={12} sm={8} md={8} lg={4} xl={4}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
              className="mt1"
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default TinnitusRating;
