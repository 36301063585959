import { Button, Card, Col, Result, Row } from "antd";
import { processedPhoneNumber } from "../Common/Utils";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  VALIDATIONS,
} from "../Common/Constants";
import { useEffect, useState } from "react";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase";
import useNotify from "../Common/Notify";
import { postApis } from "../Common/Apis";
import { publicContactUsUrl } from "../Common/Endpoints";
import { useNavigate } from "react-router-dom";
import CustomForm from "../Common/CustomForm";
import {
  captchaInput,
  emailInput,
  nameInput,
  phoneInput,
  zipcodeInput,
} from "../Common/FormFields";
import BANNER from "../../Assets/images/contactUsBg.png";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { notify } = useNotify();
  const navigate = useNavigate();

  useEffect(() => {
    let recaptchaVerifier;

    const initializeRecaptchaVerifier = async () => {
      if (!formSubmitted) {
        recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
          size: "normal",
        });

        try {
          await recaptchaVerifier.render();
          await recaptchaVerifier.verify();
          setCaptchaVerified(true);
        } catch (error) {
          console.error("reCAPTCHA error:", error);
        }
      }
    };

    initializeRecaptchaVerifier();

    return () => {
      if (recaptchaVerifier) {
        recaptchaVerifier.clear();
      }
    };
  }, [formSubmitted]);

  const onClickHandler = () => {
    navigate(ROUTES.DEFAULT.PATH);
    window.scrollTo(0, 0);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const phone = processedPhoneNumber(values);
    const updatedValues = {
      ...values,
      phoneNumber: phone,
    };
    delete updatedValues["code"];

    if (captchaVerified) {
      setLoading(true);
      await postApis(publicContactUsUrl, updatedValues)
        .then(() => {
          setLoading(false);
          setFormSubmitted(true);
          navigate(ROUTES.PUBLIC.CONTACT_US);
        })
        .catch((error) => {
          setLoading(false);
          notify(NOTIFICATIONS.ERROR.KEY, error?.data?.message);
        });
    } else {
      setLoading(false);
      notify(NOTIFICATIONS.ERROR.KEY, "Please verify the Captcha");
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div data-testid="contact-us">
      <div className="banner-container">
        <Row justify="center">
          <Col xs={24}>
            <img src={BANNER} alt="Banner" className="banner-image" />
            <div className="banner-text">{ROUTES.PUBLIC.CONTACT_US.LABEL}</div>
          </Col>
        </Row>
      </div>

      <Row className="flex-center pd2">
        <Col xs={24} sm={24} md={16} lg={8} xl={8}>
          <Card className="grey-fill box-shadow">
            {formSubmitted ? (
              <Result
                status="success"
                title="Thanks for contacting us!"
                extra={[
                  <Button
                    key="continue"
                    type="primary"
                    block
                    onClick={onClickHandler}
                  >
                    Continue
                  </Button>,
                ]}
              />
            ) : (
              <CustomForm
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                formFields={[
                  nameInput,
                  emailInput,
                  phoneInput,
                  zipcodeInput,
                  captchaInput,
                ]}
                initialValues={{ code: "+1" }}
                loading={loading}
                text={BUTTONS.SUBMIT}
                validations={{
                  required: VALIDATIONS.DEFAULT.BLANK,
                  types: {
                    email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
                  },
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ContactUs;
