import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table } from "antd";
import { getApis } from "../../Common/Apis";
import {
  clinicAdminPatientsUrl,
  defaultPageSize,
} from "../../Common/Endpoints";
import { displayDateWithText } from "../../Common/Utils";
import { useParams } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
const { Search } = Input;

const Tinnitus = () => {
  let { service } = useParams();
  const [patientList, setPatientList] = useState();
  const [patients, setPatients] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [searchQuery, setSearchQuery] = useState();
  const [paginationDetails, setPaginationDetails] = useState({
    current: 1,
    pageSize: defaultPageSize,
  });
  const [fromPage, setFromPage] = useState(1);
  const [forward, setForward] = useState(true);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      sortDirections: ["descend"],
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Enrolled On",
      dataIndex: "created",
      render: (_, record) => displayDateWithText(record?.created),
    },
    {
      title: "MRN",
      dataIndex: "mrn",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
    },
    {
      title: "Contact",
      dataIndex: "phoneNumber",
    },
  ];

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDetails, service]);

  const handleSearch = async (query) => {
    setPatientList();
    setSearchQuery(query);
    setFromPage(1);
    setForward(true);
    setPaginationDetails({
      forward: true,
      current: 1,
      searchBy: query,
      service: service,
      pageSize: paginationDetails.pageSize,
    });
  };

  const getPatients = async () => {
    setShowLoader(true);
    const defaultParams = {
      forward: forward,
      searchBy: searchQuery,
      service: service,
      paginationSize: paginationDetails.pageSize,
      firstDate: patientList?.firstDate,
      lastDate: patientList?.lastDate,
    };
    if (searchQuery === "") {
      delete defaultParams["searchBy"];
    }
    await getApis(clinicAdminPatientsUrl, defaultParams)
      .then((response) => {
        setPatientList(response?.data);
        setPatients(response?.data?.patients);
        setShowLoader(false);
      })
      .catch(() => setShowLoader(false));
  };

  const handlePageChange = (pagination) => {
    setFromPage(pagination.current);
    setForward(pagination.current > fromPage);
    setPaginationDetails({
      ...paginationDetails,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    !showLoader && (
      <div data-testid="ca-slit-patients">
        <Row justify={"end"} gutter={[16, 8]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Search
              allowClear
              value={searchQuery}
              placeholder="Search by First Name or MRN"
              onSearch={handleSearch}
              onChange={handleSearchInputChange}
              size="large"
              className="full-width search"
            />
          </Col>
        </Row>
        <Row className="mt1">
          <Col span={24}>
            <Table
              rowKey={(record) => record.mrn}
              columns={columns}
              dataSource={patients}
              scroll={{ x: "100%" }}
              onChange={handlePageChange}
              pagination={{
                current: paginationDetails.current,
                pageSize: paginationDetails.pageSize,
                total: patientList?.totalPatients,
                showSizeChanger: false,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => (
                  <div className="mt025">
                    Total <b>{total}</b> Items
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  );
};
export default Tinnitus;
