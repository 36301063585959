import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../../Common/Apis";
import { NOTIFICATIONS } from "../../../Common/Constants";
import {
  clinicAdminFotonaAddLocationUrl,
  clinicAdminFotonaLocationDetailsUrl,
} from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import {
  getRemoteConfigData,
  processedPhoneNumber,
} from "../../../Common/Utils";
import AddLocation from "./AddLocation";

const FotonaLocations = () => {
  const { showLoader, setShowLoader } = useApiLoader();
  const [sleepLocations, setFotonaLocations] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [staticDropdownData, setStaticDropdownData] = useState();
  const { notify } = useNotify();

  const getFotonaLocations = async () => {
    setShowLoader(true);
    await getApis(clinicAdminFotonaLocationDetailsUrl, {})
      .then((response) => {
        setShowLoader(false);
        setFotonaLocations(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );

    getFotonaLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddModal = () => {
    setIsAddModalOpen(true);
    form.resetFields();
  };

  const handleAddModelCancel = () => {
    setIsAddModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const phone = await processedPhoneNumber(values);

    await postApis(clinicAdminFotonaAddLocationUrl, {
      ...values,
      phoneNumber: phone,
    })
      .then((response) => {
        setLoading(false);
        setIsAddModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const columns = [
    {
      title: "Clinic",
      dataIndex: "clinicName",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Staff Name",
      dataIndex: "staffName",
    },
  ];

  return (
    !showLoader && (
      <div data-testid="clinicAdmin-fotonaLocations">
        {sleepLocations ? (
          <>
            {false && (
              <Row justify={"end"} gutter={[16, 8]}>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Button
                    type="primary"
                    className="full-width btn-extra-pd fs16"
                    onClick={showAddModal}
                  >
                    <PlusOutlined /> Add Location
                  </Button>
                </Col>
              </Row>
            )}
            <div className="mt1">
              <Table
                rowKey={(record) => record.clinicName}
                columns={columns}
                dataSource={sleepLocations}
                scroll={{ x: "100%" }}
                pagination={false}
              />
            </div>
            <AddLocation
              form={form}
              isAddModalOpen={isAddModalOpen}
              handleAddModelCancel={handleAddModelCancel}
              loading={loading}
              onFinish={onFinish}
              staticDropdownData={staticDropdownData}
              onFinishFailed={onFinishFailed}
            />
          </>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </div>
    )
  );
};

export default FotonaLocations;
