import { Card, Col, Row } from "antd";
import THERMO from "../../../Assets/images/Devices/thermo.png";
import BP from "../../../Assets/images/Devices/bp.png";
import PFM from "../../../Assets/images/Devices/peakFlow.png";
import POX from "../../../Assets/images/Devices/pulse.png";
import { YoutubeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Meta } = Card;

export const generalFaqs = [
  {
    key: "1",
    label: "Basics of Remote/At Home Monitoring",
    children: (
      <>
        <div className="fs18">A. What is Remote Patient Monitoring?</div>
        <p className="fs16 ml1 grey">
          i. Remote Patient Monitoring utilizes technology that allows
          healthcare providers the ability to monitor patients healthcare and
          changes in their physiologic markers remotely. It involves the uses of
          medical device sensors (FDA Approved) to collect and transmit data,
          such as vital signs, activity levels, and mediation adherence, from
          the patients' homes to healthcare providers
        </p>
        <div className="fs18">B. How does remote patient monitoring work?</div>
        <p className="fs16 ml1 grey">
          i. Remote Patient Monitoring typically involves the use of wearable or
          mobile devices, mobile apps, and secure communication platforms that
          protect your information when communicated to you and your healthcare
          provider
        </p>
        <div className="fs18">C. What vitals are typically monitored?</div>
        <p className="fs16 ml1 grey">
          i. Heart Rate, Blood Pressure, Temperature, Oxygen Saturation, Lung
          Function, etc.
        </p>
        <p className="fs16 ml1 grey">
          ii. The data can be monitored real time or reviewed in scheduled
          intervals. For example, once or several times per day
        </p>
        <div className="fs18">
          D. How often should the data collected be reviewed?
        </div>
        <p className="fs16 ml1 grey">
          i. The frequency of remote patient monitoring data expends on the
          patients conditions and the healthcare providers recommendations. It
          can range from realtime to periodic monitoring as determined by the
          healthcare team
        </p>
      </>
    ),
  },
  {
    key: "2",
    label: "Benefits of Remote Patient Monitoring",
    children: (
      <>
        <div className="fs18">
          A. Why is remote patient monitoring important?
        </div>
        <p className="fs16 ml1 grey">
          i. A healthcare provider can make more accurate decisions if they have
          a clear understanding of how your body is changing over the course of
          time, and can intervene with appropriate care when that data is
          captured on a daily basis vs once a year at a wellness visit
        </p>
        <div className="fs18">
          B. What are the benefits of Remote Patient Monitoring
        </div>
        <p className="fs16 ml1 grey">
          i. Early detection of health issues or changes in condition
        </p>
        <p className="fs16 ml1 grey">
          ii. Improved patient engagement and self-management
        </p>
        <p className="fs16 ml1 grey">
          iii. Reduced hospital readmission and emergency room visits
        </p>
        <p className="fs16 ml1 grey">
          iv. Enhanced access to healthcare for patients in remote or
          underserved areas
        </p>
        <p className="fs16 ml1 grey">
          v. Cost savings by preventing complications and unnecessary
          hospitalizations
        </p>
        <div className="fs18">
          C. How can Remote Patient Monitoring Improve Patient Outcomes?
        </div>
        <p className="fs16 ml1 grey">
          i. Remote Patient Monitoring can improve patient outcomes by enabling
          early detection of health issues or changes in condition, facilitating
          early interventions, promoting the proper interventions, and ensuring
          continuous monitoring and support outside of traditional healthcare
          settings
        </p>
      </>
    ),
  },
  {
    key: "3",
    label: "How is your Data transmitted and kept safe?",
    children: (
      <>
        <div className="fs18">
          A. Is Remote Patient Monitoring Secure and Private?
        </div>
        <p className="fs16 ml1 grey">
          i. Yes, Remote Patient Monitoring and SAiWELL powered by Saigeware
          prioritize patient privacy and data security
        </p>
        <p className="fs16 ml1 grey">
          ii. Data Transmission is encrypted, and strict HIPAA privacy protocols
          are followed to protect patient information
        </p>

        <div className="fs18">
          B. How can healthcare providers access and utilize the data collected
          through the sensors?
        </div>
        <p className="fs16 ml1 grey">
          i. Healthcare providers access not only the data but the analysis of
          the data through a secure platform. SAiWELL powered by Saigeware
          Dashboard gives the healthcare provider actionable information in
          order to not only see the data, but to interpret what to do with the
          data
        </p>
        <p className="fs16 ml1 grey">
          ii. The physiologic information that is captured (heart rate, blood
          pressure, temperature, etc.) will be collected either by a remote
          gateway given to the patient, by a mobile device, or both. That data
          will then be delivered and packaged to the healthcare provider without
          the patient having to record any of their own information
        </p>
      </>
    ),
  },
  {
    key: "4",
    label: "Why is SAiWELL, Powered By Saigeware the best solution?",
    children: (
      <>
        <div className="fs18">A. What is different about SAiWELL?</div>
        <p className="fs16 ml1 grey">
          i. SAiWELL powered by Saigeware allows the healthcare provider to
          remain a caretaker of a patients health, and not rely on an over the
          counter solution to monitor someone's healthcare
        </p>
        <p className="fs16 ml1 grey">
          ii. SAiWELL powered by Saigeware analyses the sensor data collected
          and utilizes models to predict risks, empowering the healthcare
          provider to take action without having to spend time interpreting the
          information on a daily basis
        </p>
        <p className="fs16 ml1 grey">
          iii. SAiWELL powered by Saigeware has partnered with Mayo Clinic to
          best understand clinical data, historical patient data, and utilize
          remote patient monitoring to give the most accurate picture to the
          healthcare provider what is happening with the patient
        </p>
      </>
    ),
  },
  {
    key: "5",
    label: "Is Remote Patient Monitoring Covered by Insurance?",
    children: (
      <>
        <div className="fs18">
          A. Is Remote Patient Monitoring Covered by Medicare?
        </div>
        <p className="fs16 ml1 grey">
          i. Yes, Medicare provides coverage and payment to the healthcare
          provider for remote patient monitoring when deemed medically
          necessary. SAiWELL with work with you and Medicare to follow the
          appropriate protocols for coverage
        </p>
        <div className="fs18">
          B. Is Remote Patient Monitoring Covered by Private Insurance
        </div>
        <p className="fs16 ml1 grey">
          i. Coverage for Remote Patient Monitoring is widely accepted by
          Private Insurance, and typically follows Medicare guidelines. SAiWELL
          powered by Saigeware will work with every patient to identify their
          individual coverage by their specific insurance plan
        </p>
        <div className="fs18">
          C. How are Remote Patient Monitoring Services Billed?
        </div>
        <p className="fs16 ml1 grey">
          i. Medicare and/or Private insurance is billed on a monthly basis.
          SAiWELL powered by Saigeware will communicate with the patient
          regularly to ensure the out of pocket expenses to the patient are
          contained, and in most cases will not cost the patient any additional
          expense
        </p>
        <p className="fs16 ml1 grey">
          ii. Depending on the nature of the condition being monitored,
          healthcare providers can recommend a monitoring time of 1 to 4 months,
          or could stretch to an indefinite period in order to continue to
          collect information that will guide the medical professional in the
          personalized clinical decision making process
        </p>
      </>
    ),
  },
];

export const devicesFaqs = [
  {
    key: "1",
    label: "Tenovi Infrared Thermometer",
    children: (
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Link
            to="https://www.youtube.com/watch?v=0gCrrWqu8PY"
            target={"_blank"}
          >
            <Card
              styles={{ body: { background: "#f8f8f8" } }}
              cover={<img alt="THERMO" src={THERMO} />}
              className="box-shadow"
            >
              <Meta
                title={
                  <div className="left">
                    <div className="flex-center">
                      <YoutubeFilled
                        style={{ fontSize: "28px", color: "red" }}
                        className="mr05"
                      />
                      Infrared Thermometer
                    </div>
                  </div>
                }
                description="www.youtube.com"
              />
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="flex-center">
          <p className="fs16">
            Cellular-Connected Temperature Monitoring Measures body temperature
            FDA-cleared, CE, and EN certified; industry-leading accuracy No need
            to calibrate Transmits data through a cellular network with the
            Tenovi Cellular Gateway
          </p>
        </Col>
      </Row>
    ),
  },
  {
    key: "2",
    label: "Tenovi BPM",
    children: (
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="flex-center">
          <p className="fs16">
            Accurate, Cellular-Connected Blood Pressure Monitoring Measures
            systolic pressure, diastolic pressure, and pulse rate FDA-cleared,
            CE, and EN certified with industry-leading accuracy Large, easy to
            read digital display Irregular heartbeat and body motion detection
            Transmits data through a cellular network with the Tenovi Cellular
            Gateway
          </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Link
            to="https://www.youtube.com/watch?v=KL-HskTwFEA"
            target={"_blank"}
          >
            <Card
              styles={{ body: { background: "#f8f8f8" } }}
              cover={<img alt="BP" src={BP} />}
              className="box-shadow"
            >
              <Meta
                title={
                  <div className="left">
                    <div className="flex-center">
                      <YoutubeFilled
                        style={{ fontSize: "28px", color: "red" }}
                        className="mr05"
                      />
                      BP Monitor
                    </div>
                  </div>
                }
                description="www.youtube.com"
              />
            </Card>
          </Link>
        </Col>
      </Row>
    ),
  },
  {
    key: "3",
    label: "Tenovi Peak Flow Meter",
    children: (
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Link
            to="https://www.youtube.com/watch?v=TSjRQI1aYPk"
            target={"_blank"}
          >
            <Card
              styles={{ body: { background: "#f8f8f8" } }}
              cover={<img alt="PFM" src={PFM} />}
              className="box-shadow"
            >
              <Meta
                title={
                  <div className="left">
                    <div className="flex-center">
                      <YoutubeFilled
                        style={{ fontSize: "28px", color: "red" }}
                        className="mr05"
                      />
                      Peak Flow Meter
                    </div>
                  </div>
                }
                description="www.youtube.com"
              />
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="flex-center">
          <p className="fs16">
            Cellular-Connected Peak Flow Meter Measures PEF and FEV1
            FDA-cleared, CE, and EN certified with industry-leading accuracy No
            need to calibrate Transmits data through a cellular network with the
            Tenovi Cellular Gateway
          </p>
        </Col>
      </Row>
    ),
  },
  {
    key: "4",
    label: "Tenovi Pulse Oximeter",
    children: (
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="flex-center">
          <p className="fs16">
            Cellular-Connected Pulse Oximeter Measures oxygen saturation and
            pulse rate FDA-cleared, CE, and EN certified with industry-leading
            accuracy Multi-color LED Real-time bar graph indicator Transmits
            data through a cellular network with the Tenovi Cellular Gateway
          </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Link
            to="https://www.youtube.com/watch?v=XI-CR3sVwe4"
            target={"_blank"}
          >
            <Card
              styles={{ body: { background: "#f8f8f8" } }}
              cover={<img alt="POX" src={POX} />}
              className="box-shadow"
            >
              <Meta
                title={
                  <div className="left">
                    <div className="flex-center">
                      <YoutubeFilled
                        style={{ fontSize: "28px", color: "red" }}
                        className="mr05"
                      />
                      Pulse Oximeter
                    </div>
                  </div>
                }
                description="www.youtube.com"
              />
            </Card>
          </Link>
        </Col>
      </Row>
    ),
  },
];
