import { message } from "antd";
import { useCallback } from "react";

const useNotify = () => {
  const notify = useCallback((type, description) => {
    message[type](description);
  }, []);

  return {
    notify,
  };
};

export default useNotify;
