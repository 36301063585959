import { Layout } from "antd";
import "./style.css";
import PublicHeader from "./Headers/Public";
import LargeFooter from "./Footers/Large";
const { Content } = Layout;

const PublicLayout = (props) => {
  return (
    <Layout className="mh100vh" data-testid="signup-layout">
      <PublicHeader />
      <Content>{props.children}</Content>
      <LargeFooter />
    </Layout>
  );
};
export default PublicLayout;
