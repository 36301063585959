import { Card, Empty, Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { deleteApis, getApis, putApis } from "../../Common/Apis";
import { NOTIFICATIONS, ROUTES } from "../../Common/Constants";
import {
  patientPaymentsUrl,
  patientPaymentTransactionsUrl,
} from "../../Common/Endpoints";
import Transactions from "./Transactions";
import Cards from "./Cards";
import useNotify from "../../Common/Notify";
import Refund from "./Refund";

const Payments = () => {
  const [activeTab, setActiveTab] = useState("transactions");
  const [cardDetails, setCardDetails] = useState();
  const [transactions, setTransactions] = useState();
  const [refunds, setRefunds] = useState();
  const [message, setMessage] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const navigate = useNavigate();
  const { notify } = useNotify();
  const { confirm } = Modal;

  const getCards = async () => {
    setShowLoader(true);
    await getApis(patientPaymentsUrl)
      .then((response) => {
        setShowLoader(false);
        setCardDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getTransactions = async () => {
    setShowLoader(true);
    await getApis(patientPaymentTransactionsUrl)
      .then((response) => {
        setShowLoader(false);
        setTransactions(response?.data?.transactions);
        setRefunds(response?.data?.refund);
      })
      .catch(() => setShowLoader(false));
  };

  const updatePayment = async () => {
    await putApis(patientPaymentsUrl, {
      chekoutOutSessionId: sessionId,
    })
      .then(() => navigate(ROUTES.PATIENT.PAYMENTS.PATH))
      .catch(() => {});
  };

  const showDeleteConfirm = (clinic, record) => {
    confirm({
      title: "Are you sure?",
      content: "This action will delete a card.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        deleteCard(clinic, record);
      },
      onCancel() {},
    });
  };

  const deleteCard = async (clinic, record) => {
    await deleteApis(patientPaymentsUrl, {
      paymentId: record?.id,
      clinic: clinic,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setShowLoader(false);
        setMessage(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (activeTab === "cards") {
      getCards();
    } else if (activeTab === "transactions") {
      getTransactions();
    }
    if (sessionId) {
      updatePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const tabItems = [
    {
      key: "transactions",
      label: "Transactions",
      children: transactions ? (
        <Transactions transactions={transactions} />
      ) : (
        <Empty />
      ),
    },
    {
      key: "refund",
      label: "Refunds",
      children: refunds ? <Refund refunds={refunds} /> : <Empty />,
    },
    {
      key: "cards",
      label: "Cards",
      children: cardDetails ? (
        <Cards
          cardDetails={cardDetails}
          message={message}
          setMessage={setMessage}
          showDeleteConfirm={showDeleteConfirm}
        />
      ) : (
        <Empty />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    !showLoader && (
      <Card className="mt1">
        <Tabs
          type="card"
          defaultActiveKey={activeTab}
          onChange={onTabChange}
          items={tabItems}
        />
      </Card>
    )
  );
};

export default Payments;
