import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileDetails: null,
  episodeDetails: null,
  feNoDetails: null,
  claimDetails: null,
  vialDetails: null,
  graphDetails: null,
  bestPefDetails: null,
  vitalDetails: null,
  alertDetails: null,
  deviceDetails: null,
  timelineDetails: null,
  questionnaireDetails: null,
  emrDetails: null,
  reportDetails: null,
  wellnessDetails: null,
  healthDetails: null,
  emrSelectedKey: 1,
  riskDetails: null,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
    setEpisodeDetails: (state, action) => {
      state.episodeDetails = action.payload;
    },
    setFeNoDetails: (state, action) => {
      state.feNoDetails = action.payload;
    },
    setClaimDetails: (state, action) => {
      state.claimDetails = action.payload;
    },
    setVialDetails: (state, action) => {
      state.vialDetails = action.payload;
    },
    setGraphDetails: (state, action) => {
      state.graphDetails = action.payload;
    },
    setBestPefDetails: (state, action) => {
      state.bestPefDetails = action.payload;
    },
    setVitalDetails: (state, action) => {
      state.vitalDetails = action.payload;
    },
    setAlertDetails: (state, action) => {
      state.alertDetails = action.payload;
    },
    setDeviceDetails: (state, action) => {
      state.deviceDetails = action.payload;
    },
    setTimelineDetails: (state, action) => {
      state.timelineDetails = action.payload;
    },
    setQuestionnaireDetails: (state, action) => {
      state.questionnaireDetails = action.payload;
    },
    setEmrDetails: (state, action) => {
      state.emrDetails = action.payload;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload;
    },
    setWellnessDetails: (state, action) => {
      state.wellnessDetails = action.payload;
    },
    setHealthDetails: (state, action) => {
      state.healthDetails = action.payload;
    },
    setEmrSelectedKey: (state, action) => {
      state.emrSelectedKey = action.payload;
    },
    setRiskDetails: (state, action) => {
      state.riskDetails = action.payload;
    },
  },
});

export const {
  setProfileDetails,
  setEpisodeDetails,
  setFeNoDetails,
  setClaimDetails,
  setVialDetails,
  setGraphDetails,
  setBestPefDetails,
  setVitalDetails,
  setAlertDetails,
  setDeviceDetails,
  setTimelineDetails,
  setQuestionnaireDetails,
  setEmrDetails,
  setReportDetails,
  setWellnessDetails,
  setHealthDetails,
  setEmrSelectedKey,
  setRiskDetails,
} = patientSlice.actions;
export default patientSlice.reducer;
