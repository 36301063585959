import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Calendar } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const MonthlyCalendar = ({ handleDateSelect, disabledDate }) => {
  const currentDate = moment().add(1, "days");
  const maxDate = currentDate.clone().add(3, "months");

  const headerRender = ({ value, onChange }) => {
    const isPrevDisabled = value.isSame(currentDate, "month");
    const isNextDisabled =
      value.isSame(maxDate, "month") || value.isAfter(maxDate, "month");

    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            if (!isPrevDisabled) {
              const newValue = value.clone().subtract(1, "month");
              onChange(newValue);
            }
          }}
          disabled={isPrevDisabled}
        />
        <span>{value.format("MMMM YYYY")}</span>
        <Button
          icon={<RightOutlined />}
          onClick={() => {
            if (!isNextDisabled) {
              const newValue = value.clone().add(1, "month");
              onChange(newValue);
            }
          }}
          disabled={isNextDisabled}
        />
      </div>
    );
  };

  return (
    <Calendar
      fullscreen={false}
      defaultValue={dayjs().add(1, "day")}
      onSelect={handleDateSelect}
      headerRender={headerRender}
      disabledDate={disabledDate}
    />
  );
};

export default MonthlyCalendar;
