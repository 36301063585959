import { Button, Input, Form, Row, Col, Select, Checkbox } from "antd";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "../../../../Reducers/authSlice";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import { BUTTONS, FORM_FIELDS, USER_TYPES, VALIDATIONS } from "../../Constants";
import { Link } from "react-router-dom";
import TermsOfUseModal from "../TermsOfUseModal";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import {
  getRemoteConfigData,
  logAnalyticsEvent,
  setAccessTokens,
  setUserDetails,
} from "../../Utils";
import { providerAccessTokenUrl, providerRegisterUrl } from "../../Endpoints";
import { postApis } from "../../Apis";
const { Title } = Typography;

const ProviderStepThree = () => {
  const [loading, setLoading] = useState(false);
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState();
  const otherStepDetails = useSelector((state) => state.auth.allStepDetails);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) => {
      setStaticDropdownData(result);
      setSelectedClinic(result?.common?.clinics?.[0]?.value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccessToken = async (uid) => {
    await postApis(providerAccessTokenUrl, {
      uid: uid,
    }).then((response) => {
      dispatch(setLoggedIn());
      setAccessTokens(response?.data);
      setUserDetails({ userType: USER_TYPES.PROVIDER });
      setLoading(false);
    });
  };

  const registerUser = async (formData) => {
    await postApis(providerRegisterUrl, formData)
      .then((response) => {
        logAnalyticsEvent("sign_up", {
          userType: USER_TYPES.PROVIDER,
          data: formData?.email,
        });
        getAccessToken(response?.data?.uid);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const updatedValues = {
      ...otherStepDetails,
      ...values,
    };
    registerUser(updatedValues);
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTermsModalCancel = () => {
    setIsTermsModalOpen(false);
  };

  const handlePrivacyModalCancel = () => {
    setIsPrivacyModalOpen(false);
  };

  return (
    <div data-testid="providerStepThree">
      <TermsOfUseModal
        isTermsModalOpen={isTermsModalOpen}
        handleTermsModalCancel={handleTermsModalCancel}
      />

      <PrivacyPolicyModal
        isPrivacyModalOpen={isPrivacyModalOpen}
        handlePrivacyModalCancel={handlePrivacyModalCancel}
      />
      <div className="mt2">
        <Title>Personal Details</Title>
      </div>
      <div className="flex-center">
        <Form
          className="mt2"
          initialValues={{ address1: "", clinic: "SFOTO" }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            width: 800,
          }}
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.FIRST_NAME}
                name="firstName"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.FIRST_NAME} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.LAST_NAME}
                name="lastName"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: VALIDATIONS.PATTERN.STRING,
                  },
                ]}
              >
                <Input placeholder={FORM_FIELDS.LAST_NAME} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.GENDER}
                name="gender"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  options={staticDropdownData?.common?.genders}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.CLINIC}
                name="clinic"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Please Select"
                  options={staticDropdownData?.common?.clinics}
                  onChange={(value) => setSelectedClinic(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.PROGRAMS}
                name="programs"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Please Select"
                  options={
                    staticDropdownData?.providers?.specialisations?.[
                      selectedClinic
                    ]
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={FORM_FIELDS.ROLE}
                name="role"
                size="large"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Please Select"
                  options={staticDropdownData?.providers?.roles}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="termsAndConditions"
                valuePropName="checked"
                className="left"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("Please accept it");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox>
                  I agree to the
                  <Link
                    onClick={() => setIsTermsModalOpen(true)}
                    className="ml025 mr025 underline"
                  >
                    Terms of Use
                  </Link>
                  and
                  <Link
                    onClick={() => setIsPrivacyModalOpen(true)}
                    className="ml025 underline"
                  >
                    Privacy Policy
                  </Link>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row className="flex-center">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item className="mt2">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={loading}
                >
                  <Loader loading={loading} text={BUTTONS.SUBMIT} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ProviderStepThree;
