import { Button, Card, Table } from "antd";
import { displayDateTime, displayOnlyDate } from "../../Common/Utils";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const BiologicsAnswers = ({
  questionnaireDetails,
  profileDetails,
  episodeDetails,
  questionnaires,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const questionKeys = new Set();

  Object.keys(questionnaireDetails?.questionnaires)?.map((item) => {
    return Object.keys(questionnaireDetails?.questionnaires?.[item][0]).forEach(
      (key) => {
        if (!["created", "subType", "type"].includes(key)) {
          questionKeys.add(key);
        }
      }
    );
  });

  const mapAnswerToLabel = (questionIndex, answer, key) => {
    const options =
      questionnaires?.["Biologics"]?.questionnaires?.[key]?.data?.[
        questionIndex
      ]?.options;
    if (options && answer !== null && answer !== undefined) {
      return options[answer - 1]?.label || "-";
    }
    return "-";
  };

  const handleDownload = (key) => {
    let formattedData;

    formattedData = questionnaireDetails?.questionnaires?.[key]?.map(
      (item) => ({
        "Filled On": displayDateTime(item.created),
        Q1: mapAnswerToLabel(0, item.Q1, key),
        Q2: mapAnswerToLabel(1, item.Q2, key),
        Q3: mapAnswerToLabel(2, item.Q3, key),
        Q4: mapAnswerToLabel(3, item.Q4, key),
        Q5: mapAnswerToLabel(4, item.Q5, key),
        Score: item.Q1 + item.Q2 + item.Q3 + item.Q4 + item.Q5,
      })
    );

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `${profileDetails?.firstName}_${profileDetails?.lastName}_${episodeDetails?.currentEpisode}_Biologics_Questionnaires_${key}`;
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const columns = [
    {
      title: "Filled On",
      dataIndex: "created",
      key: "created",
      render: (_, record) => displayOnlyDate(record?.created),
    },
    ...Array.from(questionKeys).map((key) => ({
      title: key,
      dataIndex: [key],
      key: `${key}`,
      render: (text) => {
        return text;
      },
    })),

    {
      title: "Score",
      key: "score",
      render: (_, record) => {
        const score = Object.keys(record)
          .filter((key) => key.startsWith("Q"))
          .reduce((sum, key) => sum + (record[key] || 0), 0);
        return score;
      },
    },
  ];

  return Object.keys(questionnaireDetails?.questionnaires)?.map(
    (key, index) => {
      return (
        <Card
          title={`${questionnaires?.["Biologics"]?.questionnaires?.[key]?.label} - Questionnaires`}
          key={index}
          extra={
            <Button
              type="primary"
              size="small"
              onClick={() => handleDownload(key)}
            >
              <DownloadOutlined style={{ color: "#fff", fontSize: "16px" }} />
              Download Answers
            </Button>
          }
        >
          {questionnaires?.["Biologics"]?.questionnaires?.[key]?.data?.map(
            (question, questionIndex) => (
              <div
                key={questionIndex}
                className={questionIndex > 0 ? "mt2" : ""}
              >
                <div className="mb1">
                  <span className="mr025">{questionIndex + 1}.</span>{" "}
                  {question?.title}
                </div>
                <div className="ml1">
                  {question.options.map((option, optionIndex) => (
                    <span key={optionIndex} className="mr1">
                      {optionIndex + 1}. {option.label}
                    </span>
                  ))}
                </div>
              </div>
            )
          )}

          <Table
            rowKey={(record) => `${new Date(record?.created).getTime()}`}
            dataSource={questionnaireDetails?.questionnaires?.[key]}
            columns={columns}
            bordered
            scroll={{ x: "100%" }}
            pagination={false}
            className="mt2"
          />
        </Card>
      );
    }
  );
};

export default BiologicsAnswers;
