import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOtpSent } from "../../Reducers/authSlice";
import { setMailSent } from "../../Reducers/bookingsSlice";

const Timer = () => {
  const [countdown, setCountdown] = useState(60);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    if (countdown === 0) {
      clearInterval(interval);
      dispatch(setOtpSent(false));
      dispatch(setMailSent(false));
      setCountdown(60);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <span data-testid="timer" className="orange bold">
      {countdown}s
    </span>
  );
};

export default Timer;
