import { QRCode } from "antd";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../Constants";
import Popup from "../Popup";
import { rowDataWithIcon } from "../Utils";
import {
  userInfo,
  bookingsInfoForPatient,
  bookingsInfoForClinicAdmin,
} from "./Info";

const View = ({ showQR, modalOpen, cancelModal, details }) => {
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");

  const combinedList =
    userType === USER_TYPES.PATIENT
      ? [...bookingsInfoForPatient(details), ...userInfo(details)]
      : bookingsInfoForClinicAdmin(details);

  const distinctList = combinedList.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.label === item.label)
  );

  return (
    <Popup
      title={"Booking Details"}
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={700}
      style={{ top: "8%" }}
    >
      {showQR && (
        <div className="pd1">
          <div className="flex-center">
            Show this QR to get the device at the clinic
          </div>
          <div className="flex-center pd1">
            <QRCode
              value={`${details?.bookingID}` || "NA"}
              size={120}
              bgColor="#fff"
            />
          </div>
          <div className="flex-center bold blue">ID: {details?.bookingID}</div>
        </div>
      )}

      <div>
        {distinctList?.map((item, index) => {
          return rowDataWithIcon(index, item?.icon, item?.label, item?.value);
        })}
      </div>
    </Popup>
  );
};

export default View;
