import { Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Timer from "../../Timer";
import { setLoggedIn, setOtpSent } from "../../../../Reducers/authSlice";
import { logAnalyticsEvent, setAccessTokens } from "../../Utils";
import { useEffect, useState } from "react";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import useNotify from "../../Notify";
import {
  clinicAdminAccessTokenUrl,
  clinicAdminResendEmailOtpUrl,
  providerAccessTokenUrl,
  providerResendEmailOtpUrl,
} from "../../Endpoints";
import { postApis } from "../../Apis";
import { signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../firebase";
import { otpInput } from "../../FormFields";
import CustomForm from "../../CustomForm";
const { Title } = Typography;

const VerifyOtp = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otpSent = useSelector((state) => state.auth.otpSent);
  const userType = useSelector((state) => state?.app?.userType);
  const authRedux = useSelector((state) => state.auth);
  const { notify } = useNotify();

  useEffect(() => {
    if (!window.confirmationResult) {
      navigate(
        userType === USER_TYPES.CLINIC_ADMIN
          ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.SEND_OTP
          : ROUTES.PROVIDER.LOGIN.PATHS.SEND_OTP
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccessToken = async (uid, type, data) => {
    await postApis(
      userType === USER_TYPES.CLINIC_ADMIN
        ? clinicAdminAccessTokenUrl
        : providerAccessTokenUrl,
      {
        uid: uid,
      }
    )
      .then((response) => {
        dispatch(setLoggedIn());
        setAccessTokens(response?.data);
        navigate(
          userType === USER_TYPES.CLINIC_ADMIN
            ? ROUTES.CLINIC_ADMIN.DASHBOARD.PATH
            : ROUTES.PROVIDER.DASHBOARD.PATH
        );
        setLoading(false);
        logAnalyticsEvent("login", {
          userType: userType,
          loginType: type,
          data: data,
        });
        delete window.confirmationResult;
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (
      typeof window.confirmationResult === "object" &&
      window.confirmationResult.hasOwnProperty("verificationCode")
    ) {
      if (
        window.confirmationResult?.verificationCode === parseInt(values?.otp)
      ) {
        getAccessToken(
          window.confirmationResult?.uid,
          "Email",
          window.confirmationResult?.email
        );
      } else {
        notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.INVALID_OTP);
        setLoading(false);
      }
    } else {
      window.confirmationResult
        .confirm(values?.otp)
        .then((response) => {
          getAccessToken(
            response?.user?.uid,
            "Phone",
            response?.user?.phoneNumber
          );
        })
        .catch((err) => {
          setLoading(false);
          notify(
            NOTIFICATIONS.ERROR.KEY,
            err?.message?.includes("invalid-verification-code")
              ? NOTIFICATIONS.ERROR.INVALID_OTP
              : err?.message
          );
        });
    }
  };
  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  const resendOtp = async () => {
    if (window.confirmationResult?.email) {
      await postApis(
        userType === USER_TYPES.CLINIC_ADMIN
          ? clinicAdminResendEmailOtpUrl
          : providerResendEmailOtpUrl,
        {
          email: window.confirmationResult?.email,
        }
      )
        .then((response) => {
          dispatch(setOtpSent(true));
          window.confirmationResult = {
            ...window.confirmationResult,
            ...{ verificationCode: response?.data?.verificationCode },
          };
        })
        .catch(() => {});
    } else {
      signInWithPhoneNumber(
        auth,
        authRedux?.phoneNumber,
        window.recaptchaVerifier
      )
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          dispatch(setOtpSent(true));
        })
        .catch((error) => {
          notify(NOTIFICATIONS.ERROR.KEY, error?.message);
        });
    }
  };

  return (
    <div>
      <Title>
        {userType === USER_TYPES.CLINIC_ADMIN
          ? ROUTES.CLINIC_ADMIN.LOGIN.LABEL
          : ROUTES.PROVIDER.LOGIN.LABEL}
      </Title>
      <div className="grey">Verification code has been sent</div>
      <div className="grey mt05">Please enter your One Time Passcode below</div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[otpInput]}
        initialValues={{}}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.VERIFY}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      />

      <Flex justify={"space-between"} className="mt1">
        {otpSent ? (
          <Flex wrap="wrap" gap="small" className="align-center">
            <div className="grey">Resend code in</div>
            <Timer />
          </Flex>
        ) : (
          <Link onClick={resendOtp} className="left">
            Resend Code
          </Link>
        )}
        <Link
          to={
            userType === USER_TYPES.CLINIC_ADMIN
              ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.EMAIL
              : ROUTES.PROVIDER.LOGIN.PATHS.EMAIL
          }
        >
          Email Login
        </Link>
      </Flex>
    </div>
  );
};
export default VerifyOtp;
