import { Card, Col, Row } from "antd";
import PatientVitals from "../../Common/PatientVitals";

const PrePostProcedure = ({ graphDetails }) => {
  return (
    <Row gutter={[16, 16]} className="mt1" justify="center">
      {graphDetails?.map((item, index) => {
        return (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
            <Card>
              <PatientVitals graphData={item} />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default PrePostProcedure;
