import { Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { processedPhoneNumber } from "../../Utils";
import { auth } from "../../../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { setOtpSent, setPhoneNumber } from "../../../../Reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { postApis } from "../../Apis";
import { useState } from "react";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import {
  clinicAdminVerifyPhoneUrl,
  providerVerifyPhoneUrl,
} from "../../Endpoints";
import useNotify from "../../Notify";
import CustomForm from "../../CustomForm";
import { passwordInput, phoneInput } from "../../FormFields";
const { Title } = Typography;

const SendOtp = () => {
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state?.app?.userType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useNotify();

  const checkRegisteredPhone = async (values) => {
    const phone = await processedPhoneNumber(values);

    await postApis(
      userType === USER_TYPES.CLINIC_ADMIN
        ? clinicAdminVerifyPhoneUrl
        : providerVerifyPhoneUrl,
      {
        phoneNumber: phone,
        password: values?.password,
      }
    )
      .then(() => {
        signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            dispatch(setOtpSent(true));
            dispatch(setPhoneNumber(phone));
            navigate(
              userType === USER_TYPES.CLINIC_ADMIN
                ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.VERIFY_OTP
                : ROUTES.PROVIDER.LOGIN.PATHS.VERIFY_OTP
            );
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notify(
              NOTIFICATIONS.ERROR.KEY,
              err?.message?.includes("too-many-requests")
                ? "Too many attempts. Try Later."
                : err?.message
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const parentDiv = document.getElementById("captchaDiv");
    const childDiv = document.createElement("div");
    childDiv.id = "recaptcha";
    parentDiv.appendChild(childDiv);

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
        callback: () => {
          console.log("Verified Recaptcha.");
        },
      });
    }
    checkRegisteredPhone(values);
  };

  const onFinishFailed = () => {
    setLoading(false);
    notify(NOTIFICATIONS.ERROR.KEY, NOTIFICATIONS.ERROR.MESSAGE);
  };

  return (
    <div>
      <Title>
        {userType === USER_TYPES.CLINIC_ADMIN
          ? ROUTES.CLINIC_ADMIN.LOGIN.LABEL
          : ROUTES.PROVIDER.LOGIN.LABEL}
      </Title>
      <div className="grey">Please enter your login details below</div>
      <div className="grey mt05">
        We will send a verification code to your phone
      </div>

      <CustomForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        formFields={[phoneInput, passwordInput]}
        initialValues={{ code: "+1" }}
        style={{
          width: "100%",
        }}
        loading={loading}
        text={BUTTONS.SEND}
        validations={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      />

      <Flex justify={"space-between"} className="mt1">
        <Link
          to={
            userType === USER_TYPES.CLINIC_ADMIN
              ? ROUTES.CLINIC_ADMIN.LOGIN.PATHS.EMAIL
              : ROUTES.PROVIDER.LOGIN.PATHS.EMAIL
          }
        >
          Email Login
        </Link>
      </Flex>
    </div>
  );
};
export default SendOtp;
