import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Col, Divider, Result, Row } from "antd";
import VISA from "../../../Assets/images/visa.svg";
import MASTER from "../../../Assets/images/master.svg";
import { getPageHeader } from "../../Common/Utils";

const Cards = ({ cardDetails, setMessage, showDeleteConfirm, message }) => {
  return Object.keys(cardDetails)?.map((clinic, cIndex) => {
    return (
      <div key={cIndex} className={cIndex > 0 ? "mt2" : "mt05"}>
        {message && (
          <Row className="mt1 mb1">
            <Col span={24}>
              <Alert
                message={message}
                type="error"
                closable
                onClose={() => setMessage()}
              />
            </Col>
          </Row>
        )}

        <div className="bold blue fs18">{clinic}</div>
        <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

        {cardDetails?.[clinic]?.length === 0 ? (
          <div className="flex-center">
            <Result title="No cards found for this Clinic" />
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            {cardDetails?.[clinic]?.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div className="mt05 card">
                    <Row gutter={[16, 16]}>
                      <Col span={4} className="flex-center">
                        <img
                          src={
                            item?.display_brand === "mastercard" ? MASTER : VISA
                          }
                          width="100%"
                          alt={item?.display_brand}
                        />
                      </Col>
                      <Col span={18} className="flex-start-center">
                        {getPageHeader(item?.display_brand)} ending in{" "}
                        {item?.last4}
                      </Col>
                      <Col span={2} className="flex-center">
                        <DeleteOutlined
                          className="icon18"
                          title="Delete"
                          onClick={() => showDeleteConfirm(clinic, item)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    );
  });
};

export default Cards;
