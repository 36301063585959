import { DownOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Table } from "antd";
import { USER_TYPES } from "../Constants";
import { getPageHeader } from "../Utils";

const History = ({
  bookingList,
  userType,
  getBookingDetails,
  getPatientDetails,
  locationType,
}) => {
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Scheduled For",
      dataIndex: "bookingDate",
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Type",
      dataIndex: "bookingType",
    },
    {
      title: "Payment",
      dataIndex: "stripePaymentStatus",
      render: (_, record) => {
        return record?.stripePaymentStatus
          ? record?.stripePaymentStatus === "NA"
            ? record?.stripePaymentStatus
            : getPageHeader(record?.stripePaymentStatus)
          : "NA";
      },
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      render: (_, record) =>
        record?.bookingStatus === "COMPLETED" ? (
          <span className="green bold">{record?.bookingStatus}</span>
        ) : (
          <span className="orange bold">{record?.bookingStatus}</span>
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Dropdown
          className="flex-center"
          menu={{
            items: [
              {
                key: "1",
                label: "Booking Details",
                icon: <EyeOutlined />,
                onClick: () => getBookingDetails(record),
              },

              {
                key: "2",
                label: "Patient Details",
                icon: <EyeOutlined />,
                onClick: () => getPatientDetails(record),
              },
            ].filter((item) =>
              userType === USER_TYPES.PATIENT ? item.key !== "2" : item
            ),
          }}
        >
          <Button block>
            <Space>
              Actions
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div data-testid="sleepHistory">
      <Table
        bordered
        rowKey={(record) => `${record?.bookingID}-${record?.bookingDate}`}
        columns={
          userType === USER_TYPES.PATIENT
            ? columns.filter(
                (column) => !["fullName"].includes(column.dataIndex)
              )
            : locationType === "fotona"
            ? columns.filter(
                (column) =>
                  !["returnDate", "bookingType"].includes(column.dataIndex)
              )
            : userType === USER_TYPES.CLINIC_ADMIN
            ? columns.filter(
                (column) => !["bookingType"].includes(column.dataIndex)
              )
            : columns
        }
        dataSource={bookingList}
        scroll={{ x: "100%" }}
        pagination={false}
      />
    </div>
  );
};

export default History;
