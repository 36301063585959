import { combineReducers } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import authSlice from "./authSlice";
import bookingsSlice from "./bookingsSlice";
import filterSlice from "./filterSlice";
import patientSlice from "./patientSlice";
import profileSlice from "./profileSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  filter: filterSlice,
  app: appSlice,
  patient: patientSlice,
  bookings: bookingsSlice,
});

export default rootReducer;
