import { Button, Input, Form, Row, Col, Select, Checkbox } from "antd";
import { Typography } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "../../../../Reducers/authSlice";
import { useEffect, useState } from "react";
import Loader from "../../Loader";
import {
  BUTTONS,
  FORM_FIELDS,
  NOTIFICATIONS,
  PLACEHOLDER,
  USER_TYPES,
  VALIDATIONS,
} from "../../Constants";
import { Link } from "react-router-dom";
import { postApis } from "../../Apis";
import { patientAccessTokenUrl, patientRegisterUrl } from "../../Endpoints";
import TermsOfUseModal from "../TermsOfUseModal";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import {
  getRemoteConfigData,
  logAnalyticsEvent,
  setAccessTokens,
  setUserDetails,
  validateDate,
} from "../../Utils";
import useNotify from "../../Notify";
import { MaskedInput } from "antd-mask-input";
const { Title } = Typography;

const PatientStepThree = () => {
  const [loading, setLoading] = useState(false);
  const userType = useSelector((state) => state?.app?.userType);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [staticDropdownData, setStaticDropdownData] = useState();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const otherStepDetails = useSelector((state) => state.auth.allStepDetails);
  const { notify } = useNotify();

  useEffect(() => {
    window.scrollTo(0, 0);
    getRemoteConfigData("DROPDOWNS").then((result) =>
      setStaticDropdownData(result)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccessToken = async (uid) => {
    await postApis(patientAccessTokenUrl, {
      uid: uid,
    }).then((response) => {
      dispatch(setLoggedIn());
      setAccessTokens(response?.data);
      setUserDetails({ userType: userType });
      setLoading(false);
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const updatedValues = {
      ...otherStepDetails,
      ...values,
      dateOfBirth: moment(values?.dateOfBirth).format("MM-DD-YYYY"),
    };
    delete updatedValues["confirmPassword"];
    await postApis(patientRegisterUrl, updatedValues)
      .then((response) => {
        if (window?.webkit?.messageHandlers?.uidMessageHandler) {
          logAnalyticsEvent("mobile_sign_up", {
            userType: USER_TYPES.PATIENT,
            data: updatedValues?.email,
          });
        } else {
          logAnalyticsEvent("sign_up", {
            userType: USER_TYPES.PATIENT,
            data: updatedValues?.email,
          });
        }
        getAccessToken(response?.data?.uid);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const handleTermsModalCancel = () => {
    setIsTermsModalOpen(false);
  };

  const handlePrivacyModalCancel = () => {
    setIsPrivacyModalOpen(false);
  };

  return (
    <div data-testid="patientStepThree">
      <TermsOfUseModal
        isTermsModalOpen={isTermsModalOpen}
        handleTermsModalCancel={handleTermsModalCancel}
      />

      <PrivacyPolicyModal
        isPrivacyModalOpen={isPrivacyModalOpen}
        handlePrivacyModalCancel={handlePrivacyModalCancel}
      />

      <div className="mt2">
        <Title>Personal Details</Title>
      </div>
      <Row justify={"center"}>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Form
            className="mt2"
            initialValues={{}}
            form={form}
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={FORM_FIELDS.FIRST_NAME}
                  name="firstName"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: VALIDATIONS.PATTERN.STRING,
                    },
                  ]}
                >
                  <Input placeholder={FORM_FIELDS.FIRST_NAME} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={FORM_FIELDS.LAST_NAME}
                  name="lastName"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: VALIDATIONS.PATTERN.STRING,
                    },
                  ]}
                >
                  <Input placeholder={FORM_FIELDS.LAST_NAME} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={FORM_FIELDS.EMAIL_ADDRESS}
                  name="email"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder={PLACEHOLDER.EMAIL_ADDRESS} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.GENDER}
                  name="gender"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select"
                    options={staticDropdownData?.common?.genders}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.DOB}
                  name="dateOfBirth"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: validateDate,
                    },
                  ]}
                >
                  <MaskedInput mask={"00/00/0000"} placeholder="MM/DD/YYYY" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.PASSWORD}
                  name="password"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 8,
                      message: VALIDATIONS.DEFAULT.PASSWORD_MIN_LENGTH,
                    },
                  ]}
                >
                  <Input.Password placeholder={PLACEHOLDER.PASSWORD} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={FORM_FIELDS.CONFIRM_PASSWORD}
                  name="confirmPassword"
                  size="large"
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(VALIDATIONS.DEFAULT.PASSWORD_MISMATCH)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={PLACEHOLDER.CONFIRM_PASSWORD} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="termsAndConditions"
                  valuePropName="checked"
                  className="left"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Please accept it");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox>
                    I agree to the
                    <Link
                      onClick={() => setIsTermsModalOpen(true)}
                      className="ml025 mr025 underline"
                    >
                      Terms of Use
                    </Link>
                    and
                    <Link
                      onClick={() => setIsPrivacyModalOpen(true)}
                      className="ml025 underline"
                    >
                      Privacy Policy
                    </Link>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row className="flex-center">
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item className="mt2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={loading}
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default PatientStepThree;
