import {
  CalendarOutlined,
  DollarOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

const getDates = (item, index) => {
  return {
    icon: CalendarOutlined,
    label: `Session ${index + 1}`,
    value: `${item?.date}: ${item?.slot}`,
  };
};

export const displayFotonaConfirmInfo = (details) => {
  const dates = [];
  details?.bookingDate?.map((item, index) => {
    return dates.push(getDates(item, index));
  });
  const location = [
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
  ];
  return [...dates, ...location];
};
