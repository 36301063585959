import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { BUTTONS, VALIDATIONS } from "../../Common/Constants";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";
import { getRemoteConfigData } from "../../Common/Utils";
const { TextArea } = Input;

const ResolveAlert = ({
  form,
  selectedAlert,
  handleTextAreaChange,
  modalOpen,
  onFinish,
  onFinishFailed,
  cancelModal,
  handleDropdownChange,
  loading,
  selectedReason,
}) => {
  const [reasonDropdownData, setReasonDropdownData] = useState();

  useEffect(() => {
    getRemoteConfigData("DROPDOWNS").then((result) => {
      setReasonDropdownData(result?.providers?.alertReasons);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup
      title={
        selectedAlert?.alertStatus === "Resolved"
          ? "Resolved Alert"
          : "Resolve an Alert"
      }
      open={modalOpen}
      cancel={cancelModal}
      footer={null}
      width={500}
    >
      {selectedAlert?.alertStatus === "Resolved" ? (
        <div className="grey-fill pd1">
          <div>
            <b>Reason: </b> {selectedAlert?.resolvedReason || "NA"}
          </div>
          <div className="mt05">
            <b>Description: </b> {selectedAlert?.resolvedDescription || "NA"}
          </div>
          <div className="mt05">
            <b>Resolved By: </b>
            {selectedAlert?.resolverName || "NA"}
          </div>
        </div>
      ) : (
        <div className="flex-center">
          <Form
            form={form}
            initialValues={{}}
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
            style={{ width: "100%" }}
            className="mt05"
          >
            <Form.Item
              label={false}
              name="selectReason"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Please Select a Reason Type"
                options={reasonDropdownData}
                onChange={handleDropdownChange}
              />
            </Form.Item>

            <Form.Item
              name="otherReason"
              size="large"
              rules={[{ required: selectedReason === "Other" }]}
            >
              <TextArea
                rows={4}
                placeholder="Add a reason"
                onChange={handleTextAreaChange}
              />
            </Form.Item>
            <Form.Item className="mt2">
              <Button type="primary" htmlType="submit" block disabled={loading}>
                <Loader loading={loading} text={BUTTONS.SAVE} />
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Popup>
  );
};

export default ResolveAlert;
